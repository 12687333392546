// OptionTable.js

import React from 'react';
import axios from 'axios';
import { w3cwebsocket as W3CWebSocket } from 'websocket';

import Container from '@material-ui/core/Container';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { red, green, grey } from '@mui/material/colors';
import { Divider } from '@mui/material';
import Chip from '@mui/material/Chip';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import AuthenticationService from './AuthenticationService.js';
import CurrentHistorical from './functions/CurrentHistorical.js';
import MaterialTableOptionTable from './functions/MaterialTableOptionTable.js';
import MaterialTableOptionDetailsList from './functions/MaterialTableOptionDetailsList.js';
import StrikeReferenceLines_Get from './functions/StrikeReferenceLines_Get.js';
import ButtonAppBar from "./functions/ButtonAppBar.js";
import UnderlyingProduct1 from './functions/UnderlyingProduct1.js';
import ToggleButtons from './functions/ToggleButtons.js';
import DebitSuggestion1 from './functions/DebitSuggestion1.js';
import CreditSuggestion1 from './functions/CreditSuggestion1.js';
import UnderlyingProductDetails from './functions/UnderlyingProductDetails.js';
import { ChartDataDateTimeHKContext, IndexStockListItemContext } from './Context.js';
import { OnMessageStock, OnMessageStockOption, PutOption2OptionDetailsListItem, CallOption2OptionDetailsListItem, LongUnderlyingProductValue, Number2Dollar, FormattedNumber, CheckAuthenticated, LongExpiry, ShortExpiry, Expiry_Get, SetBidAskValue, GroupSummaryCostValueSum, GroupNumberList_Update, ActiveOptionTableList_Get, OnMessageIndex, OnMessageIndexOption } from './functions/Common.js';

class OptionTable extends React.Component {

   constructor(props){

      super(props);
  
      this.state ={
        TimeStatus: 'Current',
        RefreshDate: '',
        RefreshHour: '',
        RefreshMinute: '',
        DateList: [],
        ProductType: 'IndexOption',
        HistoricalRefreshButtonPressed: 0,
        UnderlyingProductCodeOptions:'',
        UnderlyingProductCodeDefaultValue: '',
        ExpiryOptions:'',
        ExpiryDefaultValue:'',
        Expiry:'',
        UnderlyingProductCode:'HK.HSI',
        UnderlyingProductCode2:'',
        // UnderlyingProductLast: 0,
        // StrikeInterval: 0,
        OptionTableList: [],
        OptionList: [],
        OptionDetailsList: [],
        OptionDetailsListLength: 0,
        TotalCost: 0,
        GroupTitle: '',
        Now: '',
        GroupList: [],
        Mode: 'Insert',
        GroupNumberList: [],
        // GroupNumberListItem: null,
        RevisionDateTimeHKLast: '',
        Auto: false,
        // WSClient: '',
        YMin: 0,
        YMax: 0,
        StrikeReferenceLines: [],
        IndexStockListItem: {},
        WebSocketConsole: {Message: ' ', DateTime: ''},
        WebSocketOpen: 'WebSocket',
        WebSocketOpenBgColor: 'warning',
        ToggleButton: 'Theta',
        WebSocketConsoleMessage: 'Console Message',
        Demo: false,
        ShowCreditSuggestion: false,
        ShowDebitSuggestion: false,
      };

      this.SubmitAction = this.SubmitAction.bind(this);
      this.AutoAction = this.AutoAction.bind(this);
      // ShowCreditSuggestionAction
      this.ShowDebitSuggestionAction = this.ShowDebitSuggestionAction.bind(this);
      this.ShowCreditSuggestionAction = this.ShowCreditSuggestionAction.bind(this);
      this.WebSocket_Open = this.WebSocket_Open.bind(this);
      // WebSocketOpen
      this.onChange_Mode = this.onChange_Mode.bind(this);
      this.GroupNumberListItem_Callback = this.GroupNumberListItem_Callback.bind(this);
      this.YMin_Callback = this.YMin_Callback.bind(this);
      this.YMax_Callback = this.YMax_Callback.bind(this);
      this.StrikeReferenceLines_Callback = this.StrikeReferenceLines_Callback.bind(this);
      this.YMin_Callback = this.YMin_Callback.bind(this);
      this.YMax_Callback = this.YMax_Callback.bind(this);
      this.StrikeReferenceLines_Callback = this.StrikeReferenceLines_Callback.bind(this);
      this.ToggleButtons_Callback = this.ToggleButtons_Callback.bind(this);
      // this.setIndexStockListItem, this.setWebSocketConsoleMessage
      this.setIndexStockListItem = this.setIndexStockListItem.bind(this);
      this.setWebSocketConsoleMessage = this.setWebSocketConsoleMessage.bind(this);
      this.GroupTitle_Callback123 = this.GroupTitle_Callback123.bind(this);
      // this.WebSocketConsoleMessage = this.WebSocketConsoleMessage.bind(this);
      this.IndexList_Callback = this.IndexList_Callback.bind(this);

   }

  Now(){

    let _now = new Date();

    let _year = _now.getFullYear().toString();
    let _month = (_now.getMonth() + 1).toString();
    let _day = _now.getDate().toString();
    let _hour = _now.getHours().toString();
    let _minute = _now.getMinutes().toString();

    let _now_string = _month.padStart(2, "0") + '-' + _day.padStart(2, "0") + ' ' + _hour.padStart(2, "0") + ':' + _minute.padStart(2, "0");

    this.setState({Now: _now_string});

  } 

  YMin_Callback(P_YMin){
    this.setState({YMin: P_YMin});
  }

  YMax_Callback(P_YMax){
    this.setState({YMax: P_YMax});
  }

  UpdateOptionDetailsListItem(p_option_details_list_item){  
    SetBidAskValue(this.state.OptionTableList, p_option_details_list_item);
  }

  UpdateOptionDetailsList(){
    this.state.OptionDetailsList.map((_option_details_list_item) => (
      this.UpdateOptionDetailsListItem(_option_details_list_item)
    ))
  }
  
  IndexStockListItem_Get(P_ProductType){

    var V_UnderlyingProductType;
    
    switch(P_ProductType){
      case 'IndexOption':
        V_UnderlyingProductType = 'Index';
        break;
      case 'StockOption':
        V_UnderlyingProductType = 'Stock';
        break;
    }

    var v_http_post_data = {
      Credential: AuthenticationService.getCurrentUser(),
      UnderlyingProductCode: this.state.UnderlyingProductCode,
      TimeStatus: this.state.TimeStatus,
    }
  
    var url;
    switch(V_UnderlyingProductType){
        case 'Index':
            url = sessionStorage.getItem('SiteURL') + 'api/IndexListItem/IndexCode/';
            break;  
        case 'Stock':
            url = sessionStorage.getItem('SiteURL') + 'api/StockListItem/StockCode/';
            break;    
    }    
  
    axios.post(url, v_http_post_data)
  
    .then(resp => {
  
        var V_IndexStockListItem = resp.data;
        V_IndexStockListItem.ProductType = V_UnderlyingProductType;
        // this.OptionTableList_Get(V_IndexStockListItem);
        this.setState({IndexStockListItem: V_IndexStockListItem});
        // this.OptionTableList_Get(V_IndexStockListItem);

    });

  }

  OptionTableList_Get(P_IndexStockListItem){

    var v_http_post_data = {
      Credential: AuthenticationService.getCurrentUser(),
      OptionList: this.state.OptionList,
      Expiry: this.state.Expiry,
      TimeStatus: this.state.TimeStatus,
    }
    
    const url = sessionStorage.getItem('SiteURL') + '/api/OptionTableList/InsertNew2/';

    axios.post(url, v_http_post_data)

        .then(resp => {

          let _OptionTableList = resp.data;

          if (_OptionTableList.length > 0){
            var V_ActiveOptionTableList = ActiveOptionTableList_Get(_OptionTableList, P_IndexStockListItem.Last);
            this.setState({OptionTableList: V_ActiveOptionTableList}, () => {    
              this.Now();
              this.UpdateOptionDetailsList();
            });
          }

      });

  }

  StrikeReferenceLines_Callback(P_StrikeReferenceLines){
    this.setState({StrikeReferenceLines: P_StrikeReferenceLines});
  }

  OptionList_Get(){

    var v_http_post_data = {
      Credential: AuthenticationService.getCurrentUser(),
      ProductType: this.state.ProductType,
      UnderlyingProductCode: this.state.UnderlyingProductCode,
      TimeStatus: this.state.TimeStatus,
    }
    
    const url = sessionStorage.getItem('SiteURL') + '/api/OptionList/ByUnderlyingProductCode/';

    axios.post(url, v_http_post_data)

        .then(resp => {
          let _OptionList = resp.data;
          this.setState({OptionList: _OptionList}, () => {               
            // StrikeReferenceLines_Get(this.state.UnderlyingProductCode, this.state.YMin, this.state.YMax, this.state.OptionList, this.StrikeReferenceLines_Callback);
            var V_UnderlyingProductType;
            switch(this.state.ProductType){
              case 'IndexOption':
                V_UnderlyingProductType = 'Index';
                break;
              case 'StockOption':
                V_UnderlyingProductType = 'Stock';
                break;
            }
            this.OptionTableList_Get(this.state.IndexStockListItem);
          });

      });

  }

  SubmitAction(){
    this.IndexStockListItem_Get(this.state.ProductType);
    this.OptionList_Get();
    this.setState({UnderlyingProductCode2: this.state.UnderlyingProductCode});
  }

   ProductType_Callback = (P_ProductType) => {
      this.setState({ProductType: P_ProductType}, () => {    
        // this.IndexStockListItem_Get(this.state.ProductType);
      });
    }

    UnderlyingProductCode_Callback = (p_underlying_product_code) => {
      this.setState({UnderlyingProductCode: p_underlying_product_code});
    }

    Expiry_Callback = (p_expiry) => {
      this.setState({Expiry: p_expiry});
    }

    // StrikeInterval_Callback = (p_strike_interval) => {
    //   this.setState({StrikeInterval: p_strike_interval});
    // }

    TimeStatus_Callback = (p_time_status) => {
      this.setState({TimeStatus: p_time_status});
    }

    IndexList_Callback = (P_IndexList) => {

    }

    OptionDetailsList_Callback = (p_OptionDetailsList) => {
      this.setState({OptionDetailsList: p_OptionDetailsList});
    }

    GroupTitle_Callback123 = (P_GroupTitle) => {
      this.setState({GroupTitle: P_GroupTitle});
    }

    UnderlyingProductLast_Callback = (P_UnderlyingProductLast) => {
      var V_IndexStockListItem = this.state.IndexStockListItem;
      V_IndexStockListItem.Last = P_UnderlyingProductLast;
      this.setState({IndexStockListItem: V_IndexStockListItem});
    }

    OptionListItem_Callback = (P_OptionListItem) => {

      var V_Testing = true;

      var V_OptionList = this.state.OptionList;

      var _index1 = V_OptionList.findIndex(x => x.ProductCode === P_OptionListItem.ProductCode);

      if (_index1 !== -1){  

        if (V_Testing){
          V_OptionList[_index1].Bid = P_OptionListItem.Bid;
          V_OptionList[_index1].Ask = P_OptionListItem.Ask;
          V_OptionList[_index1].RevisionDateTimeHKBid = P_OptionListItem.RevisionDateTimeHKBid;
          V_OptionList[_index1].RevisionDateTimeHKAsk = P_OptionListItem.RevisionDateTimeHKAsk;
        }
        else{
          V_OptionList[_index1] = P_OptionListItem;
        }
        
      }

      //

      var V_OptionTableList = this.state.OptionTableList;

      switch(P_OptionListItem.CallPut){

        case 'C':
          
          var _index2 = V_OptionTableList.findIndex(x => x.CallProductCode === P_OptionListItem.ProductCode);

          if (_index2 !== -1){              
            V_OptionTableList[_index2].CallBid = P_OptionListItem.Bid;
            V_OptionTableList[_index2].CallAsk = P_OptionListItem.Ask;
            V_OptionTableList[_index2].CallLast = P_OptionListItem.Last;
            V_OptionTableList[_index2].CallVolume = P_OptionListItem.Volume;
            V_OptionTableList[_index2].RevisionDateTimeHKCallBid = P_OptionListItem.RevisionDateTimeHKBid;
            V_OptionTableList[_index2].RevisionDateTimeHKCallAsk = P_OptionListItem.RevisionDateTimeHKAsk;
            V_OptionTableList[_index2].RevisionDateTimeHKCallLast = P_OptionListItem.RevisionDateTimeHKLast;
            V_OptionTableList[_index2].RevisionDateTimeHKCallVolume = P_OptionListItem.RevisionDateTimeHKVolume;
          }

          break;
  
        case 'P':

          var _index3 = V_OptionTableList.findIndex(x => x.PutProductCode === P_OptionListItem.ProductCode);

          if (_index3 !== -1){              
            V_OptionTableList[_index3].PutBid = P_OptionListItem.Bid;
            V_OptionTableList[_index3].PutAsk = P_OptionListItem.Ask;
            V_OptionTableList[_index3].PutLast = P_OptionListItem.Last;
            V_OptionTableList[_index3].PutVolume = P_OptionListItem.Volume;
            V_OptionTableList[_index3].RevisionDateTimeHKPutBid = P_OptionListItem.RevisionDateTimeHKBid;
            V_OptionTableList[_index3].RevisionDateTimeHKPutAsk = P_OptionListItem.RevisionDateTimeHKAsk;
            V_OptionTableList[_index3].RevisionDateTimeHKPutLast = P_OptionListItem.RevisionDateTimeHKLast;
            V_OptionTableList[_index3].RevisionDateTimeHKPutVolume = P_OptionListItem.RevisionDateTimeHKVolume;
          }

          break;

      }
      
      this.setState({
        OptionList: V_OptionList,
        OptionTableList: V_OptionTableList,
      }, () => {
        // CostValueSum(this.state.OptionList, this.state.GroupNumberListItem);
      });

    }

    OptionDetailsListSort(p_OptionDetailsList){

      // let _list = p_OptionDetailsList.sort((a, b) => (a.Strike > b.Strike) ? 1 : -1)
      // list.sort((a, b) => (a.color > b.color) ? 1 : (a.color === b.color) ? ((a.size > b.size) ? 1 : -1) : -1 )
      let _list = p_OptionDetailsList.sort((a, b) => (a.Strike > b.Strike) ? 1 : (a.Strike === b.Strike) ? ((a.CallPut == 'P') ? 1 : -1) : -1 )
      return _list;

    }

    // CallOption2OptionDetailsListItem(P_CallOption){

    //   var _option_details_list_item = {

    //     CallPut: 'C',

    //     ProductType: P_CallOption.ProductType,
    //     UnderlyingProductCode: P_CallOption.UnderlyingProductCode,
    //     ProductCode: P_CallOption.ProductCode,
    //     Expiry: P_CallOption.Expiry,
    //     Strike: P_CallOption.Strike,

    //     Delta: P_CallOption.Delta,
    //     Theta: P_CallOption.Theta,
    //     Vega: P_CallOption.Vega,

    //     RefCostContract: 0,
    //     RefCostShare: 0,
    //     RefCostPrice: 0,

    //     RefValueContract: 0,
    //     RefValueShare: 0,
    //     RefValuePrice: 0,

    //     TotalCostAvg: 0,
    //     TotalValueAvg: 0,
    //     TotalProfitAvg: 0,          

    //     Status: 'Pending',

    //   }  
      
    //   return _option_details_list_item;

    // }

    CallProductCodeSubmitAction = (P_CallOption) => {

      var V_GroupNumberList = this.state.GroupNumberList;
      let _group_number_list_item = V_GroupNumberList[0];
      let _option_details_list = _group_number_list_item.OptionDetailsList;
      const _index = _option_details_list.findIndex(x => x.ProductCode == P_CallOption.ProductCode);

      if (_index == -1){

        var _option_details_list_item = CallOption2OptionDetailsListItem(P_CallOption);

        // var _option_details_list_item = {

        //   CallPut: 'C',

        //   ProductType: P_CallOption.ProductType,
        //   UnderlyingProductCode: P_CallOption.UnderlyingProductCode,
        //   ProductCode: P_CallOption.ProductCode,
        //   Expiry: P_CallOption.Expiry,
        //   Strike: P_CallOption.Strike,

        //   Delta: P_CallOption.Delta,
        //   Theta: P_CallOption.Theta,
        //   Vega: P_CallOption.Vega,

        //   RefCostContract: 0,
        //   RefCostShare: 0,
        //   RefCostPrice: 0,

        //   RefValueContract: 0,
        //   RefValueShare: 0,
        //   RefValuePrice: 0,

        //   TotalCostAvg: 0,
        //   TotalValueAvg: 0,
        //   TotalProfitAvg: 0,          

        //   Status: 'Pending',

        // }  
        
        _option_details_list.push(_option_details_list_item);
        _option_details_list = this.OptionDetailsListSort(_option_details_list);

        this.setState({GroupNumberList: V_GroupNumberList});
  
      }

    }

    PutProductCodeSubmitAction = (P_PutOption) => {

      var V_GroupNumberList = this.state.GroupNumberList;
      let _group_number_list_item = V_GroupNumberList[0];
      let _option_details_list = _group_number_list_item.OptionDetailsList;
      const _index = _option_details_list.findIndex(x => x.ProductCode == P_PutOption.ProductCode);

      if (_index == -1){

        var _option_details_list_item = PutOption2OptionDetailsListItem(P_PutOption);

        // var _option_details_list_item = {

        //   CallPut: 'P',

        //   ProductType: P_PutOption.ProductType,
        //   UnderlyingProductCode: P_PutOption.UnderlyingProductCode,
        //   ProductCode: P_PutOption.ProductCode,
        //   Expiry: P_PutOption.Expiry,
        //   Strike: P_PutOption.Strike,

        //   Delta: P_PutOption.Delta,
        //   Theta: P_PutOption.Theta,
        //   Vega: P_PutOption.Vega,

        //   RefCostContract: 0,
        //   RefCostShare: 0,
        //   RefCostPrice: 0,

        //   RefValueContract: 0,
        //   RefValueShare: 0,
        //   RefValuePrice: 0,

        //   TotalCostAvg: 0,
        //   TotalValueAvg: 0,
        //   TotalProfitAvg: 0,      

        //   Status: 'Pending',

        // }  
        
        _option_details_list.push(_option_details_list_item); 
        _option_details_list = this.OptionDetailsListSort(_option_details_list);

        this.setState({GroupNumberList: V_GroupNumberList});
  
      }

    }

    onChange_Mode(p_event){

      let _mode = p_event.target.value;
      this.setState({Mode: _mode});

    }

    GroupNumberListItem_Callback(P_GroupNumberListItem){
      var V_GroupNumberList = this.state.GroupNumberList;
      V_GroupNumberList[0] = P_GroupNumberListItem;
      this.setState({GroupNumberList: V_GroupNumberList}); 
    }

    GroupNumberList_Create(){

      var V_GroupNumberListItem = {
        Number: 0,
        OptionDetailsList: [],
        TotalCostAvg: 0,
        TotalValueAvg: 0,
        TotalProfitAvg: 0,
        TotalIntrinsicValueAvg: 0,
        TotalIntrinsicValueProfitAvg: 0,
        Updated: {GroupTitle: ''},
        Active: true,
        AllExpired: false,
      }

      var V_GroupNumberList = [];
      V_GroupNumberList.push(V_GroupNumberListItem);

      this.setState({GroupNumberList: V_GroupNumberList}); 
    }

    GroupNumberListItem_Existed(){

      if (this.state.GroupNumberList[0] != null)
      {
        // GroupSummaryCostValueSum(this.state.OptionList, this.state.GroupNumberListItem);

        return(
          <div>
            <MaterialTableOptionDetailsList
              ProductType={this.state.ProductType}
              OptionList={this.state.OptionList}
              OptionDetailsListLength={this.state.OptionDetailsListLength}
              UnderlyingProductLast={this.state.IndexStockListItem.Last}
              GroupNumberListItem={this.state.GroupNumberList[0]} 
              GroupNumberListItem_Callback={this.GroupNumberListItem_Callback}
              GroupTitle_Callback123={this.GroupTitle_Callback123}
              GroupTitle={this.state.GroupTitle}
              CallerType='Draft'
              Multiplier={this.state.IndexStockListItem.Multiplier}
            />
          </div>
        )

      }

    }

    ToggleButtons_Callback(P_ToggleButton){
      this.setState({ToggleButton: P_ToggleButton});
    }

    setIndexStockListItem(V_IndexListItemRC){
      this.setState({IndexStockListItem: V_IndexListItemRC});
    }

    setWebSocketConsoleMessage(V_WebSocketConsoleMessage){
      this.setState({WebSocketConsoleMessage: V_WebSocketConsoleMessage});
    }

    WebSocket_Open(P_Message, P_WebSocketOpenBgColor){

      var V_WebSocketURL = sessionStorage.getItem('WebSocketURL');
      const _ws_client = new W3CWebSocket(V_WebSocketURL);      

      _ws_client.onopen = () => {

        // var V_WebSocketOpen = {Message: '', DateTime: ''};

        // V_WebSocketOpen.Message = P_Message;
        // V_WebSocketOpen.DateTime = new Date().toLocaleTimeString('en-US', { hour12: false, hour: "numeric", minute: "numeric", second: "numeric"});
        // V_WebSocketOpen.DateTime = '(' + V_WebSocketOpen.DateTime + ')';

        var V_ConsoleMessage = P_Message;
        var V_ConsoleDateTime = new Date().toLocaleTimeString('en-US', { hour12: false, hour: "numeric", minute: "numeric", second: "numeric"});
        var V_WebSocketOpen = V_ConsoleMessage + ' (' + V_ConsoleDateTime + ')';
    
        this.setState({WebSocketOpen: V_WebSocketOpen});
        this.setState({WebSocketOpenBgColor: P_WebSocketOpenBgColor});

        _ws_client.onmessage = (message) => {
        
          var V_Data = JSON.parse(message.data);
  
          switch(V_Data.ProductType){
  
            case 'Index':  
              OnMessageIndex(V_Data, this.setIndexStockListItem, this.setWebSocketConsoleMessage, 'OptionTable');
              break;           
  
            case 'Stock':  
              OnMessageStock(V_Data, this.setIndexStockListItem, this.setWebSocketConsoleMessage, 'OptionTable');
              break;

            case 'IndexOption':
              OnMessageIndexOption(V_Data, this.OptionListItem_Callback, this.setWebSocketConsoleMessage, 'OptionTable');
              break;

            case 'StockOption':
              OnMessageStockOption(V_Data, this.OptionListItem_Callback, this.setWebSocketConsoleMessage, 'OptionTable');
              break;

          }
  
        }
  
        _ws_client.onclose = () => {
          this.WebSocket_Open('Opened', 'error');
        };

        _ws_client.onerror = (err) => {
          console.error('WebSocket encountered error: ', err.message, 'Closing socket');
          _ws_client.close();
        };

      }

    }

    ShowDebitSuggestionAction(){
      this.setState({ShowDebitSuggestion: !this.state.ShowDebitSuggestion});
    }

    ShowCreditSuggestionAction(){
      this.setState({ShowCreditSuggestion: !this.state.ShowCreditSuggestion});
    }

    TriggerAction(){

    }
    
    AutoAction(){

      this.setState({Auto: !this.state.Auto}, () => {

        switch(this.state.Auto){

          case true:
          
            this.WebSocket_Open('Opened', 'warning');
            break;
      
          case false:      
      
            break;
      
        }

      });   
      
    }

    // WebSocketConsoleMessage(){
    //   return(
    //     <React.Fragment>
    //       <Grid item align="right" xs={12} lg={12}>
    //         <Typography sx={{color: grey[400]}}>{this.state.WebSocketOpen.Message} {this.state.WebSocketOpen.DateTime}</Typography>
    //       </Grid>
    //       <Grid item align="right" xs={12} lg={12}>
    //         <Typography sx={{color: grey[400]}}>{this.state.WebSocketConsole.Message} {this.state.WebSocketConsole.DateTime}</Typography>
    //       </Grid>          
    //     </React.Fragment>
    //   )
    // }

    componentDidMount() {

      this.GroupNumberList_Create();

      // var V_UnderlyingProductType;
      // switch(this.state.ProductType){
      //   case 'IndexOption':
      //     V_UnderlyingProductType = 'Index';
      //     break;
      //   case 'StockOption':
      //     V_UnderlyingProductType = 'Stock';
      //     break;
      // }
      this.IndexStockListItem_Get(this.state.ProductType);

    }

    AutoCheckbox_Show(){

      if (this.state.Demo == false){

        return(

          <React.Fragment>

              <Grid container spacing={2}>
                <Grid item align="left" xs={9} lg={9}>
                  <Button variant="contained" color="primary" fullWidth="true" onClick={this.SubmitAction}>確定</Button>
                </Grid>
                <Grid item align="right" xs={3} lg={3}>
                  <FormControlLabel control={<Checkbox />} label="Auto" onClick={this.AutoAction}/>
                </Grid>
              </Grid> 

          </React.Fragment>

        )

      }
      else{

        return(

          <React.Fragment>

              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <Button variant="contained" color="primary" fullWidth="true" onClick={this.SubmitAction}>確定</Button>
                </Grid>
                {/* <Grid item align="right" xs={3} lg={3}>
                  <FormControlLabel control={<Checkbox />} label="Auto" onClick={this.AutoAction}/>
                </Grid> */}
              </Grid> 

          </React.Fragment>

        )

      }

    }

    DebitSuggestion_Show(P_ShowSuggestion){

    // if (this.state.ProductType == 'IndexOption'){

      if (this.state.Demo == false & this.state.ProductType == 'IndexOption'){

        return(

          <React.Fragment>

          <div style={{ display: P_ShowSuggestion == true ? 'block' : 'none'}}>
          
          
          <Box bgcolor={green[50]}>
            
            <DebitSuggestion1
              OptionTableList={this.state.OptionTableList}
              IndexStockListItem={this.state.IndexStockListItem}
              CallPut='Put'
              CombinationName='Long Put Vertical Spread'
            />            
            <br/>
            <Divider color={grey[300]} variant="middle"></Divider>
            <br/>
            <DebitSuggestion1
              OptionTableList={this.state.OptionTableList}
              IndexStockListItem={this.state.IndexStockListItem}
              CallPut='Call'
              CombinationName='Long Call Vertical Spread'
            />            
            <br/>
          </Box>

          <br/>
          
          </div>

          </React.Fragment>

        )

      }
      else{

        return(
          <React.Fragment>
          </React.Fragment>
        )

      }

    // }
    // else{

    //   return(
    //     <React.Fragment>
    //     </React.Fragment>
    //   )

    // }
  }

    CreditSuggestion_Show(P_ShowSuggestion){

      if (this.state.ProductType == 'IndexOption'){

        if (this.state.Demo == false){

          return(
  
            <React.Fragment>
  
            <div style={{ display: P_ShowSuggestion == true ? 'block' : 'none'}}> 
  
            <br/>
  
            <Box bgcolor={red[50]}>
  
              <CreditSuggestion1
                OptionTableList={this.state.OptionTableList}
                IndexStockListItem={this.state.IndexStockListItem}
                CallPut='Put'
                CombinationName='Short Put Vertical Spread'
                IndexGap={1}
                StrikeGap={1}
              />              
              <br/>
              <Divider color={grey[300]} variant="middle"></Divider>
              <br/>
              <CreditSuggestion1
                OptionTableList={this.state.OptionTableList}
                IndexStockListItem={this.state.IndexStockListItem}
                CallPut='Call'
                CombinationName='Short Call Vertical Spread'
                IndexGap={1}
                StrikeGap={1}
              />
              <br/>
            </Box>
  
            <br/>
  
            <Box bgcolor={red[50]}>
  
            <CreditSuggestion1
                OptionTableList={this.state.OptionTableList}
                IndexStockListItem={this.state.IndexStockListItem}
                CallPut='Put'
                CombinationName='Short Put Vertical Spread'
                IndexGap={1}
                StrikeGap={2}
              />              
              <br/>
              <Divider color={grey[300]} variant="middle"></Divider>
              <br/>
              <CreditSuggestion1
                OptionTableList={this.state.OptionTableList}
                IndexStockListItem={this.state.IndexStockListItem}
                CallPut='Call'
                CombinationName='Short Call Vertical Spread'
                IndexGap={1}
                StrikeGap={2}
              />
            <br/>
            </Box>
  
            <br/>
  
            <Box bgcolor={red[50]}>
  
            <CreditSuggestion1
                OptionTableList={this.state.OptionTableList}
                IndexStockListItem={this.state.IndexStockListItem}
                CallPut='Put'
                CombinationName='Short Put Vertical Spread'
                IndexGap={2}
                StrikeGap={2}
              />              
              <br/>
              <Divider color={grey[300]} variant="middle"></Divider>
              <br/>
              <CreditSuggestion1
                OptionTableList={this.state.OptionTableList}
                IndexStockListItem={this.state.IndexStockListItem}
                CallPut='Call'
                CombinationName='Short Call Vertical Spread'
                IndexGap={2}
                StrikeGap={2}
              />
            <br/>
            </Box>
  
            
            </div>
  
            </React.Fragment>
  
          )
  
        }
        else{
  
          return(
            <React.Fragment>
            </React.Fragment>
          )
  
        }

      }
      else{

        return(
          <React.Fragment>
          </React.Fragment>
        )
  
      }

    }

    UnderlyingProduct_Show(){

      if (this.state.Demo == false){

        return(

          <UnderlyingProduct1 
            ProductType={this.state.ProductType} 
            UnderlyingProductCode={this.state.UnderlyingProductCode} 
            TimeStatus={'Current'}
            OptionList={this.state.OptionList}
          />

        )

      }
      else{

        return(
          <React.Fragment>
          </React.Fragment>
        )

      }

    }

   render(){

    var V_Debug = this.state.WSClient;

    if (this.state.IndexStockListItem.Last !== undefined & this.state.IndexStockListItem.Last !== null){
      GroupNumberList_Update(this.state.OptionList, this.state.GroupNumberList, this.state.IndexStockListItem.Last);
    }

    return (

         <div>

          <IndexStockListItemContext.Provider value={this.state.IndexStockListItem}>

            <ButtonAppBar />            

            <Container maxWidth="lg">
            <br/>
              <CurrentHistorical 
                ProductType_Callback={this.ProductType_Callback} 
                UnderlyingProductCode_Callback={this.UnderlyingProductCode_Callback} 
                Expiry_Callback={this.Expiry_Callback} 
                // StrikeInterval_Callback={this.StrikeInterval_Callback} 
                TimeStatus_Callback={this.TimeStatus_Callback}
                Expiry={this.state.Expiry} 
                NoExpiry={false}
                // IndexStockListItem_Callback={this.IndexStockListItem_Get}
              />

              {this.AutoCheckbox_Show()}
              {/* <Grid container spacing={2}>
                <Grid item align="left" xs={9} lg={9}>
                  <Button variant="contained" color="primary" fullWidth="true" onClick={this.SubmitAction}>確定</Button>
                </Grid>
                <Grid item align="right" xs={3} lg={3}>
                  <FormControlLabel control={<Checkbox />} label="Auto" onClick={this.AutoAction}/>
                </Grid>
              </Grid>    */}

            </Container>
            <br/><br/>
            {/* {this.WebSocketConsoleMessage()} */}
            {/* <Grid item align="right" xs={12} lg={12}>
              <Typography sx={{fontSize: 12}}>{this.state.WebSocketConsole.Message} {this.state.WebSocketConsole.DateTime}</Typography>
            </Grid> */}

              {this.UnderlyingProduct_Show()}
              {/* <UnderlyingProduct1 
                ProductType={'Index'} 
                UnderlyingProductCode={'HK.HSI'} 
                TimeStatus={'Current'}
                OptionList={this.state.OptionList}
              /> */}

            <br/><br/>

            <Grid container spacing={2}>
              <Grid item align="center" xs={12} lg={12}>
                <ToggleButtons DefaultToggleButton={this.state.ToggleButton} ToggleButtons_Callback={this.ToggleButtons_Callback}/>
              </Grid>
            </Grid>            

            <br/><br/>

            {/* {this.WebSocketConsoleMessage()} */}

            <br/>
            <UnderlyingProductDetails
              IndexStockListItem={this.state.IndexStockListItem}
            />
           
            <br/>

            <Snackbar open={true} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
              <Alert icon={false} severity={this.state.WebSocketOpenBgColor}>
                {this.state.WebSocketOpen}
              </Alert>
            </Snackbar>

            <Snackbar open={true} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
              <Alert icon={false} severity={'info'}>
                {this.state.WebSocketConsoleMessage}
              </Alert>
            </Snackbar>

            <MaterialTableOptionTable
              ProductType={this.state.ProductType}
              UnderlyingProductCode={this.state.UnderlyingProductCode}
              // UnderlyingProductLast={this.state.IndexStockListItem.Last}
              OptionTableList={this.state.OptionTableList}
              OptionTableListLength={this.state.OptionTableList.length}
              CallProductCodeSubmitAction={this.CallProductCodeSubmitAction}
              PutProductCodeSubmitAction={this.PutProductCodeSubmitAction}
              RevisionDateTimeHKLast={this.state.RevisionDateTimeHKLast}
              OptionList={this.state.OptionList}
              UnderlyingProductCode2={this.state.UnderlyingProductCode2}
              TimeStatus={this.state.TimeStatus}
              UnderlyingProductLast_Callback={this.UnderlyingProductLast_Callback}
              OptionListItem_Callback={this.OptionListItem_Callback}
              Auto={this.state.Auto}
              WSClient={this.state.WSClient}

              StrikeReferenceLines={this.state.StrikeReferenceLines} 
              YMin={this.state.YMin}    
              YMax={this.state.YMax}
              YMin_Callback={this.YMin_Callback}           
              YMax_Callback={this.YMax_Callback} 

              ToggleButton={this.state.ToggleButton}
            />            

            {/* {this.WebSocketConsoleMessage()} */}

            <Grid container spacing={2}>
                {/* <Grid item align="left" xs={6} lg={6}>
                  
                </Grid> */}
                <Grid item align="center" xs={4} lg={4}>
                  <FormControlLabel control={<Checkbox />} label="Debit" onClick={this.ShowDebitSuggestionAction}/>
                </Grid>
                <Grid item align="center" xs={4} lg={4}>
                  <FormControlLabel control={<Checkbox />} label="Credit" onClick={this.ShowCreditSuggestionAction}/>
                </Grid>
                <Grid item align="center" xs={4} lg={4}>
                  <FormControlLabel control={<Checkbox />} label="Trigger" onClick={this.TriggerAction}/>
                </Grid>                
            </Grid>     
                   
            <br/><br/>

            {this.DebitSuggestion_Show(this.state.ShowDebitSuggestion)}
            {this.CreditSuggestion_Show(this.state.ShowCreditSuggestion)}       

            <br/>

            {this.GroupNumberListItem_Existed()}
            <br/><br/>

            </IndexStockListItemContext.Provider>

         </div>

    );

  }

}

export default OptionTable;