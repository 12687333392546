// HomeProfit.js

import { BrowserRouter, Route } from "react-router-dom";
import React from 'react';
import axios from 'axios';
import { useState } from "react";
import ReactDOM from 'react-dom';
import MaterialTable from "material-table";
import { useRoutes, A, navigate } from "hookrouter";
import { w3cwebsocket as W3CWebSocket } from 'websocket';

import { InputLabel } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { sizing } from '@material-ui/system';
import Container from '@material-ui/core/Container';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FolderIcon from '@material-ui/icons/Folder';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Tooltip from '@material-ui/core/Tooltip';
import { DataGrid } from '@material-ui/data-grid';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';

import Filtering from './Filtering.js';
import Historical from './Historical.js';
import AuthenticationService from '../AuthenticationService.js';
import ProfitLineChart1 from './ProfitLineChart1.js';
import { LongUnderlyingProductValue, Number2Dollar, FormattedNumber, CheckAuthenticated, ButtonAppBar, LongExpiry, ShortExpiry, Expiry_Get, date2string, UnderlyingProductValue_Get, getResponse, GroupSummaryCostValueSum, SetBidAskValue, GroupNumberList_Update } from './Common.js';

export default function HomeProfit(props) {

    const [Profit, setProfit] = useState(0);

    var v_http_post_data = {
        Credential: AuthenticationService.getCurrentUser(),
    }
      
    const url = sessionStorage.getItem('SiteURL') + '/api/OptionDetailsList/HomeProfit/';
  
    axios.post(url, v_http_post_data)
  
    .then(resp => {
        let V_Profit = resp.data;
        setProfit(V_Profit);  
  
    });

    function GroupNumberList_SetExpired(){

    }

    function OptionDetailsList_SetExpired(){

        var v_http_post_data = {
            Credential: AuthenticationService.getCurrentUser(),
        }
          
        const url = sessionStorage.getItem('SiteURL') + '/api/OptionDetailsList/SetExpired/';

        axios.post(url, v_http_post_data)
  
        .then(resp => {            
            let V_ReturnStatus = resp.data;
        });

    }

    function OptionList_SetExpired(){

        var v_http_post_data = {
            Credential: AuthenticationService.getCurrentUser(),
        }
          
        const url = sessionStorage.getItem('SiteURL') + '/api/OptionList/SetExpired/';

        axios.post(url, v_http_post_data)
  
        .then(resp => {            
            let V_ReturnStatus = resp.data;
        });

    }

    function SubmitAction(){
        OptionList_SetExpired();
        OptionDetailsList_SetExpired();
        GroupNumberList_SetExpired();
    }

    return(
        <div>                    
            <ProfitLineChart1 Profit={Profit}/><br/>
            <Button variant="contained" color="primary" fullWidth="true" onClick={e => SubmitAction(e)}>Set Expired</Button>
        </div>
    )    
}