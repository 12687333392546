// OptionTableListItemDetails.js

import React from 'react';
import { useState, useEffect, createContext, useContext } from "react";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import Box from '@material-ui/core/Box';
import { Alert, AlertTitle } from '@material-ui/lab';

import Tooltip from '@material-ui/core/Tooltip';
import { InputLabel } from '@material-ui/core';
import Chip from '@mui/material/Chip';

import CallOptionDetails from './CallOptionDetails.js';
import PutOptionDetails from './PutOptionDetails.js';
import UnderlyingProductDetails from './UnderlyingProductDetails.js';

import { ChartDataDateTimeHKContext, IndexStockListItemContext } from '../Context.js';
import { OptionTableListItem4Put, OptionTableListItem4Call, LongUnderlyingProductValue, Number2Dollar, FormattedNumber, CheckAuthenticated, ButtonAppBar, StartEnd, StartEnd2, Rounding, date2string, OTM_ATM_ITM } from './Common.js';
import Typography from '@material-ui/core/Typography';
import Button from '@mui/material/Button';

// function OptionTableListItem4Call(P_OptionTableListItem){

//   var V_CallOption ={};

//   V_CallOption.ProductType = P_OptionTableListItem.ProductType;
//   V_CallOption.UnderlyingProductCode = P_OptionTableListItem.UnderlyingProductCode;
//   V_CallOption.Expiry = P_OptionTableListItem.Expiry;
//   V_CallOption.Strike = P_OptionTableListItem.Strike;

//   V_CallOption.Bid = LongUnderlyingProductValue("IndexOption", P_OptionTableListItem.CallBid);
//   V_CallOption.Ask = LongUnderlyingProductValue("IndexOption", P_OptionTableListItem.CallAsk);
//   V_CallOption.Last = LongUnderlyingProductValue("IndexOption", P_OptionTableListItem.CallLast);
  
//   V_CallOption.Close = LongUnderlyingProductValue("IndexOption", P_OptionTableListItem.CallClose);
//   V_CallOption.Open = LongUnderlyingProductValue("IndexOption", P_OptionTableListItem.CallOpen);
  
//   V_CallOption.Volume = LongUnderlyingProductValue("IndexOption", P_OptionTableListItem.CallVolume);
//   V_CallOption.OpenInterest = LongUnderlyingProductValue("IndexOption", P_OptionTableListItem.CallOpenInterest);
//   V_CallOption.PreviousOpenInterest = LongUnderlyingProductValue("IndexOption", P_OptionTableListItem.CallPreviousOpenInterest);
  
//   V_CallOption.Delta = Rounding(P_OptionTableListItem.CallDelta, 2);
//   V_CallOption.Gamma = Rounding(P_OptionTableListItem.CallGamma, 2);
//   V_CallOption.Theta = Rounding(P_OptionTableListItem.CallTheta, 2);
//   V_CallOption.Vega = Rounding(P_OptionTableListItem.CallVega, 2);
//   V_CallOption.ImpliedVolatility = Rounding(P_OptionTableListItem.CallImpliedVolatility, 2);

//   V_CallOption.ProductCode = P_OptionTableListItem.CallProductCode;
//   V_CallOption.RevisionDateTimeHKBid = P_OptionTableListItem.RevisionDateTimeHKCallBid;
//   V_CallOption.RevisionDateTimeHKAsk = P_OptionTableListItem.RevisionDateTimeHKCallAsk;
//   V_CallOption.RevisionDateTimeHKLast = P_OptionTableListItem.RevisionDateTimeHKCallLast;

//   V_CallOption.RevisionDateTimeHKVolume = P_OptionTableListItem.RevisionDateTimeHKCallVolume;
//   V_CallOption.RevisionDateTimeHKOpenInterest = P_OptionTableListItem.RevisionDateTimeHKCallOpenInterest;
//   V_CallOption.RevisionDateTimeHKPreviousOpenInterest = P_OptionTableListItem.RevisionDateTimeHKCallPreviousOpenInterest;
//   V_CallOption.RevisionDateTimeHKOptionComputation = P_OptionTableListItem.RevisionDateTimeHKCallOptionComputation;

//   return V_CallOption;

// }

export default function OptionTableListItemDetails(props){

  const { 
    ProductType,
    UnderlyingProductCode, 
    Strike, 
    CallProductCodeSubmitAction, 
    PutProductCodeSubmitAction, 
    OptionTableListItem, 
    OptionList,
    Currency,
  } = props;

  const IndexStockListItem = useContext(IndexStockListItemContext);

  var V_CallOption = OptionTableListItem4Call(OptionTableListItem, Currency);
  var V_PutOption = OptionTableListItem4Put(OptionTableListItem, Currency);

  // function UnderlyingProductDetails_Show()
  // {
  //   switch(ProductType)
  //   {
  //     case 'IndexOption':
  //       if (IndexStockListItem.IndexCode == UnderlyingProductCode)
  //       {
  //         return(
  //           <UnderlyingProductDetails
  //           IndexStockListItem={IndexStockListItem}
  //           />
  //         )
  //       }
  //       break;
  //     case 'StockOption':
  //       if (IndexStockListItem.StockCode == UnderlyingProductCode)
  //       {
  //         return(
  //           <UnderlyingProductDetails
  //           IndexStockListItem={IndexStockListItem}
  //           />
  //         )
  //       }
  //       break;
  //   }
  // }

  return(

    <div>

    <br/>

    <Grid container spacing={2}>
      <Grid item xs={12} lg={12}>
        <Typography component="h1" variant="h5">
          {LongUnderlyingProductValue(OptionTableListItem.ProductType, Strike)}
        </Typography>
      </Grid>
    </Grid>

    {/* {UnderlyingProductDetails_Show()} */}
    <UnderlyingProductDetails
      IndexStockListItem={IndexStockListItem}
    />

    <br/><br/>
    <Grid container spacing={1}>
      <Grid item xs={6} lg={6} style={{ backgroundColor: '#e8f5e9' }}>
        <CallOptionDetails 
          CallProductCodeSubmitAction={CallProductCodeSubmitAction} 
          // OptionTableListItem={OptionTableListItem} 
          CallOption={V_CallOption}
          OptionList={OptionList}
        />
      </Grid>
      <Grid item xs={6} lg={6} style={{ backgroundColor: '#ffebee' }}>
        <PutOptionDetails 
          PutProductCodeSubmitAction={PutProductCodeSubmitAction} 
          // OptionTableListItem={OptionTableListItem} 
          PutOption={V_PutOption}
          OptionList={OptionList}
        />
      </Grid>
    </Grid>  
    <br/>

    </div>

  )

}