// Options4Submit.js

import { BrowserRouter, Route } from "react-router-dom";
import React from 'react';
import ReactDOM from 'react-dom';
import { Button, InputLabel } from '@material-ui/core';
import MaterialTable from "material-table";
//import { DataGrid } from '@material-ui/data-grid';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { sizing } from '@material-ui/system';
import axios from 'axios';
import Container from '@material-ui/core/Container';

import Filtering from './Filtering.js';
import BaseContract from './BaseContract.js';
import BaseContract2 from './BaseContract2.js';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FolderIcon from '@material-ui/icons/Folder';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Box from '@material-ui/core/Box';

import Tooltip from '@material-ui/core/Tooltip';

import { DataGrid } from '@material-ui/data-grid';
import AuthenticationService from '../AuthenticationService.js';
import { useRoutes, A, navigate } from "hookrouter";

import Options4Display from './Options4Display.js';

function Options4Submit(props){

  const { 
    RequireUnderlying_Callback,
    OutlookUnderlying_Callback,
    ProfitPotential_Callback,
    LossPotential_Callback,
    CreditDebit_Callback,
    Leg_Callback,
    ProductCode,
    BaseContract_Callback,
    Left_Callback,
    Center_Callback,
    Right_Callback,
    BaseContract,
  } = props;

  return (

    <div>

      {/* <Box pb={3} pt={3} m={0} width="100%" borderTop={1} borderColor="grey.300">          
        <BaseContract2
          BaseContract_Callback={BaseContract_Callback}
          BaseContract={BaseContract}
        />
      </Box> */}
      {/* <br/> */}
      <Filtering
        RequireUnderlying_Callback={RequireUnderlying_Callback}
        OutlookUnderlying_Callback={OutlookUnderlying_Callback}
        ProfitPotential_Callback={ProfitPotential_Callback}
        LossPotential_Callback={LossPotential_Callback}
        CreditDebit_Callback={CreditDebit_Callback}
        Leg_Callback={Leg_Callback}
      />        
      <br/>
      <Box pb={3} pt={3} m={0} width="100%">
        <Options4Display Left_Callback={Left_Callback} Center_Callback={Center_Callback} Right_Callback={Right_Callback}/>
      </Box>

      <br/><br/>

    </div>

  )  

}

export default Options4Submit;