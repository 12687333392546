// DropDownList_CreditDebit.js

import React from 'react';
import { useState, useEffect, createContext, useContext } from "react";
import { InputLabel } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

function DropDownList_CreditDebit(props){

  const { 
    CreditDebitDefaultValue,
    CreditDebit_Callback
  } = props;

  const [CreditDebit, setCreditDebit] = useState(CreditDebitDefaultValue);

  function onChange_CreditDebit(P_CreditDebit){
    setCreditDebit(P_CreditDebit);
    CreditDebit_Callback(P_CreditDebit);
  }

  return(
    <div>
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="CreditDebitLabel">Credit / Debit</InputLabel>
        <Select native='true' name="CreditDebit" id="CreditDebit" labelId="CreditDebitLabel" value={CreditDebit} onChange={e => onChange_CreditDebit(e.target.value)}>
          <option key='0' value=''></option>
          <option key='1' value='Credit'>Credit</option>
          <option key='2' value='Debit'>Debit</option>
          <option key='3' value='Credit/Debit'>Credit/Debit</option> 
          <option key='4' value='Unknown'>Unknown</option> 
        </Select>
      </FormControl>
    </div>
      )

}

export default DropDownList_CreditDebit;