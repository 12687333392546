// Test.js

import React from 'react';
import axios from 'axios';
import { useState, useEffect } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';

import Grid from '@material-ui/core/Grid';
import Button from '@mui/material/Button';

import AuthenticationService from './AuthenticationService.js';
import HomeProfit from './functions/HomeProfit.js';
import UnderlyingProductLineChart from './functions/UnderlyingProductLineChart.js';
import ButtonAppBar from "./functions/ButtonAppBar.js";
import StrikeReferenceLines_Get from './functions/StrikeReferenceLines_Get.js';
import CurrentHistorical from './functions/CurrentHistorical.js';
import UnderlyingProduct1 from './functions/UnderlyingProduct1.js';
import { LabelPointDollar, LongUnderlyingProductValue, Number2Dollar, FormattedNumber, CheckAuthenticated, LongExpiry, ShortExpiry, Expiry_Get, date2string, UnderlyingProductValue_Get, getResponse, GroupSummaryCostValueSum, SetBidAskValue, GroupNumberList_Update } from './functions/Common.js';

export default function Test(){

  const [ProductType, setProductType] = useState('IndexOption');
  const [UnderlyingProductCode, setUnderlyingProductCode] = useState('');
  const [Expiry, setExpiry] = useState('');
  const [TimeStatus, setTimeStatus] = useState('');
  const [OptionList, setOptionList] = useState([]);

  const [ProductType2, setProductType2] = useState('');
  const [UnderlyingProductCode2, setUnderlyingProductCode2] = useState('');
  const [Expiry2, setExpiry2] = useState('');
  const [TimeStatus2, setTimeStatus2] = useState('');

  function SubmitAction(){
    setProductType2(ProductType);
    setUnderlyingProductCode2(UnderlyingProductCode);
    setExpiry2(Expiry);
  }

  return(

    <div>

      <ButtonAppBar />

      <CurrentHistorical
        ProductType={ProductType}
        ProductType_Callback={setProductType} 
        UnderlyingProductCode_Callback={setUnderlyingProductCode} 
        Expiry_Callback={setExpiry}
        TimeStatus_Callback={setTimeStatus}
        NoExpiry={false} 
      />

      <Grid container spacing={2}>
        <Grid item align="left" xs={12} lg={12}>
          <Button variant="contained" color="primary" fullWidth="true" onClick={SubmitAction}>確定</Button>
        </Grid>
      </Grid>

      <br/><br/>

      {LabelPointDollar('開倉值', 'Cost', 'IndexOption', 10000/50, 50, null, 6, 6)}

      <br/><br/>

      {ProductType2}<br/>
      {UnderlyingProductCode2}<br/>
      {Expiry2}

      <UnderlyingProduct1 
          ProductType={ProductType} 
          UnderlyingProductCode={UnderlyingProductCode} 
          TimeStatus={'Current'}
          OptionList={OptionList}
      />

    </div>
  );

}