// MaterialTableOptionTable.js

import { BrowserRouter, Route } from "react-router-dom";
import React from 'react';
import { useState, useEffect, createContext, useContext } from "react";
import ReactDOM from 'react-dom';
import { useRoutes, A, navigate } from "hookrouter";
import axios from 'axios';
import MaterialTable from "material-table";

import { InputLabel } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { sizing } from '@material-ui/system';
import Container from '@material-ui/core/Container';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FolderIcon from '@material-ui/icons/Folder';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { Alert, AlertTitle } from '@material-ui/lab';
import { DataGrid } from '@material-ui/data-grid';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import AuthenticationService from '../AuthenticationService.js';
import Options4Display from "./Options4Display.js";
import OptionTableListItemDetails from './OptionTableListItemDetails.js';
// UnderlyingProduct
import OptionTableRow from './OptionTableRow.js';
import { ChartDataDateTimeHKContext, IndexStockListItemContext } from '../Context.js';
import { LongUnderlyingProductValue, Number2Dollar, FormattedNumber, CheckAuthenticated, ButtonAppBar, StartEnd, StartEnd2, Rounding, date2string } from './Common.js';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(0),
  },
  title: {
    flexGrow: 1,
  },
  // typography: {
  //   fontSize: 12,
  // },
}));

export default function MaterialTableOptionTable(props) {

  const { 
    Country,
    ProductType,
    UnderlyingProductCode,
    CallProductCodeSubmitAction, 
    PutProductCodeSubmitAction, 
    OptionList, 
    OptionTableList, 
    OptionTableListLength, 
    ToggleButton 
  } = props;

  // UnderlyingProductValue_Callback = (P_UnderlyingProductValue) => {
  //   this.props.UnderlyingProductValue_Callback(P_UnderlyingProductValue);
  // }

  useEffect(() => {
    CheckAuthenticated();   
  }, []);

  // useEffect(() => {

  //   if (OptionTableList.length > 0){

  //     var V_CallOpenInterestDiff;
  //     var V_PutOpenInterestDiff;

  //     for (let i = 0; i < OptionTableList.length; i++) {
  //       var _option_table_list_item = OptionTableList[i];
  //       if (_option_table_list_item.CallMarked == true) V_CallOpenInterestDiff = _option_table_list_item.CallOpenInterestDiff;
  //       if (_option_table_list_item.PutMarked == true) V_PutOpenInterestDiff = _option_table_list_item.PutOpenInterestDiff;        
  //     }

  //     for (let i = 0; i < OptionTableList.length; i++) {
  //       var _option_table_list_item = OptionTableList[i];
  //       if (_option_table_list_item.CallMarked == true & _option_table_list_item.CallOpenInterestDiff > V_PutOpenInterestDiff){
  //         _option_table_list_item.OpenInterestDiffMax = true;
  //       }
  //       else{
  //         _option_table_list_item.OpenInterestDiffMax = false;
  //       };
  //       if (_option_table_list_item.PutMarked == true & _option_table_list_item.PutOpenInterestDiff > V_CallOpenInterestDiff){
  //         _option_table_list_item.OpenInterestDiffMax = true;
  //       }
  //       else{
  //         _option_table_list_item.OpenInterestDiffMax = false;
  //       };   
  //     }

  //   };

  // }, [OptionTableList]);

  return (

    <div>

  <Container maxWidth="lg">

  </Container>

  <Container disableGutters={true} maxWidth="lg">

  <TableContainer component={Paper}>
    <Table aria-label="collapsible table">

      <TableHead>
      <TableRow>
          <TableCell align="center" sx={{ paddingLeft: 0, paddingRight: 0, backgroundColor: "black", color: 'white' }} colSpan={3}>Call</TableCell>
          <TableCell align="center" sx={{ paddingLeft: 0, paddingRight: 0, backgroundColor: "black", color: 'white' }}></TableCell>
          <TableCell align="center" sx={{ paddingLeft: 0, paddingRight: 0, backgroundColor: "black", color: 'white' }} colSpan={3}>Put</TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="center" sx={{ paddingLeft: 0, paddingRight: 0, backgroundColor: "black", color: 'white' }}>Bid</TableCell>
          <TableCell align="center" sx={{ paddingLeft: 0, paddingRight: 0, backgroundColor: "black", color: 'white' }}>Ask</TableCell>
          <TableCell align="center" sx={{ paddingLeft: 0, paddingRight: 1, backgroundColor: "#424242", color: 'white' }}>{ToggleButton}</TableCell>
          <TableCell align="center" sx={{ paddingLeft: 0, paddingRight: 0, backgroundColor: "black", color: 'white' }}>Strike</TableCell>
          <TableCell align="center" sx={{ paddingLeft: 0, paddingRight: 0, backgroundColor: "black", color: 'white' }}>Bid</TableCell>
          <TableCell align="center" sx={{ paddingLeft: 0, paddingRight: 0, backgroundColor: "black", color: 'white' }}>Ask</TableCell>
          <TableCell align="center" sx={{ paddingLeft: 0, paddingRight: 1, backgroundColor: "#424242", color: 'white' }}>{ToggleButton}</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {OptionTableList.map((_option_table_list_item) => (
          <OptionTableRow 
            Country={Country}
            ProductType={ProductType}
            UnderlyingProductCode={UnderlyingProductCode}
            CallProductCodeSubmitAction={CallProductCodeSubmitAction} 
            PutProductCodeSubmitAction={PutProductCodeSubmitAction} 
            Key={_option_table_list_item.ID} 
            OptionTableListItem={_option_table_list_item} 
            // UnderlyingProductLast={this.props.UnderlyingProductLast}
            OptionList={OptionList}
            ToggleButton={ToggleButton}
          />
        ))}
      </TableBody>

    </Table>
  </TableContainer> 
  數據來源: Interactive Brokers 盈透證券 
  </Container>
  
  <br/><br/>
  {/* <Container maxWidth="lg">
  Total: {OptionTableListLength}
  </Container> */}
    </div>
  )

}