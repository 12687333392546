// DropDownList_ProfitPotential.js

import React from 'react';
import { useState, useEffect, createContext, useContext } from "react";
import { InputLabel } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

function DropDownList_ProfitPotential(props){

  const { 
    ProfitPotentialDefaultValue,
    ProfitPotential_Callback
  } = props;

  const [ProfitPotential, setProfitPotential] = useState(ProfitPotentialDefaultValue);

  function onChange_ProfitPotential(P_ProfitPotential){
    setProfitPotential(P_ProfitPotential);
    ProfitPotential_Callback(P_ProfitPotential);
  }

  return(
    <div>
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="ProfitPotentialLabel">潛在利潤</InputLabel>
        <Select native='true' name="ProfitPotential" id="ProfitPotential" labelId="ProfitPotentialLabel" value={ProfitPotential} onChange={e => onChange_ProfitPotential(e.target.value)}>
          <option key='0' value=''></option>
          <option key='1' value='Limited'>有限</option>
          <option key='2' value='Unlimited'>無限</option>
          <option key='3' value='Unknown'>未知</option>
        </Select>
      </FormControl>
    </div>
  )

}

export default DropDownList_ProfitPotential;