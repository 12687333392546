// DebitSuggestion1.js

import React from 'react';
import axios from 'axios';
import { useState, useEffect, createContext, useContext } from "react";

import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { red, green, grey } from '@mui/material/colors';
import Typography from '@mui/material/Typography';

import AuthenticationService from '../AuthenticationService.js';
import OptionDetailsListItemAdd from './OptionDetailsListItemAdd.js';
// ProductBarInfo
import ProductBarInfo from './ProductBarInfo.js';

import { 
    LabelPointDollar,
    OptionDetailsListSubmit,
    CallOption2OptionDetailsListItem,
    PutOption2OptionDetailsListItem,
    OptionTableListItem4Call,
    OptionTableListItem4Put,
    LongFormattedValue, 
    LongFormattedValue2,
    LongFormattedValueNoPoint,
    Dollar2Point,
    RedColor_Set, 
    date2string, 
    UnderlyingProductDiffString_Get 
} from './Common.js';

export default function DebitSuggestion1(props){

    const { 
        ProductType,
        OptionTableList,
        IndexStockListItem,
        CallPut,
        CombinationName,
    } = props;

    var V_MinStrikeDiff = 50;
    var V_MaxContract = 1;

    if (OptionTableList.length > 0){

        // var UnderlyingProductLast = IndexStockListItem.Last.toFixed(0);
        var UnderlyingProductLastString;
        var UnderlyingProductLast;
        
        switch(IndexStockListItem.ProductType){
            case 'Index':
                UnderlyingProductLastString = IndexStockListItem.Last.toFixed(0);
                UnderlyingProductLast = parseFloat(UnderlyingProductLastString);
                break;
            case 'Stock':
                UnderlyingProductLastString = IndexStockListItem.Last.toFixed(2);
                UnderlyingProductLast = parseFloat(UnderlyingProductLastString);
                break;
        }

        var V_Index;
        var V_StrikeDiff;
        var V_StrikeFinalDiff;
        var V_StrikeDiffString;

        var V_Case;
        var V_Contract;    
        var V_OptionTableListItem1, V_OptionTableListItem2;

        var V_ProductCode1, V_ProductCode2;
        var V_CallPut1, V_CallPut2;
        var V_BidAsk1, V_BidAsk2;
        var V_BidAskDateTime1, V_BidAskDateTime2;
        var V_Color;

        var V_Cost1, V_Cost2;
        var V_TotalCost;

        var V_Contract1, V_Contract2;

        var V_CallOptionTableListItem = OptionTableList.find(x => x.CallMarked == true);
        var V_PutOptionTableListItem = OptionTableList.find(x => x.PutMarked == true);
    
        var V_CallOpenInterestDiff = V_CallOptionTableListItem.CallOpenInterest - V_CallOptionTableListItem.CallPreviousOpenInterest;
        var V_PutOpenInterestDiff = V_PutOptionTableListItem.PutOpenInterest - V_PutOptionTableListItem.PutPreviousOpenInterest;

        if (V_CallOptionTableListItem.Strike < UnderlyingProductLast) V_CallOpenInterestDiff = -999; // ITM
        if (V_PutOptionTableListItem.Strike > UnderlyingProductLast) V_PutOpenInterestDiff = -999; // ITM

        const AvatarRedBgColor={backgroundColor: red[200]};
        const AvatarGreenBgColor={backgroundColor: green[200]};
        var AvatarBgColor1, AvatarBgColor2;

        var V_OptionDetailsList = [];
        var V_ProductType;

        switch(CallPut){

            case 'Call':
            
                V_Index = OptionTableList.findIndex(x => x.Strike > UnderlyingProductLast);
                V_StrikeDiff = OptionTableList[V_Index].Strike - UnderlyingProductLast;
                V_StrikeDiffString = LongFormattedValue(OptionTableList[V_Index].Strike, 0, false) + ' - ' + LongFormattedValue(UnderlyingProductLast, 0, false) + ' = ' + LongFormattedValue(V_StrikeDiff, 0, false);
    
                if (V_StrikeDiff >= V_MinStrikeDiff){ // reasonable far and less likely to go through (ITM), thus only consider 1 contract of next strike
                    V_Case = 'StrikeDiff (' + V_StrikeDiffString + ') >= 50';
                    V_Contract = V_MaxContract;
                    V_OptionTableListItem1 = OptionTableList[V_Index+0];
                    V_OptionTableListItem2 = OptionTableList[V_Index+1];
                    V_StrikeFinalDiff = OptionTableList[V_Index+0].Strike - UnderlyingProductLast;
                }
                else if (V_StrikeDiff < V_MinStrikeDiff){ // too near and expensive, thus consider 1 contract of next next strike
                    V_Case = 'StrikeDiff (' + V_StrikeDiffString + ') < 50';
                    V_Contract = V_MaxContract;
                    V_OptionTableListItem1 = OptionTableList[V_Index+1];
                    V_OptionTableListItem2 = OptionTableList[V_Index+2];
                    V_StrikeFinalDiff = OptionTableList[V_Index+1].Strike - UnderlyingProductLast;
                }
    
                var V_CallOption1 = OptionTableListItem4Call(V_OptionTableListItem1);
                var V_CallOption2 = OptionTableListItem4Call(V_OptionTableListItem2);

                V_ProductType =V_CallOption1.ProductType;

                var V_StrikeGap = V_CallOption2.Strike - V_CallOption1.Strike

                var _option_details_list_item_1 = CallOption2OptionDetailsListItem(V_CallOption1);
                var _option_details_list_item_2 = CallOption2OptionDetailsListItem(V_CallOption2);

                _option_details_list_item_1.Currency = IndexStockListItem.Currency;
                _option_details_list_item_2.Currency = IndexStockListItem.Currency;

                _option_details_list_item_1.RefCostContract = 1;
                _option_details_list_item_2.RefCostContract = -1;
                _option_details_list_item_1.RefCostShare = IndexStockListItem.Multiplier;
                _option_details_list_item_2.RefCostShare = IndexStockListItem.Multiplier * (-1);

                _option_details_list_item_1.RefValueContract = -1;
                _option_details_list_item_2.RefValueContract = 1;
                _option_details_list_item_1.RefValueShare = IndexStockListItem.Multiplier * (-1);
                _option_details_list_item_2.RefValueShare = IndexStockListItem.Multiplier;

                _option_details_list_item_1.OptionType = 'LongCall';
                _option_details_list_item_2.OptionType = 'ShortCall';

                V_OptionDetailsList.push(_option_details_list_item_1);
                V_OptionDetailsList.push(_option_details_list_item_2);

                V_ProductCode1 = V_CallOption1.ProductCode;
                V_CallPut1 = 'Call';
                V_ProductCode2 = V_CallOption2.ProductCode;
                V_CallPut2 = 'Call';

                V_Color = 'success';
    
                V_BidAsk1 = V_CallOption1.Ask;
                V_BidAsk2 = V_CallOption2.Bid;
    
                V_BidAskDateTime1 = V_CallOption1.RevisionDateTimeHKAsk;
                V_BidAskDateTime2 = V_CallOption2.RevisionDateTimeHKBid;
    
                V_Cost1 = V_Contract * V_BidAsk1 * IndexStockListItem.Multiplier * (-1);
                V_Cost2 = V_Contract * V_BidAsk2 * IndexStockListItem.Multiplier;
                V_TotalCost = V_Cost1 + V_Cost2;
        
                V_Contract1 = V_Contract;
                V_Contract2 = V_Contract * (-1);

                break;  

            case 'Put':
                
                V_Index = OptionTableList.findLastIndex(x => x.Strike < UnderlyingProductLast);
                V_StrikeDiff = UnderlyingProductLast - OptionTableList[V_Index].Strike;
                V_StrikeDiffString = LongFormattedValue(UnderlyingProductLast, 0, false) + ' - ' + LongFormattedValue(OptionTableList[V_Index].Strike, 0, false) + ' = ' + LongFormattedValue(V_StrikeDiff, 0, false);
    
                if (V_StrikeDiff >= V_MinStrikeDiff){
                    V_Case = 'StrikeDiff (' + V_StrikeDiffString + ') >= 50';
                    V_Contract = V_MaxContract;
                    V_OptionTableListItem1 = OptionTableList[V_Index-1];
                    V_OptionTableListItem2 = OptionTableList[V_Index-0];
                    V_StrikeFinalDiff = UnderlyingProductLast - OptionTableList[V_Index-0].Strike;
                }
                else if (V_StrikeDiff < V_MinStrikeDiff){
                    V_Case = 'StrikeDiff (' + V_StrikeDiffString + ') < 50';
                    V_Contract = V_MaxContract;
                    V_OptionTableListItem1 = OptionTableList[V_Index-2];
                    V_OptionTableListItem2 = OptionTableList[V_Index-1];
                    V_StrikeFinalDiff = UnderlyingProductLast - OptionTableList[V_Index-1].Strike;
                }

                var V_PutOption1 = OptionTableListItem4Put(V_OptionTableListItem1);
                var V_PutOption2 = OptionTableListItem4Put(V_OptionTableListItem2);

                V_ProductType =V_PutOption1.ProductType;

                var V_StrikeGap = V_PutOption2.Strike - V_PutOption1.Strike

                var _option_details_list_item_1 = PutOption2OptionDetailsListItem(V_PutOption1);
                var _option_details_list_item_2 = PutOption2OptionDetailsListItem(V_PutOption2);

                _option_details_list_item_1.Currency = IndexStockListItem.Currency;
                _option_details_list_item_2.Currency = IndexStockListItem.Currency;

                _option_details_list_item_1.RefCostContract = -1;
                _option_details_list_item_2.RefCostContract = 1;
                _option_details_list_item_1.RefCostShare = IndexStockListItem.Multiplier * (-1);
                _option_details_list_item_2.RefCostShare = IndexStockListItem.Multiplier;

                _option_details_list_item_1.RefValueContract = 1;
                _option_details_list_item_2.RefValueContract = -1;
                _option_details_list_item_1.RefValueShare = IndexStockListItem.Multiplier;
                _option_details_list_item_2.RefValueShare = IndexStockListItem.Multiplier * (-1);

                _option_details_list_item_1.OptionType = 'ShortPut';
                _option_details_list_item_2.OptionType = 'LongPut';

                V_OptionDetailsList.push(_option_details_list_item_1);
                V_OptionDetailsList.push(_option_details_list_item_2);
    
                V_ProductCode1 = V_PutOption1.ProductCode;
                V_CallPut1 = 'Put';
                V_ProductCode2 = V_PutOption2.ProductCode;
                V_CallPut2 = 'Put';
        
                V_Color = 'error';
    
                V_BidAsk1 = V_PutOption1.Bid;
                V_BidAsk2 = V_PutOption2.Ask;
    
                V_BidAskDateTime1 = V_PutOption1.RevisionDateTimeHKBid;
                V_BidAskDateTime2 = V_PutOption2.RevisionDateTimeHKAsk;
        
                V_Cost1 = V_Contract * V_BidAsk1 * IndexStockListItem.Multiplier;
                V_Cost2 = V_Contract * V_BidAsk2 * IndexStockListItem.Multiplier * (-1);
    
                V_TotalCost = V_Cost1 + V_Cost2;
        
                V_Contract1 = V_Contract * (-1);
                V_Contract2 = V_Contract;

                break;    

        }   

        var V_Contract1String;
        if (V_Contract1 > 0){
            V_Contract1String = '+' + V_Contract1;
            AvatarBgColor1 = AvatarGreenBgColor;
        }
        else{
            V_Contract1String = V_Contract1;
            AvatarBgColor1 = AvatarRedBgColor;
        }

        var V_Contract2String;
        if (V_Contract2 > 0){
            V_Contract2String = '+' + V_Contract2;
            AvatarBgColor2 = AvatarGreenBgColor;
        }
        else{
            V_Contract2String = V_Contract2;
            AvatarBgColor2 = AvatarRedBgColor;
        }

        var V_CallOpenInterestDiffString, V_PutOpenInterestDiffString;
        if (V_CallOpenInterestDiff > 0){
            V_CallOpenInterestDiffString = '+' + V_CallOpenInterestDiff;
        }
        else{
            V_CallOpenInterestDiffString = V_CallOpenInterestDiff;
        }

        if (V_PutOpenInterestDiff > 0){
            V_PutOpenInterestDiffString = '+' + V_PutOpenInterestDiff;
        }
        else{
            V_PutOpenInterestDiffString = V_PutOpenInterestDiff;
        }

        return(

            <React.Fragment>

            <Box sx={{paddingTop: 2, paddingBottom: 2, paddingLeft: 1, paddingRight: 1}}>

                <Grid container rowSpacing={2} columnSpacing={0} sx={{ flexDirection: 'row' }}>  
                    
                    <Grid item xs={8} lg={8} sx={{display: "", alignItems: "center"}}>
                        {CombinationName} ({V_StrikeGap}, {V_StrikeFinalDiff})
                    </Grid>

                    <Grid item xs={4} lg={4} sx={{display: "flex", alignItems: "center"}}>
                        <Button fullWidth="true" variant="outlined" onClick={() => OptionDetailsListSubmit(V_OptionDetailsList)}>加入我的清單</Button>
                    </Grid>      

                    <Grid item xs={12} lg={12}>
                        <ProductBarInfo
                            Contract={V_Contract1}
                            ProductType={ProductType}
                            ProductCode={V_ProductCode1}
                            CallPut={V_CallPut1}
                            BidAsk={V_BidAsk1}
                            RevisionDateTimeHKBidAsk={V_BidAskDateTime1}
                            Multiplier={IndexStockListItem.Multiplier}
                        />  
                    </Grid>

                    <Grid item xs={12} lg={12}>
                        <ProductBarInfo
                            Contract={V_Contract2}
                            ProductType={ProductType}
                            ProductCode={V_ProductCode2}
                            CallPut={V_CallPut2}
                            BidAsk={V_BidAsk2}
                            RevisionDateTimeHKBidAsk={V_BidAskDateTime2}
                            Multiplier={IndexStockListItem.Multiplier}
                        />
                    </Grid>

                    <Grid item xs={9} lg={9} sx={{display: "flex", alignItems: "center"}}>                     
                        {V_Case}
                    </Grid>
                    <Grid item xs={3} lg={3} align='right'>
                        {LabelPointDollar(null, null, V_ProductType, V_TotalCost/IndexStockListItem.Multiplier, IndexStockListItem.Multiplier, true, null, 1, 11)}
                    </Grid>    

                    <Grid item xs={6} lg={6}>

                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <Grid item xs={12} lg={12}>
                            {LabelPointDollar('最大利潤：', null, V_ProductType, (V_StrikeGap * IndexStockListItem.Multiplier + V_TotalCost)/IndexStockListItem.Multiplier, IndexStockListItem.Multiplier, true, null, 7, 5)}
                        </Grid>
                        <Grid item xs={12} lg={12}> 
                            {LabelPointDollar('最大虧損：', null, V_ProductType, (V_TotalCost)/IndexStockListItem.Multiplier, IndexStockListItem.Multiplier, true, null, 7, 5)}                    
                        </Grid>
                    </Grid>

         

                </Grid>

            </Box>

            </React.Fragment>
            
        )

    }
    else{
        return(
            <React.Fragment>
            </React.Fragment>
        ) 
    }
    
}