// AccordionTooltip.js

import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import LineChart1 from './LineChart1.js';
import Paper from '@material-ui/core/Paper';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Chip from '@material-ui/core/Chip';
import { Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { palette } from '@material-ui/system';
//import { ResponsiveLine } from '@nivo/line'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import { Button, InputLabel, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { withStyles } from "@material-ui/core/styles";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import MuiAlert from '@material-ui/lab/Alert';
import { grey, purple } from '@material-ui/core/colors';
import Icon from '@material-ui/core/Icon';
import AddIcon from '@material-ui/icons/Add';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import RemoveTwoToneIcon from '@material-ui/icons/RemoveTwoTone';
import RemoveCircleOutlineTwoToneIcon from '@material-ui/icons/RemoveCircleOutlineTwoTone';
import { makeStyles } from '@material-ui/core/styles';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function AccordionTooltip(props){

    const { 
        Summary,
        Details,
    } = props;

    return(
        <div>
            <Accordion>
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">    
                {Summary}    
            </AccordionSummary>
            <AccordionDetails>
                <Typography variant="caption">
                    <Box color={'#9e9e9e'}>
                        {Details}
                    </Box>
                </Typography>
            </AccordionDetails>
            </Accordion>
      </div>         
    )

}

class AccordionTooltip123 extends React.Component{

    constructor(props){
        super(props);
      } 

      render(){
        return(
            <div>
                <Accordion>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">    
                    {this.props.Summary}    
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="caption">
                        <Box color={'#9e9e9e'}>
                            {this.props.Details}
                        </Box>
                    </Typography>
                </AccordionDetails>
                </Accordion>
          </div>         
        )
    }

}