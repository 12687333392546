// OptionTable2.js

import React from 'react';
import axios from 'axios';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { useState, useEffect, createContext, useContext } from "react";

import Container from '@material-ui/core/Container';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { red, green, grey } from '@mui/material/colors';
import { Divider } from '@mui/material';
import Chip from '@mui/material/Chip';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import AuthenticationService from '../AuthenticationService.js';
import CurrentHistorical from './CurrentHistorical.js';
import MaterialTableOptionTable from './MaterialTableOptionTable.js';
import MaterialTableOptionDetailsList from './MaterialTableOptionDetailsList.js';
import StrikeReferenceLines_Get from './StrikeReferenceLines_Get.js';
import ButtonAppBar from "./ButtonAppBar.js";
import UnderlyingProduct1 from './UnderlyingProduct1.js';
import ToggleButtons from './ToggleButtons.js';
import DebitSuggestion1 from './DebitSuggestion1.js';
import CreditSuggestion1 from './CreditSuggestion1.js';
import UnderlyingProductDetails from './UnderlyingProductDetails.js';
import { ChartDataDateTimeHKContext, IndexStockListItemContext } from '../Context.js';
import { NowGet, WebSocket_Open, UnderlyingProduct_Show, OnMessageStock, OnMessageStockOption, PutOption2OptionDetailsListItem, CallOption2OptionDetailsListItem, LongUnderlyingProductValue, Number2Dollar, FormattedNumber, CheckAuthenticated, LongExpiry, ShortExpiry, Expiry_Get, SetBidAskValue, GroupSummaryCostValueSum, GroupNumberList_Update, ActiveOptionTableList_Get, OnMessageIndex, OnMessageIndexOption } from './Common.js';

export default function OptionTable2(props){

    const [YMin, setYMin] = useState(0);
    const [YMax, setYMax] = useState(0);

    const [OptionTableList, setOptionTableList] = useState([]);
    const [OptionDetailsList, setOptionDetailsList] = useState([]);
    const [Now, setNow] = useState('');
    const [UnderlyingProductCode, setUnderlyingProductCode] = useState('HK.HSI');
    const [Currency, setCurrency] = useState('HKD');
    const [TimeStatus, setTimeStatus] = useState('Current');
    const [IndexStockListItem, setIndexStockListItem] = useState({});
    const [OptionList, setOptionList] = useState([]);
    const [Expiry, setExpiry] = useState('');
    const [StrikeReferenceLines, setStrikeReferenceLines] = useState([]);
    const [ProductType, setProductType] = useState('IndexOption');
    const [UnderlyingProductCode2, setUnderlyingProductCode2] = useState('');
    const [GroupNumberList, setGroupNumberList] = useState([]);
    const [Mode, setMode] = useState('Insert');
    const [OptionDetailsListLength, setOptionDetailsListLength] = useState(0);
    const [GroupTitle, setGroupTitle] = useState('');
    const [ToggleButton, setToggleButton] = useState('Theta');
    const [WebSocketConsoleMessage, setWebSocketConsoleMessage] = useState('Console Message');
    const [WebSocketOpen, setWebSocketOpen] = useState('WebSocket');
    const [WebSocketOpenBgColor, setWebSocketOpenBgColor] = useState('warning');
    const [ShowCreditSuggestion, setShowCreditSuggestion] = useState(false);
    const [ShowDebitSuggestion, setShowDebitSuggestion] = useState(false);
    const [Auto, setAuto] = useState(false);
    const [Demo, setDemo] = useState(false);
    const [WSClient, setWSClient] = useState('');
    const [RevisionDateTimeHKLast, setRevisionDateTimeHKLast] = useState('');
    const [ChartData, setChartData] = useState([]);
    const [ChartDataDateTimeHK, setChartDataDateTimeHK] = useState('');
    const [MaxAsk, setMaxAsk] = useState('09');
    // Country
    const [Country, setCountry] = useState('');
    const [ProductTypeUnderlyingProductCodeExpiryList, setProductTypeUnderlyingProductCodeExpiryList] = useState([]);

  // function NowGet(){

  //   let _now = new Date();

  //   let _year = _now.getFullYear().toString();
  //   let _month = (_now.getMonth() + 1).toString();
  //   let _day = _now.getDate().toString();
  //   let _hour = _now.getHours().toString();
  //   let _minute = _now.getMinutes().toString();

  //   let _now_string = _month.padStart(2, "0") + '-' + _day.padStart(2, "0") + ' ' + _hour.padStart(2, "0") + ':' + _minute.padStart(2, "0");

  //   setNow(_now_string);

  // } 

  function UpdateOptionDetailsListItem(p_option_details_list_item){  
    SetBidAskValue(OptionTableList, p_option_details_list_item);
  }

  function UpdateOptionDetailsList(){
    OptionDetailsList.map((_option_details_list_item) => (
      UpdateOptionDetailsListItem(_option_details_list_item)
    ))
  }

  function ProductTypeUnderlyingProductCodeExpiryList_Get(P_ProductType){

    var v_http_post_data = {
      Credential: AuthenticationService.getCurrentUser(),
      TimeStatus: TimeStatus,
    }

    const url = sessionStorage.getItem('SiteURL') + '/api/OptionList/ProductTypeUnderlyingProductCodeExpiry/';

    axios.post(url, v_http_post_data)
  
    .then(resp => {  
        var V_ProductTypeUnderlyingProductCodeExpiryList = resp.data;
        setProductTypeUnderlyingProductCodeExpiryList(V_ProductTypeUnderlyingProductCodeExpiryList);
    });

  }

  function IndexStockListItem_Get(P_ProductType){

    var V_UnderlyingProductType;
    
    switch(P_ProductType){
      case 'IndexOption':
        V_UnderlyingProductType = 'Index';
        break;
      case 'StockOption':
        V_UnderlyingProductType = 'Stock';
        break;
    }

    var v_http_post_data = {
      Credential: AuthenticationService.getCurrentUser(),
      UnderlyingProductCode: UnderlyingProductCode,
      TimeStatus: TimeStatus,
    }
  
    var url;
    switch(V_UnderlyingProductType){
        case 'Index':
            url = sessionStorage.getItem('SiteURL') + 'api/IndexListItem/IndexCode/';
            break;  
        case 'Stock':
            url = sessionStorage.getItem('SiteURL') + 'api/StockListItem/StockCode/';
            break;    
    }    
  
    axios.post(url, v_http_post_data)
  
    .then(resp => {
  
        var V_IndexStockListItem = resp.data;
        V_IndexStockListItem.ProductType = V_UnderlyingProductType;
        setCountry(V_IndexStockListItem.Country);
        setIndexStockListItem(V_IndexStockListItem);

    });

  }

  function OptionTableList_Get(P_IndexStockListItem){

    var v_http_post_data = {
      Credential: AuthenticationService.getCurrentUser(),
      OptionList: OptionList,
      Expiry: Expiry,
      TimeStatus: TimeStatus,
    }
    
    const url = sessionStorage.getItem('SiteURL') + '/api/OptionTableList/InsertNew2/';

    axios.post(url, v_http_post_data)

        .then(resp => {

          let _OptionTableList = resp.data;

          if (_OptionTableList.length > 0){
            var V_ActiveOptionTableList = ActiveOptionTableList_Get(_OptionTableList, P_IndexStockListItem.Last);
            setOptionTableList(V_ActiveOptionTableList);
            // NowGet();
            // UpdateOptionDetailsList();
            // this.setState({OptionTableList: V_ActiveOptionTableList}, () => {    
            //   this.Now();
            //   this.UpdateOptionDetailsList();
            // });
          }

      });

  }

  function OptionList_Get(){

    var v_http_post_data = {
      Credential: AuthenticationService.getCurrentUser(),
      ProductType: ProductType,
      UnderlyingProductCode: UnderlyingProductCode,
      TimeStatus: TimeStatus,
    }
    
    const url = sessionStorage.getItem('SiteURL') + '/api/OptionList/ByUnderlyingProductCode/';

    axios.post(url, v_http_post_data)

        .then(resp => {
          
            let _OptionList = resp.data;

            setOptionList(_OptionList);

            // var V_UnderlyingProductType;
            // switch(ProductType){
            //   case 'IndexOption':
            //     V_UnderlyingProductType = 'Index';
            //     break;
            //   case 'StockOption':
            //     V_UnderlyingProductType = 'Stock';
            //     break;
            // }
            // OptionTableList_Get(IndexStockListItem);            

        //   this.setState({OptionList: _OptionList}, () => {
        //     var V_UnderlyingProductType;
        //     switch(this.state.ProductType){
        //       case 'IndexOption':
        //         V_UnderlyingProductType = 'Index';
        //         break;
        //       case 'StockOption':
        //         V_UnderlyingProductType = 'Stock';
        //         break;
        //     }
        //     this.OptionTableList_Get(this.state.IndexStockListItem);
        //     });

        });

  }

  function PriceHistoryListToday_Get(P_UnderlyingProductType){

    var v_http_post_data = {
      Credential: AuthenticationService.getCurrentUser(),
      ProductType: P_UnderlyingProductType,
      UnderlyingProductCode: UnderlyingProductCode,
      PriceType: 'Last',
      // Country: 'HK',
    }

    const url = sessionStorage.getItem('SiteURL') + '/api/PriceHistoryList/Today/';

    axios.post(url, v_http_post_data)

    .then(resp => {

      var V_ConsoleDateTime = new Date().toLocaleTimeString('en-US', { hour12: false, hour: "numeric", minute: "numeric", second: "numeric"});
      var V_ConsoleMessage = 'PriceHistoryList/Today/' + ' (' + V_ConsoleDateTime +')';
      console.log(V_ConsoleMessage);

      var V_Data = resp.data;
      var V_ChartData = [];

      for (var _x_index = 0; _x_index < V_Data.length; _x_index++){

          var V_Price;
          switch(P_UnderlyingProductType){
              case 'Index':
                  V_Price = V_Data[_x_index].Price.toFixed(0);
                  break;  
              case 'Stock':
                  V_Price = V_Data[_x_index].Price.toFixed(2);
                  break;    
          }            

          var y = parseFloat(V_Price);

          let _xy = {};
          _xy.x = _x_index;
          _xy.y = y;
          V_ChartData.push(_xy);

      }

      setChartData(V_ChartData);

      if (V_Data.length > 0){
        var V_ChartDataDateTimeHK = V_Data[V_Data.length-1].CreationDateTimeHK;
        setChartDataDateTimeHK(V_ChartDataDateTimeHK);
      }

    });     

  }

  function SubmitAction(){

    IndexStockListItem_Get(ProductType);
    OptionList_Get();
    setUnderlyingProductCode2(UnderlyingProductCode);

    var V_UnderlyingProductType;

    switch(ProductType){
      case 'IndexOption':
        V_UnderlyingProductType = 'Index';
        break;
      case 'StockOption':
        V_UnderlyingProductType = 'Stock';
        break;
    }

    PriceHistoryListToday_Get(V_UnderlyingProductType);
    const interval = setInterval(() => {
      PriceHistoryListToday_Get(V_UnderlyingProductType);        
    }, 60000);

    return () => clearInterval(interval);

  }

  function UnderlyingProductLast_Callback(P_UnderlyingProductLast){
    var V_IndexStockListItem = IndexStockListItem;
    V_IndexStockListItem.Last = P_UnderlyingProductLast;
    setIndexStockListItem(V_IndexStockListItem);
  }

  function OptionListItem_Callback(P_OptionListItem){

    var V_Testing = true;

    var V_OptionList = OptionList;

    var _index1 = V_OptionList.findIndex(x => x.ProductCode === P_OptionListItem.ProductCode);

    if (_index1 !== -1){  

      if (V_Testing){
        // V_OptionList[_index1].Bid = P_OptionListItem.Bid;
        // V_OptionList[_index1].Ask = P_OptionListItem.Ask;
        // V_OptionList[_index1].RevisionDateTimeHKBid = P_OptionListItem.RevisionDateTimeHKBid;
        // V_OptionList[_index1].RevisionDateTimeHKAsk = P_OptionListItem.RevisionDateTimeHKAsk;
        V_OptionList[_index1].Bid = P_OptionListItem.Bid;
        V_OptionList[_index1].Ask = P_OptionListItem.Ask;
        V_OptionList[_index1].Volume = P_OptionListItem.Volume;
        V_OptionList[_index1].RevisionDateTimeHKBid = P_OptionListItem.RevisionDateTimeHKBid;
        V_OptionList[_index1].RevisionDateTimeHKAsk = P_OptionListItem.RevisionDateTimeHKAsk;
        V_OptionList[_index1].RevisionDateTimeHKVolume = P_OptionListItem.RevisionDateTimeHKVolume;        
      }
      else{
        V_OptionList[_index1] = P_OptionListItem;
      }
      
    }

    //

    var V_OptionTableList = OptionTableList;

    switch(P_OptionListItem.CallPut){

      case 'C':
        
        var _index2 = V_OptionTableList.findIndex(x => x.CallProductCode === P_OptionListItem.ProductCode);

        if (_index2 !== -1){              
          V_OptionTableList[_index2].CallBid = P_OptionListItem.Bid;
          V_OptionTableList[_index2].CallAsk = P_OptionListItem.Ask;
          V_OptionTableList[_index2].CallLast = P_OptionListItem.Last;
          V_OptionTableList[_index2].CallVolume = P_OptionListItem.Volume;
          V_OptionTableList[_index2].RevisionDateTimeHKCallBid = P_OptionListItem.RevisionDateTimeHKBid;
          V_OptionTableList[_index2].RevisionDateTimeHKCallAsk = P_OptionListItem.RevisionDateTimeHKAsk;
          V_OptionTableList[_index2].RevisionDateTimeHKCallLast = P_OptionListItem.RevisionDateTimeHKLast;
          V_OptionTableList[_index2].RevisionDateTimeHKCallVolume = P_OptionListItem.RevisionDateTimeHKVolume;
        }

        break;

      case 'P':

        var _index3 = V_OptionTableList.findIndex(x => x.PutProductCode === P_OptionListItem.ProductCode);

        if (_index3 !== -1){              
          V_OptionTableList[_index3].PutBid = P_OptionListItem.Bid;
          V_OptionTableList[_index3].PutAsk = P_OptionListItem.Ask;
          V_OptionTableList[_index3].PutLast = P_OptionListItem.Last;
          V_OptionTableList[_index3].PutVolume = P_OptionListItem.Volume;
          V_OptionTableList[_index3].RevisionDateTimeHKPutBid = P_OptionListItem.RevisionDateTimeHKBid;
          V_OptionTableList[_index3].RevisionDateTimeHKPutAsk = P_OptionListItem.RevisionDateTimeHKAsk;
          V_OptionTableList[_index3].RevisionDateTimeHKPutLast = P_OptionListItem.RevisionDateTimeHKLast;
          V_OptionTableList[_index3].RevisionDateTimeHKPutVolume = P_OptionListItem.RevisionDateTimeHKVolume;
        }

        break;

    }
    
    setOptionList(V_OptionList);
    setOptionTableList(V_OptionTableList);

    // this.setState({
    //   OptionList: V_OptionList,
    //   OptionTableList: V_OptionTableList,
    // }, () => {

    // });

  }

  function OptionDetailsListSort(p_OptionDetailsList){
    let _list = p_OptionDetailsList.sort((a, b) => (a.Strike > b.Strike) ? 1 : (a.Strike === b.Strike) ? ((a.CallPut == 'P') ? 1 : -1) : -1 )
    return _list;
  }

  function CallProductCodeSubmitAction(P_CallOption){

    let V_GroupNumberList = [...GroupNumberList]; // if directly "V_GroupNumberList = GroupNumberList", GroupNumberList was updated simultaneously with V_GroupNumberList before setGroupNumberList and thus no re-render will be called
    let _group_number_list_item = V_GroupNumberList[0];
    let _option_details_list = _group_number_list_item.OptionDetailsList;
    const _index = _option_details_list.findIndex(x => x.ProductCode == P_CallOption.ProductCode);

    if (_index == -1){

        let _option_details_list_item = CallOption2OptionDetailsListItem(P_CallOption);      
        _option_details_list_item.Currency = Currency;
        _option_details_list.push(_option_details_list_item);
        _option_details_list = OptionDetailsListSort(_option_details_list);
        setGroupNumberList(V_GroupNumberList);

    }

  }

  function PutProductCodeSubmitAction(P_PutOption){

    let V_GroupNumberList = [...GroupNumberList]; // if directly "V_GroupNumberList = GroupNumberList", GroupNumberList was updated simultaneously with V_GroupNumberList before setGroupNumberList and thus no re-render will be called
    let _group_number_list_item = V_GroupNumberList[0];
    let _option_details_list = _group_number_list_item.OptionDetailsList;
    const _index = _option_details_list.findIndex(x => x.ProductCode == P_PutOption.ProductCode);

    if (_index == -1){

      var _option_details_list_item = PutOption2OptionDetailsListItem(P_PutOption); 
      _option_details_list_item.Currency = Currency;     
      _option_details_list.push(_option_details_list_item); 
      _option_details_list = OptionDetailsListSort(_option_details_list);
      setGroupNumberList(V_GroupNumberList);

    }

  }

  function onChange_Mode(p_event){
    let _mode = p_event.target.value;

    this.setState({Mode: _mode});

  }

  function GroupNumberListItem_Callback(P_GroupNumberListItem){
    let V_GroupNumberList = [...GroupNumberList]; // if directly "V_GroupNumberList = GroupNumberList", GroupNumberList was updated simultaneously with V_GroupNumberList before setGroupNumberList and thus no re-render will be called
    V_GroupNumberList[0] = P_GroupNumberListItem;
    setGroupNumberList(V_GroupNumberList);
  }

  function GroupNumberList_Create(){

    var V_GroupNumberListItem = {
      Number: 0,
      OptionDetailsList: [],
      TotalCostAvg: 0,
      TotalValueAvg: 0,
      TotalProfitAvg: 0,
      TotalIntrinsicValueAvg: 0,
      TotalIntrinsicValueProfitAvg: 0,
      Updated: {GroupTitle: ''},
      Active: true,
      AllExpired: false,
    }

    let V_GroupNumberList = [];
    V_GroupNumberList.push(V_GroupNumberListItem);
    setGroupNumberList(V_GroupNumberList);

    }

    function GroupNumberListItem_Existed(){

        if (GroupNumberList[0] != null)
        {

            return(
            <div>
              <MaterialTableOptionDetailsList
                ProductType={ProductType}
                // Currency={Currency}
                OptionList={OptionList}
                OptionDetailsListLength={OptionDetailsListLength}
                UnderlyingProductLast={IndexStockListItem.Last}
                GroupNumberListItem={GroupNumberList[0]} 
                GroupNumberListItem_Callback={GroupNumberListItem_Callback}
                GroupTitle_Callback123={setGroupTitle}
                GroupTitle={GroupTitle}
                CallerType='Draft'
                Multiplier={IndexStockListItem.Multiplier}
                Exchange={IndexStockListItem.Exchange}
                // OptionDetailsList={OptionDetailsList}
                Currency={Currency}
              />
            </div>
          )
  
        }
  
      }

    function ToggleButtons_Callback(P_ToggleButton){
        setToggleButton(P_ToggleButton);
    }

    // function WebSocket_Open(P_Message, P_WebSocketOpenBgColor){

    //   var V_WebSocketURL = sessionStorage.getItem('WebSocketURL');
    //   const _ws_client = new W3CWebSocket(V_WebSocketURL);      

    //   _ws_client.onopen = () => {

    //     var V_ConsoleMessage = P_Message;
    //     var V_ConsoleDateTime = new Date().toLocaleTimeString('en-US', { hour12: false, hour: "numeric", minute: "numeric", second: "numeric"});
    //     var V_WebSocketOpen = V_ConsoleMessage + ' (' + V_ConsoleDateTime + ')';
    
    //     _ws_client.onmessage = (message) => {
        
    //       var V_Data = JSON.parse(message.data);
  
    //       switch(V_Data.ProductType){
  
    //         case 'Index':  
    //           if (ProductType == 'IndexOption')
    //           {
    //             setWebSocketOpen(V_WebSocketOpen);
    //             setWebSocketOpenBgColor(P_WebSocketOpenBgColor);
    //             OnMessageIndex(V_Data, setIndexStockListItem, setWebSocketConsoleMessage, 'OptionTable');
    //           }                
    //           break;           
  
    //         case 'Stock':  
    //           if (ProductType == 'StockOption')
    //           {
    //             setWebSocketOpen(V_WebSocketOpen);
    //             setWebSocketOpenBgColor(P_WebSocketOpenBgColor);
    //             OnMessageStock(V_Data, setIndexStockListItem, setWebSocketConsoleMessage, 'OptionTable');
    //           }            
    //           break;

    //         case 'IndexOption':
    //           if (ProductType == 'IndexOption')
    //           {
    //             setWebSocketOpen(V_WebSocketOpen);
    //             setWebSocketOpenBgColor(P_WebSocketOpenBgColor);
    //             OnMessageIndexOption(V_Data, OptionListItem_Callback, setWebSocketConsoleMessage, 'OptionTable');
    //           } 
    //           break;

    //         case 'StockOption':
    //           if (ProductType == 'StockOption')
    //           {
    //             setWebSocketOpen(V_WebSocketOpen);
    //             setWebSocketOpenBgColor(P_WebSocketOpenBgColor);
    //             OnMessageStockOption(V_Data, OptionListItem_Callback, setWebSocketConsoleMessage, 'OptionTable');
    //           } 
    //           break;

    //       }
  
    //     }
  
    //     _ws_client.onclose = () => {
    //       WebSocket_Open('Opened', 'error');
    //     };

    //     _ws_client.onerror = (err) => {
    //       console.error('WebSocket encountered error: ', err.message, 'Closing socket');
    //       _ws_client.close();
    //     };

    //   }

    // }

    function ShowDebitSuggestionAction(){
        setShowDebitSuggestion(!ShowDebitSuggestion);
    }
  
    function ShowCreditSuggestionAction(){
        setShowCreditSuggestion(!ShowCreditSuggestion);
    }
  
    function TriggerAction(){
  
      var v_http_post_data = {
        Credential: AuthenticationService.getCurrentUser(),
        ProductType: ProductType,
        UnderlyingProductCode: UnderlyingProductCode,
        Expiry: Expiry,
        // CallPut: P_CallPut,
        MaxAsk: MaxAsk,
      }
    
      const url = sessionStorage.getItem('SiteURL') + '/api/OptionDetailsList/TriggerPrepare/';
    
      axios.post(url, v_http_post_data)
    
      .then(resp => {
        let _return_value = resp.data;
      });

    }

    // function TriggerCallAction(){
  
    //   var v_http_post_data = {
    //     Credential: AuthenticationService.getCurrentUser(),
    //     ProductType: ProductType,
    //     UnderlyingProductCode: UnderlyingProductCode,
    //     Expiry: Expiry,
    //     CallPut: 'C',
    //     MaxAsk: MaxAsk,
    //   }
    
    //   const url = sessionStorage.getItem('SiteURL') + '/api/OptionDetailsList/TriggerPrepare/';
    
    //   axios.post(url, v_http_post_data)
    
    //   .then(resp => {
    //     let _return_value = resp.data;
    //   });

    // }

    // function TriggerPutAction(){
  
    //   var v_http_post_data = {
    //     Credential: AuthenticationService.getCurrentUser(),
    //     ProductType: ProductType,
    //     UnderlyingProductCode: UnderlyingProductCode,
    //     Expiry: Expiry,
    //     CallPut: 'P',
    //     MaxAsk: MaxAsk,
    //   }
    
    //   const url = sessionStorage.getItem('SiteURL') + '/api/OptionDetailsList/TriggerPrepare/';
    
    //   axios.post(url, v_http_post_data)
    
    //   .then(resp => {
    //     let _return_value = resp.data;
    //   });

    // }

    function AutoAction(){

        setAuto(!Auto);

        // switch(Auto){
  
        //     case true:
            
        //       WebSocket_Open('Opened', 'warning');
        //       break;
        
        //     case false:      
        
        //       break;
        
        // }

        // this.setState({Auto: !this.state.Auto}, () => {
  
        //   switch(Auto){
  
        //     case true:
            
        //       WebSocket_Open('Opened', 'warning');
        //       break;
        
        //     case false:      
        
        //       break;
        
        //   }
  
        // });   
        
      }

    // useEffect(() => {    
    //   var v = 1;
    // }, [GroupNumberList]);

    useEffect(() => {    
      switch(Auto){  
        case true:            
          WebSocket_Open('Opened', 'warning', setWebSocketOpen, setWebSocketOpenBgColor, ProductType, UnderlyingProductCode, setIndexStockListItem, setWebSocketConsoleMessage, OptionListItem_Callback);
          // WebSocket_Open(P_Message, P_WebSocketOpenBgColor, P_setWebSocketOpen, P_setWebSocketOpenBgColor, P_ProductType, P_setIndexStockListItem, P_setWebSocketConsoleMessage, P_OptionListItem_Callback)
          break;        
        case false:              
          break;        
      }
    }, [Auto]);

    useEffect(() => {    
        setNow(NowGet());
        UpdateOptionDetailsList();
    }, [OptionTableList]);

    useEffect(() => {    
        // var V_UnderlyingProductType;
        // switch(ProductType){
        //   case 'IndexOption':
        //     V_UnderlyingProductType = 'Index';
        //     break;
        //   case 'StockOption':
        //     V_UnderlyingProductType = 'Stock';
        //     break;
        // }
        OptionTableList_Get(IndexStockListItem);  
    }, [OptionList]);

    useEffect(() => {    
        ProductTypeUnderlyingProductCodeExpiryList_Get();
        GroupNumberList_Create();
        IndexStockListItem_Get(ProductType);          
    }, []); // Runs only on the first render

    function AutoCheckbox_Show(){

        if (Demo == false){
  
          return(
  
            <React.Fragment>
  
                <Grid container spacing={2}>
                  <Grid item align="left" xs={9} lg={9}>
                    <Button variant="contained" color="primary" fullWidth="true" onClick={SubmitAction}>確定 (02)</Button>
                  </Grid>
                  <Grid item align="right" xs={3} lg={3}>
                    <FormControlLabel control={<Checkbox />} label="Auto" onClick={AutoAction}/>
                  </Grid>
                </Grid> 
  
            </React.Fragment>
  
          )
  
        }
        else{
  
          return(
  
            <React.Fragment>
  
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12}>
                    <Button variant="contained" color="primary" fullWidth="true" onClick={SubmitAction}>確定 (02)</Button>
                  </Grid>
                  {/* <Grid item align="right" xs={3} lg={3}>
                    <FormControlLabel control={<Checkbox />} label="Auto" onClick={this.AutoAction}/>
                  </Grid> */}
                </Grid> 
  
            </React.Fragment>
  
          )
  
        }
  
      }

      function DebitSuggestion_Show(P_ShowSuggestion){

          if (Demo == false){
    
            return(
    
              <React.Fragment>
    
              <div style={{ display: P_ShowSuggestion == true ? 'block' : 'none'}}>
              
              
              <Box bgcolor={green[50]}>
                
                <DebitSuggestion1
                  ProductType={ProductType}
                  OptionTableList={OptionTableList}
                  IndexStockListItem={IndexStockListItem}
                  CallPut='Put'
                  CombinationName='Long Put Vertical Spread'
                />            
                <br/>
                <Divider color={grey[300]} variant="middle"></Divider>
                <br/>
                <DebitSuggestion1
                  ProductType={ProductType}
                  OptionTableList={OptionTableList}
                  IndexStockListItem={IndexStockListItem}
                  CallPut='Call'
                  CombinationName='Long Call Vertical Spread'
                />            
                <br/>
              </Box>
    
              <br/>
              
              </div>
    
              </React.Fragment>
    
            )
    
          }
          else{
    
            return(
              <React.Fragment>
              </React.Fragment>
            )
    
          }

      }

      function CreditSuggestion_Show(P_ShowSuggestion){

        if (ProductType == 'IndexOption'){
  
          if (Demo == false){
  
            return(
    
              <React.Fragment>
    
              <div style={{ display: P_ShowSuggestion == true ? 'block' : 'none'}}> 
    
              <br/>
    
              <Box bgcolor={red[50]}>
    
                <CreditSuggestion1
                  OptionTableList={OptionTableList}
                  IndexStockListItem={IndexStockListItem}
                  CallPut='Put'
                  CombinationName='Short Put Vertical Spread'
                  IndexGap={1}
                  StrikeGap={1}
                />              
                <br/>
                <Divider color={grey[300]} variant="middle"></Divider>
                <br/>
                <CreditSuggestion1
                  OptionTableList={OptionTableList}
                  IndexStockListItem={IndexStockListItem}
                  CallPut='Call'
                  CombinationName='Short Call Vertical Spread'
                  IndexGap={1}
                  StrikeGap={1}
                />
                <br/>
              </Box>
    
              <br/>
    
              <Box bgcolor={red[50]}>
    
              <CreditSuggestion1
                  OptionTableList={OptionTableList}
                  IndexStockListItem={IndexStockListItem}
                  CallPut='Put'
                  CombinationName='Short Put Vertical Spread'
                  IndexGap={1}
                  StrikeGap={2}
                />              
                <br/>
                <Divider color={grey[300]} variant="middle"></Divider>
                <br/>
                <CreditSuggestion1
                  OptionTableList={OptionTableList}
                  IndexStockListItem={IndexStockListItem}
                  CallPut='Call'
                  CombinationName='Short Call Vertical Spread'
                  IndexGap={1}
                  StrikeGap={2}
                />
              <br/>
              </Box>
    
              <br/>
    
              <Box bgcolor={red[50]}>
    
              <CreditSuggestion1
                  OptionTableList={OptionTableList}
                  IndexStockListItem={IndexStockListItem}
                  CallPut='Put'
                  CombinationName='Short Put Vertical Spread'
                  IndexGap={2}
                  StrikeGap={2}
                />              
                <br/>
                <Divider color={grey[300]} variant="middle"></Divider>
                <br/>
                <CreditSuggestion1
                  OptionTableList={OptionTableList}
                  IndexStockListItem={IndexStockListItem}
                  CallPut='Call'
                  CombinationName='Short Call Vertical Spread'
                  IndexGap={2}
                  StrikeGap={2}
                />
              <br/>
              </Box>
    
              
              </div>
    
              </React.Fragment>
    
            )
    
          }
          else{
    
            return(
              <React.Fragment>
              </React.Fragment>
            )
    
          }
  
        }
        else{
  
          return(
            <React.Fragment>
            </React.Fragment>
          )
    
        }
  
      }

      // function UnderlyingProduct_Show(){

      //   if (Demo == false){
  
      //     var V_UnderlyingProductType;
    
      //     switch(ProductType){
      //       case 'IndexOption':
      //         V_UnderlyingProductType = 'Index';
      //         break;
      //       case 'StockOption':
      //         V_UnderlyingProductType = 'Stock';
      //         break;
      //     }

      //     return(
  
      //       <UnderlyingProduct1 
      //         ProductType={V_UnderlyingProductType} 
      //         UnderlyingProductCode={UnderlyingProductCode} 
      //         TimeStatus={'Current'}
      //         OptionList={OptionList}
      //         ChartData={ChartData}
      //         ChartDataDateTimeHK={ChartDataDateTimeHK}
      //       />
  
      //     )
  
      //   }
      //   else{
  
      //     return(
      //       <React.Fragment>
      //       </React.Fragment>
      //     )
  
      //   }
  
      // }

      var V_Debug = WSClient;

      if (IndexStockListItem.Last !== undefined & IndexStockListItem.Last !== null){
        GroupNumberList_Update(OptionList, GroupNumberList, IndexStockListItem.Last);
      }
  
      return (
  
           <div>
  
            <IndexStockListItemContext.Provider value={IndexStockListItem}>
  
              <ButtonAppBar />
  
              <Container maxWidth="lg">
              <br/>
                <CurrentHistorical
                  ProductTypeUnderlyingProductCodeExpiryList={ProductTypeUnderlyingProductCodeExpiryList}
                  ProductType_Callback={setProductType} 
                  UnderlyingProductCode_Callback={setUnderlyingProductCode} 
                  Currency_Callback={setCurrency}
                  Expiry_Callback={setExpiry} 
                  TimeStatus_Callback={setTimeStatus}
                  Expiry={Expiry} 
                  NoExpiry={false}
                />
  
                {AutoCheckbox_Show()}
  
              </Container>
              <br/><br/>
  
                {UnderlyingProduct_Show(Demo, ProductType, UnderlyingProductCode, OptionList, ChartData, ChartDataDateTimeHK)}
  
              <br/><br/>
  
              <Grid container spacing={2}>
                <Grid item align="center" xs={12} lg={12}>
                  <ToggleButtons DefaultToggleButton={ToggleButton} ToggleButtons_Callback={ToggleButtons_Callback}/>
                </Grid>
              </Grid>            
  
              <br/><br/>
  

              <br/>
              <UnderlyingProductDetails
                IndexStockListItem={IndexStockListItem}
              />
             
              <br/>
  
              <Snackbar open={true} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
                <Alert icon={false} severity={WebSocketOpenBgColor}>
                  {WebSocketOpen}
                </Alert>
              </Snackbar>
  
              <Snackbar open={true} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert icon={false} severity={'info'}>
                  {WebSocketConsoleMessage}
                </Alert>
              </Snackbar>
  
              <MaterialTableOptionTable
                Country={Country}
                ProductType={ProductType}
                UnderlyingProductCode={UnderlyingProductCode}
                OptionTableList={OptionTableList}
                OptionTableListLength={OptionTableList.length}
                CallProductCodeSubmitAction={CallProductCodeSubmitAction}
                PutProductCodeSubmitAction={PutProductCodeSubmitAction}
                RevisionDateTimeHKLast={RevisionDateTimeHKLast}
                OptionList={OptionList}
                UnderlyingProductCode2={UnderlyingProductCode2}
                TimeStatus={TimeStatus}
                UnderlyingProductLast_Callback={UnderlyingProductLast_Callback}
                OptionListItem_Callback={OptionListItem_Callback}
                Auto={Auto}
                WSClient={WSClient}
  
                StrikeReferenceLines={StrikeReferenceLines} 
                YMin={YMin}    
                YMax={YMax}
                YMin_Callback={setYMin}           
                YMax_Callback={setYMax} 
  
                ToggleButton={ToggleButton}
              />            
  
 
              <Grid container spacing={2} sx={{display: "flex", alignItems: "center"}}>

                  <Grid item align="center" xs={6} lg={6}>
                    <FormControlLabel control={<Checkbox />} label="Debit" onClick={ShowDebitSuggestionAction}/>
                  </Grid>
                  <Grid item align="center" xs={6} lg={6}>
                    <FormControlLabel control={<Checkbox />} label="Credit" onClick={ShowCreditSuggestionAction}/>
                  </Grid>

                  <Grid item align="center" xs={12} lg={12}>
                    <Box display="flex" justifyContent="center">
                      <RadioGroup row defaultValue={MaxAsk} onChange={e => setMaxAsk(e.target.value)}>
                        <FormControlLabel value="99" control={<Radio />} label="99" />
                        <FormControlLabel value="09" control={<Radio />} label="09" />
                        <FormControlLabel value="08" control={<Radio />} label="08" />
                        <FormControlLabel value="07" control={<Radio />} label="07" />
                        <FormControlLabel value="06" control={<Radio />} label="06" />
                      </RadioGroup>
                    </Box>
                  </Grid>

                  <Grid item align="center" xs={12} lg={12}>
                    <Button variant="contained" color="warning" onClick={() => TriggerAction()}>Trigger</Button>
                  </Grid>  

              </Grid>     
                     
              <br/><br/>
  
              {DebitSuggestion_Show(ShowDebitSuggestion)}
              {CreditSuggestion_Show(ShowCreditSuggestion)}       
  
              <br/>
  
              {GroupNumberListItem_Existed()}
              <br/><br/>
  
              </IndexStockListItemContext.Provider>
  
           </div>
  
      );
      
}
