// DropDownList_LossPotential.js

import React from 'react';
import { useState, useEffect, createContext, useContext } from "react";
import { InputLabel } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

function DropDownList_LossPotential(props){

  const { 
    LossPotentialDefaultValue,
    LossPotential_Callback
  } = props;

  const [LossPotential, setLossPotential] = useState(LossPotentialDefaultValue);

  function onChange_LossPotential(P_LossPotential){
    setLossPotential(P_LossPotential);
    LossPotential_Callback(P_LossPotential);
  }

      return(
        <div>
          <FormControl style={{ width: '100%' }}>
          <InputLabel id="LossPotentialLabel">最大損失</InputLabel>
          <Select native='true' name="LossPotential" id="LossPotential" labelId="LossPotentialLabel" value={LossPotential} onChange={e => onChange_LossPotential(e.target.value)}>
              <option key='0' value=''></option>
              <option key='1' value='Limited'>有限</option>
              <option key='2' value='Unlimited'>無限</option>
              <option key='3' value='Unknown'>未知</option>          
          </Select>
          </FormControl>
        </div>
          )

}

class DropDownList_LossPotential123 extends React.Component{

    constructor(props){

      super(props);
      this.state = {
        LossPotential: ''
      }

    } 
  
    componentDidMount() {  

    };
  
    onChange_LossPotential = (e) => {
      if (this.state.LossPotential != e.target.value){
        this.setState({LossPotential: e.target.value}, () => {
          this.props.parentCallback(this.state.LossPotential);
        });      
        }
    }

    render(){
      return(
        <div>
          <FormControl style={{ width: '100%' }}>
          <InputLabel id="LossPotentialLabel">最大損失</InputLabel>
          <Select name="LossPotential" id="LossPotential" labelId="LossPotentialLabel" value={this.state.LossPotential} onChange={this.onChange_LossPotential}>
              <option key='0' value=''></option>
              <option key='1' value='Limited'>有限</option>
              <option key='2' value='Unlimited'>無限</option>
              <option key='3' value='Unknown'>未知</option>          
          </Select>
          </FormControl>
        </div>
          )
        };
  }

  export default DropDownList_LossPotential;