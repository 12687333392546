// DropDownList_Leg.js

import React from 'react';
import { useState, useEffect, createContext, useContext } from "react";
import { InputLabel } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

function DropDownList_Leg(props){

  const { 
    LegDefaultValue,
    Leg_Callback
  } = props;

  const [Leg, setLeg] = useState(LegDefaultValue);

  function onChange_Leg(P_Leg){
    setLeg(P_Leg);
    Leg_Callback(P_Leg);
  }

  return (
    <div>
      <FormControl style={{ width: '100%' }}>
      <InputLabel id="LegLabel">多少隻腳？</InputLabel>
        <Select native='true' id="Leg" labelId="LegLabel" value={Leg} onChange={e => onChange_Leg(e.target.value)}>
          <option key='0' value=''></option>
          <option key='1' value='1'>1</option>
          <option key='2' value='2'>2</option>
          <option key='3' value='3'>3</option> 
          <option key='4' value='4'>4</option> 
        </Select>
      </FormControl>
    </div>
      )

}

export default DropDownList_Leg;