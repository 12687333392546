// BaseContract.js

import React from 'react';
import { useState, useEffect, createContext, useContext } from "react";
import ReactDOM from 'react-dom';
import { Button, InputLabel } from '@material-ui/core';
import MaterialTable from "material-table";
import axios from 'axios';
import linq from "linq";

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { sizing } from '@material-ui/system';
import Switch from '@material-ui/core/Switch';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Box from '@material-ui/core/Box';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

function BaseContract(props){

    const { 
        BaseContractDefaultValue,
        BaseContract_Callback,
        ProductCode
    } = props;

function onClick＿ContractRemove(){

    var V_BaseContract = BaseContractDefaultValue;
    if (V_BaseContract == 1){
        V_BaseContract = -1;
    }
    else{
        V_BaseContract = V_BaseContract - 1;
    }        
    BaseContract_Callback(ProductCode, V_BaseContract);

}

function onClick＿ContractAdd(){

    var V_BaseContract = BaseContractDefaultValue;
    if (V_BaseContract == -1){
        V_BaseContract = 1;
    }
    else{
        V_BaseContract = V_BaseContract + 1;
    }     
    BaseContract_Callback(ProductCode, V_BaseContract);

}

    return(  

        <div>
            <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                    <FormControl component="fieldset">
                        <FormLabel component="legend" align="center">{BaseContractDefaultValue}</FormLabel>
                        <br/>
                        <ButtonGroup size="small" aria-label="small outlined button group">
                            <Button onClick={onClick＿ContractRemove}><RemoveIcon /></Button>
                            <Button onClick={onClick＿ContractAdd}><AddIcon /></Button>
                        </ButtonGroup>
                    </FormControl>
                </Box>
            </Grid>
        </div>

    )

}

class BaseContract123 extends React.Component{
  
    constructor(props){

    super(props);

    this.state = {
        // BaseContract: this.props.DefaultBaseContract,
        // ProductCode: '',
      // RefreshDate: '',
    //   RefreshHour: '09',
    //   RefreshMinute: '15'
    };

    // this.onChange_Date = this.onChange_Date.bind(this);
    // this.onChange_Hour = this.onChange_Hour.bind(this);
    // this.onChange_Minute = this.onChange_Minute.bind(this);
    // this.onClick_RefreshAction = this.onClick_RefreshAction.bind(this);

  }

  onClick＿ContractRemove = (e) => {
    let BaseContract = this.props.DefaultBaseContract;
        if (BaseContract == 1){
            BaseContract = -1;
        }
        else{
            BaseContract = BaseContract - 1;
        }        
        this.setState({
            // ProductCode: this.props.ProductCode,
            // BaseContract: BaseContract
        }, () => {
            this.props.BaseContract_Callback(this.props.ProductCode, BaseContract);
        });
}

onClick＿ContractAdd = (e) => {
    let BaseContract = this.props.DefaultBaseContract;
    if (BaseContract == -1){
        BaseContract = 1;
    }
    else{
        BaseContract = BaseContract + 1;
    }     
    this.setState({
        // ProductCode: this.props.ProductCode,
        // BaseContract: BaseContract
    }, () => {
        this.props.BaseContract_Callback(this.props.ProductCode, BaseContract);
    });
}

componentDidMount() {

};

  render(){

    return(  

        <div>
            <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                    <FormControl component="fieldset">
                        <FormLabel component="legend" align="center">{this.props.DefaultBaseContract}</FormLabel><br/>
                        <ButtonGroup size="small" aria-label="small outlined button group">
                            <Button onClick={this.onClick＿ContractRemove}><RemoveIcon /></Button>
                            <Button onClick={this.onClick＿ContractAdd}><AddIcon /></Button>
                        </ButtonGroup>
                    </FormControl>
                </Box>
            </Grid>
        </div>

    )
  }

}

export default BaseContract;