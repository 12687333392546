// ProductBarInfo.js

import React from 'react';
import axios from 'axios';
import { useState, useEffect, createContext, useContext } from "react";

import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { red, green, grey } from '@mui/material/colors';
import Typography from '@mui/material/Typography';

import { 
    LabelPointDollarDateTime,
    LabelPointDollarRHS,
    OptionDetailsListSubmit,
    CallOption2OptionDetailsListItem,
    PutOption2OptionDetailsListItem,
    OptionTableListItem4Call,
    OptionTableListItem4Put,
    // LongFormattedValue, 
    LongFormattedValueNoPoint,
    Dollar2Point,
    RedColor_Set, 
    date2string, 
    UnderlyingProductDiffString_Get 
} from './Common.js';

export default function ProductBarInfo(props) {

    const { 
        Contract,
        ProductType,
        ProductCode,
        CallPut,
        BidAsk,
        RevisionDateTimeHKBidAsk,
        Multiplier,
        Currency,
    } = props;

    const AvatarRedBgColor={backgroundColor: red[200]};
    const AvatarGreenBgColor={backgroundColor: green[200]};
    var AvatarBgColor;
    
    var V_ContractString;
    var V_BidAsk;
    var V_Cost;

    if (Contract > 0){
        V_ContractString = '+' + Contract;
        AvatarBgColor = AvatarGreenBgColor;
        V_Cost = Contract * BidAsk * Multiplier * (-1);
    }
    else{
        V_ContractString = Contract;
        AvatarBgColor = AvatarRedBgColor;
        V_Cost = Contract * BidAsk * Multiplier * (-1);
    }

    var V_Color;

    switch(CallPut){
        case 'Call':
            V_Color = 'success';            
            break;
        case 'Put':
            V_Color = 'error';            
            break;
    }

    return(
        <div>
            <Grid container spacing={0}>
                <Grid item xs={7} lg={7}>
                    <Chip avatar={<Avatar sx={AvatarBgColor}>{V_ContractString}</Avatar>} label={ProductCode} color={V_Color}/>
                </Grid>  
                <Grid item xs={3} lg={3} align='left'> 
                    {LabelPointDollarDateTime(ProductType, BidAsk, true, date2string(RevisionDateTimeHKBidAsk, true, false, false), 'left', Currency)}                               
                </Grid>
                <Grid item xs={2} lg={2} align='right'>                        
                    {/* {RedColor_Set(null, LongFormattedValueNoPoint(V_Cost, 0, true))}                    
                    <Typography variant="caption">{Dollar2Point(V_Cost)}</Typography>   */}
                    {LabelPointDollarRHS(ProductType, V_Cost/Multiplier, Multiplier, true, null, 0, Currency)}
                </Grid> 
            </Grid>
        </div>
    )

}