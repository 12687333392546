// Context.js
import React from 'react';
import axios from 'axios';
import { useState, useEffect, createContext } from "react";

const JediContext = createContext();
const ChartDataDateTimeHKContext = createContext();
const IndexStockListItemContext = createContext();

export {
    JediContext,
    ChartDataDateTimeHKContext,
    IndexStockListItemContext,
};