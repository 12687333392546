// NonClosedTableRow.js

import React from 'react';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DoorFrontIcon from '@mui/icons-material/DoorFront';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import TextField from '@mui/material/TextField';
import ButtonGroup from '@mui/material/ButtonGroup';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { green, pink } from '@mui/material/colors';
import SwipeUpTwoToneIcon from '@mui/icons-material/SwipeUpTwoTone';
import SwipeDownTwoToneIcon from '@mui/icons-material/SwipeDownTwoTone';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Chip from '@mui/material/Chip';
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';

import AuthenticationService from '../AuthenticationService.js';
import BaseContract from './BaseContract.js';
import Accordion_BidAskShow from './Accordion_BidAskShow.js';
import { LabelFigure, jsFileName, LabelPointDollarRHS, LabelPointDollar, LongUnderlyingProductValue, Number2Dollar, FormattedNumber, CheckAuthenticated, ButtonAppBar, StartEnd, StartEnd2, Rounding, LongFormattedValue, SetBidAskValue, Bid, BidDetails, Ask, AskDetails, GroupSummaryCostValueSum, LabelBidAsk, RedColor_Set, date2string, CalcIntrinsicValue, jdate2string4display } from './Common.js';
import { VapingRoomsRounded } from '@mui/icons-material';
import { lightBlue } from '@material-ui/core/colors';

export default function NonClosedTableRow(props){

  const { 
    OptionDetailsListItem, 
    Key, 
    CallerType, 
    OptionList, 
    OptionDetailsListItem_Callback, 
    CostPrice, 
    onChange_CostPrice, 
    CostPriceReset, 
    ValuePrice, 
    onChange_ValuePrice, 
    ValuePriceReset, 
    BaseContract_Callback, 
    GroupNumberListItem, 
    GroupNumberListItem_Callback,
    Multiplier,
  } = props;

  function OptionDetailsListItem_DBSetInvalid(){

    OptionDetailsListItem.Valid = false;
    OptionDetailsListItem.Updated.Valid = true;
  
    var v_http_post_data = {
      Credential: AuthenticationService.getCurrentUser(),
      OptionDetailsListItemRC: OptionDetailsListItem,
    }
  
    const url = sessionStorage.getItem('SiteURL') + '/api/OptionDetailsListItem/Put/';
  
    axios.post(url, v_http_post_data)
  
    .then(resp => {
      let _return_value = resp.data;
    });
  
  }

  function RemoveOptionDetailsListItem(){

    if (CallerType == 'MyList') OptionDetailsListItem_DBSetInvalid();
    
    let _list = GroupNumberListItem.OptionDetailsList.filter(function(e){ 
      return e.ProductCode != OptionDetailsListItem.ProductCode; 
    });
  
    GroupNumberListItem.OptionDetailsList = _list;
  
    GroupNumberListItem_Callback(GroupNumberListItem);
  
  }

  function OptionDetailsListItem_Buttons(){

    switch(CallerType)
    {
      
      case 'Draft':
  
        return(
            <ButtonGroup variant="outlined">
              <DeleteForeverIcon onClick={() => RemoveOptionDetailsListItem()}/>
            </ButtonGroup>
        )
  
      case 'MyList':
  
        return(
          <DeleteForeverIcon onClick={() => RemoveOptionDetailsListItem()}/>
        )
  
    }
  
  }

  function CostValueGet(){

    switch(OptionDetailsListItem.Status){
  
      case 'Pending':
  
        var V_TotalCostAvgString = LongFormattedValue(OptionDetailsListItem.TotalCostAvg, 0, true);
  
        return(
          <React.Fragment>
            {LabelPointDollarRHS(OptionDetailsListItem.ProductType, OptionDetailsListItem.TotalCostAvg/Multiplier, Multiplier, true, null, null, OptionDetailsListItem.Currency)}
            {/* {RedColor_Set(null, V_TotalCostAvgString)} */}
          </React.Fragment>
        )
  
        break;
  
      case 'Opened':
  
        var V_TotalValueAvgString = LongFormattedValue(OptionDetailsListItem.TotalValueAvg, 0, true);
  
        return(
          <React.Fragment>
            {RedColor_Set(null, V_TotalValueAvgString)}
          </React.Fragment>
        )
  
        break;
  
    }    
  
  }

  function BaseContractGet(){

    switch(OptionDetailsListItem.Status){
  
      case 'Pending':
  
        return(
          <React.Fragment>
            <BaseContract 
              BaseContractDefaultValue={OptionDetailsListItem.RefCostContract} 
              ProductCode={OptionDetailsListItem.ProductCode} 
              BaseContract_Callback={BaseContract_Callback}
            />          
          </React.Fragment>
        )
  
        break;
  
      case 'Opened':
  
        return(
          <React.Fragment>
            <BaseContract 
              BaseContractDefaultValue={OptionDetailsListItem.RefValueContract} 
              ProductCode={OptionDetailsListItem.ProductCode} 
              BaseContract_Callback={BaseContract_Callback}
            />          
          </React.Fragment>
        )
  
        break;
  
    }      
  
  }

  function VolumeGet(){
    let _option_list_item = OptionList.find(x => x.ProductCode === OptionDetailsListItem.ProductCode);
    return(
      <div>
        {LabelFigure('Volume:', _option_list_item.Volume, 0, date2string(_option_list_item.RevisionDateTimeHKVolume, true, false, false), 4, 8)}
      </div>      
    )
    
  }

  switch(OptionDetailsListItem.Status){
  
    case 'Pending':
    case 'Opened':

      return(
        <TableRow sx={{ backgroundColor: "#f5f5f5" }} key={Key}>
        <TableCell>
          {/* {this.Accordion_BidAskShow(this.props.Caller, this.props.OptionList, this.props.OptionDetailsListItem, this.props.OptionDetailsListItem_Callback)} */}
          {jsFileName('NonClosedTableRow')}
          <Accordion_BidAskShow
            CallerType={CallerType}
            OptionList={OptionList}
            OptionDetailsListItem={OptionDetailsListItem}
            OptionDetailsListItem_Callback={OptionDetailsListItem_Callback}
            CostPrice={CostPrice}
            onChange_CostPrice={onChange_CostPrice}
            CostPriceReset={CostPriceReset}
            ValuePrice={ValuePrice}                
            onChange_ValuePrice={onChange_ValuePrice}
            ValuePriceReset={ValuePriceReset}
          />
          <br/>
          <VolumeGet />
        </TableCell>        
        <TableCell align="center" sx={{ paddingLeft: 0, paddingRight: 0 }}>
          {/* {this.BaseContractGet()} */}
          <BaseContractGet />
        </TableCell>
        <TableCell align="right" sx={{ paddingLeft: 0, paddingRight: 1 }}>
          {/* {this.CostValueGet()} */}
          <CostValueGet />
          <br/>
          {/* {this.OptionDetailsListItem_Buttons(this.props.Caller, this.props.GroupNumberListItem, this.props.OptionDetailsListItem, this.props.GroupNumberListItem_Callback)} */}
          <OptionDetailsListItem_Buttons />
        </TableCell>
      </TableRow>
      )

    // case 'Closed':

    //   return(
    //     <React.Fragment>
    //     </React.Fragment>
    //   )      

    default:

      return(
        <React.Fragment>
        </React.Fragment>
      )    

  }

}