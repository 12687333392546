import React from 'react';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';

import AuthenticationService from '../AuthenticationService.js';
import HomeProfit from '../functions/HomeProfit.js';
import UnderlyingProductLineChart from '../functions/UnderlyingProductLineChart.js';
import StrikeReferenceLines_Get from '../functions/StrikeReferenceLines_Get.js';
import ButtonAppBar from '../functions/ButtonAppBar.js';
import { LongUnderlyingProductValue, Number2Dollar, FormattedNumber, CheckAuthenticated, LongExpiry, ShortExpiry, Expiry_Get, date2string, UnderlyingProductValue_Get, getResponse, GroupSummaryCostValueSum, SetBidAskValue, GroupNumberList_Update } from '../functions/Common.js';

class Home extends React.Component {

    constructor(props){
  
      super(props);

      this.state ={
        ChartData: [],
        Domain: [],
        Close: 0,
        Last: 0,
        Next_XIndex: 0,
        UnderlyingProductCode: 'HK.HSI', 
        YMin: 0, 
        YMax: 0,
        StrikeReferenceLines: [],
      };

      this.StrikeReferenceLines_Callback = this.StrikeReferenceLines_Callback.bind(this);

    }

    StrikeReferenceLines_Callback(P_Lines){
        // this.setState({StrikeReferenceLines: P_Lines});
    }

    componentDidMount() {

        // var v_http_post_data1 = {
        //     Credential: AuthenticationService.getCurrentUser(),
        //     ProductType: 'Index',
        //     UnderlyingProductCode: 'HK.HSI',
        //     PriceType: 'Last',
        //     Country: 'HK',
        // }
      
        // const url1 = sessionStorage.getItem('SiteURL') + '/api/PriceHistoryList/Today/';
  
        // axios.post(url1, v_http_post_data1)
  
        // .then(resp => {

        //     let V_Data = resp.data;
        //     let data = [];

        //     for (var i = 0; i < V_Data.length; i++){

        //         var V_Price = V_Data[i].Price.toFixed(0);
        //         var y = parseFloat(V_Price);

        //         let _xy = {};
        //          _xy.x = i;
        //         _xy.y = y;
        //          data.push(_xy);

        //     }

        //     var V_YMin = Math.min(...data.map(c => c.y)) - 200;
        //     var V_YMax = Math.max(...data.map(c => c.y)) + 200;            

        //     this.setState({
        //         ChartData: data,
        //         Domain: [V_YMin, V_YMax],
        //         Next_XIndex: i,  
        //         YMin: V_YMin,
        //         YMax: V_YMax,
        //     }, () => {
        //         StrikeReferenceLines('HK.HSI', V_YMin, V_YMax, this.StrikeReferenceLines_Callback);
        //     });            

        // });

        // //

        // var v_http_post_data2 = {
        //     Credential: AuthenticationService.getCurrentUser(),
        //     UnderlyingProductCode: 'HK.HSI',
        //     TimeStatus: 'Current',
        // }

        // const url2 = sessionStorage.getItem('SiteURL') + 'api/IndexListItem/IndexCode/';

        // axios.post(url2, v_http_post_data2)
    
        // .then(resp => {
    
        //     var V_ListItem = resp.data;

        //     var V_UnderlyingProductCloseString = V_ListItem.Close.toFixed(0);
        //     var V_UnderlyingProductClose = parseFloat(V_UnderlyingProductCloseString);

        //     var V_UnderlyingProductValueString = V_ListItem.Last; // Last value
        //     var V_UnderlyingProductValue = parseFloat(V_UnderlyingProductValueString);

        //     this.setState({
        //         Close: V_UnderlyingProductClose,
        //         Last: V_UnderlyingProductValue,
        //     });        
    
            

        // });

    }

    render(){
   
    return (
       <div>
           <ButtonAppBar />
           <HomeProfit />
           <br/>
           {/* <UnderlyingProductLineChart 
                ChartData={this.state.ChartData} 
                Domain={this.state.Domain} 
                Close={this.state.Close}
                StrikeReferenceLines={this.state.StrikeReferenceLines}
            /> */}
       </div>
    );

    }

}

// const home = () => {
//     return (
//        <div>
//            <ButtonAppBar />
//            <HomeProfit />
//            <UnderlyingProductLineChart />
//        </div>
//     );
// }
 
export default Home;