// ProfitLineChart1.js

import React from 'react';
import axios from 'axios';
import { useState, useEffect, createContext } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';

import AuthenticationService from '../AuthenticationService.js';
import UnderlyingProductLineChart from './UnderlyingProductLineChart.js';
import ButtonAppBar from "./ButtonAppBar.js";
import StrikeReferenceLines_Get from './StrikeReferenceLines_Get.js';
import ProfitLineChart2 from './ProfitLineChart2.js';
import { ChartDataDateTimeHKContext } from '../Context.js';
import { LongUnderlyingProductValue, Number2Dollar, FormattedNumber, CheckAuthenticated, LongExpiry, ShortExpiry, Expiry_Get, date2string, UnderlyingProductValue_Get, getResponse, GroupSummaryCostValueSum, SetBidAskValue } from './Common.js';

function ProfitLineChart1(props){

  const { Profit } = props;

  const [ChartData, setChartData] = useState([]);
  const [Domain, setDomain] = useState([]);
  
  const Domain_Get = (P_ChartData) => {

    var V_D = 1000;
    var V_YMin = Math.min(...P_ChartData.map(c => c.y));
    var V_YMax = Math.max(...P_ChartData.map(c => c.y));     
    
    if (0 < V_YMin){
      V_YMin = 0 - V_D;
    } 
    else{
      V_YMin = V_YMin - V_D;
    }

    if (0 > V_YMax){
      V_YMax = 0 + V_D;
    } 
    else{
      V_YMax = V_YMax + V_D;
    } 

    setDomain([V_YMin, V_YMax]);

  }

  const ProfitList_Get = () => {

    var v_http_post_data = {
      Credential: AuthenticationService.getCurrentUser(),
    }

    const url = sessionStorage.getItem('SiteURL') + '/api/OptionDetailsList/ProfitList/';

    axios.post(url, v_http_post_data)

    .then(resp => {

      var V_Data = resp.data;
      var V_ChartData = [];

      //

      var V_Profit0 = V_Data[0].Profit.toFixed(0);
      var y0 = parseFloat(V_Profit0);

      let _xy = {};
      _xy.x = 0;
      _xy.y = y0;
      V_ChartData.push(_xy);

      //

      for (var _x_index = 1; _x_index < V_Data.length; _x_index ++){

          var V_Profit = V_Data[_x_index].Profit.toFixed(0);
          var y = parseFloat(V_Profit);

          let _xy = {};
          _xy.x = _x_index;
          _xy.y = y + V_ChartData[_x_index - 1].y;
          V_ChartData.push(_xy);

      }

      setChartData(V_ChartData);
      Domain_Get(V_ChartData);

    });     

  }

  useEffect(() => {
    ProfitList_Get();    
  }, []);   

  if (ChartData.length > 0){
    return (
        <div>
          <ProfitLineChart2 
            ChartData={ChartData} 
            Profit={Profit}
            Domain={Domain}
          />
        </div>
    );
  }
  else{
    return(
      <React.Fragment>

      </React.Fragment>
    )
  }

}

export default ProfitLineChart1;