import React from 'react';
import axios from "axios";

class AuthenticationService extends React.Component {

    signin = async (UserName, Password) => {

        var v_user_list_item = {
            UserName: UserName,
            Password: Password,
        };
    
        const url = sessionStorage.getItem('SiteURL') + 'api/UserListItem/Token/Get/';
    
        try {
            const response = await axios.post(url, v_user_list_item);
            return response.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    getCurrentUser() {
        return JSON.parse(sessionStorage.getItem('Credential'));
    }

}

export default new AuthenticationService();