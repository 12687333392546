// UnderlyingProduct2.js

import AuthenticationService from '../AuthenticationService.js';
import { useRoutes, A, navigate } from "hookrouter";
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import axios from 'axios';
import { useState, useEffect, useContext } from "react";
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Chip from '@mui/material/Chip';
import { red } from '@mui/material/colors';
import { green } from '@mui/material/colors';

import UnderlyingProductLineChart from './UnderlyingProductLineChart.js';
import { ChartDataDateTimeHKContext, IndexStockListItemContext } from '../Context.js';
import { LabelPointDollarString, LongUnderlyingProductValue, Number2Dollar, FormattedNumber, CheckAuthenticated, ButtonAppBar, LongExpiry, ShortExpiry, Expiry_Get, date2string, UnderlyingProductValue_Get, getResponse, GroupSummaryCostValueSum, SetBidAskValue, UnderlyingProductDiffString_Get } from './Common.js';

export default function UnderlyingProduct2(props){

  const { 
    ChartData, 
    ProductType, 
    OptionList,
    Currency,
  } = props;

  const IndexStockListItem = useContext(IndexStockListItemContext);

  function StrikeReferenceLines_Get(MinStrike, MaxStrike){

    var V_Lines = [];

    if (MinStrike > 0){

        var distinct_strike_list = [...new Set(OptionList.map(c => c.Strike))];
        distinct_strike_list = distinct_strike_list.sort((a, b) => a - b);
   
        var V_Index_YMin = distinct_strike_list.findLastIndex(c => c == MinStrike);
        var V_Index_YMax = distinct_strike_list.findIndex(c => c == MaxStrike);
    
        var V_ActiveDistinctStrikeList = [];
        for (let i = V_Index_YMin; i < V_Index_YMax + 1; i++) {
            V_ActiveDistinctStrikeList.push(distinct_strike_list[i]);
        }
        
         V_ActiveDistinctStrikeList.map((e, key) => {
            // V_Lines.push(<ReferenceLine y={e} label={{ value: LongUnderlyingProductValue("Index", e), angle: 0, fontSize: 12, position: 'insideTopRight', fill: 'lightgrey'}} stroke="lightgrey"/>);
            V_Lines.push(<ReferenceLine y={e} label={{ value: LabelPointDollarString(ProductType, e, Currency), angle: 0, fontSize: 12, position: 'insideTopRight', fill: 'lightgrey'}} stroke="lightgrey"/>);
        });
        // LabelPointDollarString(ProductType, e)       

    }

    return V_Lines;

  }

  function Domain_Get(P_UnderlyingProductClose){

    var V_MinStrike = 0.0;
    var V_MaxStrike = 0.0;

    var V_UnderlyingProductCloseString = P_UnderlyingProductClose;
    var V_UnderlyingProductClose = parseFloat(V_UnderlyingProductCloseString);

    var V_YMin = Math.min(...ChartData.map(c => c.y));
    var V_YMax = Math.max(...ChartData.map(c => c.y));  

    if (V_UnderlyingProductClose < V_YMin){
      V_YMin = V_UnderlyingProductClose;
    };

    if (V_UnderlyingProductClose > V_YMax){
      V_YMax = V_UnderlyingProductClose;
    };

    var distinct_strike_list = [...new Set(OptionList.map(c => c.Strike))];
    distinct_strike_list = distinct_strike_list.sort((a, b) => a - b);
    
    var V_Index_YMin = distinct_strike_list.findLastIndex(c => c < V_YMin);
    var V_Index_YMax = distinct_strike_list.findIndex(c => c > V_YMax);

    V_MinStrike = distinct_strike_list[V_Index_YMin - 1];
    V_MaxStrike = distinct_strike_list[V_Index_YMax + 1];  

    return [V_MinStrike, V_MaxStrike];

  }

  if (OptionList.length > 0){

    var V_Domain = Domain_Get(IndexStockListItem.Close);

    var V_StrikeReferenceLines = StrikeReferenceLines_Get(V_Domain[0], V_Domain[1]);

    return(
        
      <div>
  
        <UnderlyingProductLineChart
          ChartData={ChartData} 
          Domain={V_Domain} 
          StrikeReferenceLines={V_StrikeReferenceLines}                
          IndexStockListItem={IndexStockListItem}
        />
  
      </div>
  
    )

  }
  else{

    return(
      <React.Fragment>
      </React.Fragment>
    )

  }

}