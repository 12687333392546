// DropDownList_OutlookUnderlying.js

import React from 'react';
import { useState, useEffect, createContext, useContext } from "react";
import { InputLabel } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

function DropDownList_OutlookUnderlying(props){

  const { 
    OutlookUnderlyingDefaultValue,
    OutlookUnderlying_Callback
  } = props;

  const [OutlookUnderlying, setOutlookUnderlying] = useState(OutlookUnderlyingDefaultValue);

  function onChange_OutlookUnderlying(P_OutlookUnderlying){
    setOutlookUnderlying(P_OutlookUnderlying);
    OutlookUnderlying_Callback(P_OutlookUnderlying);
  }

  return(
    <div>
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="OutlookUnderlyingLabel">市況</InputLabel>
        <Select native='true' name="OutlookUnderlying" id="OutlookUnderlying" labelId="OutlookUnderlyingLabel" value={OutlookUnderlying} onChange={e => onChange_OutlookUnderlying(e.target.value)}>
          <option key='0' value=''></option>              
          <option key='1' value='Bearish'>Bearish</option>
          <option key='2' value='Bullish'>Bullish</option>
          <option key='3' value='Neutral - Bearish on IV'>Neutral - Bearish on IV</option>
          <option key='4' value='Neutral - Bullish on IV'>Neutral - Bullish on IV</option>
          <option key='5' value='Arbitrage'>Arbitrage</option>
          <option key='6' value='Unknown'>Unknown</option>
        </Select>
      </FormControl>
    </div>
  )

}

export default DropDownList_OutlookUnderlying;