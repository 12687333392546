// Training.js

import React from 'react';
import { useState, useEffect, createContext, useContext, useCallback } from "react";
import axios from 'axios';
import { useRoutes, A, navigate } from "hookrouter";

import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { InputLabel } from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { Button } from '@material-ui/core';
import InputBase from "@mui/material/InputBase";
import TextField from '@mui/material/TextField';

import ButtonAppBar from "./ButtonAppBar.js";
import AuthenticationService from '../AuthenticationService.js';
import CascadingDropDownList2 from './CascadingDropDownList2.js';
import MaterialTableOptionPlanner from './MaterialTableOptionPlanner.js';
import Historical from './Historical.js';
import CombinationListItem from './CombinationListItem.js';
import { ListOptionDetailsList_Get, WeeklyMonthlyName, LongExpiry, ShortExpiry } from './Common.js';

export default function Training(props){

    const [CombinationID, setCombinationID] = useState('_01_LongCall');
    const [CombinationList, setCombinationList] = useState([]);
    // CombinationListItem
    const [CombinationListItem123, setCombinationListItem123] = useState({});
    const [CombinationOptions, setCombinationOptions] = useState([]);
    const [CombinationDetails, setCombinationDetails] = useState({});
    const [ProductType, setProductType] = useState('');
    const [UnderlyingProductCode, setUnderlyingProductCode] = useState('');
    const [ProductTypeUnderlyingProductCodeExpiryList, setProductTypeUnderlyingProductCodeExpiryList] = useState('');
    const [Expiry, setExpiry] = useState('');
    const [UnderlyingProductCodeOptions, setUnderlyingProductCodeOptions] = useState('');
    const [TimeStatus, setTimeStatus] = useState('Current');
    const [ExpiryOptions, setExpiryOptions] = useState('');
    const [NoExpiry, setNoExpiry] = useState(false);
    const [IndexListOptionEnabled, setIndexListOptionEnabled] = useState('');
    const [StockListOptionEnabled, setStockListOptionEnabled] = useState('');
    const [REACT, setREACT] = useState(true);
    const [GroupNumber, setGroupNumber] = useState(-1);
    const [Multiplier, setMultiplier] = useState(0);
    const [UnderlyingProductCodeList, setUnderlyingProductCodeList] = useState([]);
    const [ExpiryList, setExpiryList] = useState([]);
    const [ListOptionDetailsList, setListOptionDetailsList] = useState([]);
    const [SubmitButtonLabel, setSubmitButtonLabel] = useState('確定');
    const [SubmitButtonColor, setSubmitButtonColor] = useState('primary');
    const [UnderlyingProductLast, setUnderlyingProductLast] = useState(-1);
    const [UnderlyingProductLastTemp, setUnderlyingProductLastTemp] = useState(-1);
    const [BaseContract, setBaseContract] = useState(1);
    const [Position, setPosition] = useState('Center');
    const [HistoricalDateListOptions, setHistoricalDateListOptions] = useState([]);

    function Expiry_Get(P_UnderlyingProductCode){

        var v_http_post_data = {
            Credential: AuthenticationService.getCurrentUser(),
            ProductType: ProductType,
            UnderlyingProductCode: P_UnderlyingProductCode,
            TimeStatus: TimeStatus,            
        }
    
        const url = sessionStorage.getItem('SiteURL') + 'api/OptionList/ByUnderlyingProductCode/';
    
        axios.post(url, v_http_post_data)
    
        .then(resp => {
    
            const _option_list = resp.data;
    
            let _expiry_weekly_monthly_list = [];
    
            _option_list.forEach((e) => {
                let _wm = WeeklyMonthlyName(e.WeeklyMonthly);
                let _long_expiry = LongExpiry(e.Expiry);
                var _ewm = _long_expiry + ' (' + e.WeekDay + ') ' + _wm;
                _expiry_weekly_monthly_list.push(_ewm);
            });
            const _unique_expiry_list = [...new Set(_expiry_weekly_monthly_list.map(_list_item => _list_item))];
    
            const ExpiryOptions = _unique_expiry_list.map((e, key) => {
                let _long_expiry = e.substr(0,10); // e.g. 2022-06-17
                var _expiry_value = ShortExpiry(_long_expiry); // e.g. 20220617
                return <option key={key} value={_expiry_value}>{e}</option>;
            });
    
            setExpiryOptions(ExpiryOptions);        
    
        })
    }  

    function IndexListOptionEnabled_Get(){
  
        var v_http_post_data = {
            Credential: AuthenticationService.getCurrentUser(),
            TimeStatus: TimeStatus,            
        }
      
        const url = sessionStorage.getItem('SiteURL') + 'api/IndexList/OptionEnabled/';
      
        axios.post(url, v_http_post_data)
      
            .then(resp => {
      
                const _index_list_option_enabled = resp.data;
      
                const _underlying_product_code_options = _index_list_option_enabled.map((e, key) => {
                    return <option key={key} value={e.IndexCode}>{e.IndexCode}</option>;
                });
      
                var V_UnderlyingProductCodeDefaultValue = _index_list_option_enabled[0].IndexCode;
                
                setIndexListOptionEnabled(_index_list_option_enabled);
                setUnderlyingProductCodeOptions(_underlying_product_code_options); 
                setUnderlyingProductCode(V_UnderlyingProductCodeDefaultValue);
                Expiry_Get(V_UnderlyingProductCodeDefaultValue); // also update Expiry even if the UnderlyingProductCode is not changed after TimeStatus is from Current to Historical
      
        })
      
      }
    
      function StockListOptionEnabled_Get(){
      
        var v_http_post_data = {
            Credential: AuthenticationService.getCurrentUser(),
            TimeStatus: TimeStatus,            
        }
      
        const url = sessionStorage.getItem('SiteURL') + 'api/StockList/OptionEnabled/';
      
        axios.post(url, v_http_post_data)
      
            .then(resp => {
      
                const _stock_list_option_enabled = resp.data;
      
                const _underlying_product_code_options = _stock_list_option_enabled.map((e, key) => {
                    return <option key={key} value={e.StockCode}>{e.StockCode}</option>;
                });
      
                var V_UnderlyingProductCodeDefaultValue = _stock_list_option_enabled[0].StockCode;
      
                setStockListOptionEnabled(_stock_list_option_enabled);
                setUnderlyingProductCodeOptions(_underlying_product_code_options);
                setUnderlyingProductCode(V_UnderlyingProductCodeDefaultValue);
                Expiry_Get(V_UnderlyingProductCodeDefaultValue); // also update Expiry even if the UnderlyingProductCode is not changed after TimeStatus is from Current to Historical
      
        })
      
      }

    function OptionList_Historical(P_RefreshDate, P_RefreshHour, P_RefreshMinute){
  
        var v_http_post_data = {
          Credential: AuthenticationService.getCurrentUser(),
          HistoricalDateTimeString: P_RefreshDate + P_RefreshHour + P_RefreshMinute,  
          ProductType: ProductType,
        }
      
        const url = sessionStorage.getItem('SiteURL') + 'api/OptionList/Historical/';
      
        axios.post(url, v_http_post_data)
      
          .then(resp => {
      
            let _return_status = resp.data;
      
            if (_return_status.ReturnCode == 1){
      
                  switch(ProductType){
                    case 'IndexOption':
                      IndexListOptionEnabled_Get();
                      break;
                    case 'StockOption':
                      StockListOptionEnabled_Get();
                      break;
                  }   
      
            }
      
        });
      
    }

    function RefreshAction(P_RefreshDate, P_RefreshHour, P_RefreshMinute){
  
        var v_http_post_data = {
          Credential: AuthenticationService.getCurrentUser(),
          HistoricalDateTimeString: P_RefreshDate + P_RefreshHour + P_RefreshMinute,         
        }
      
        let _debug = ProductType;
        const url = sessionStorage.getItem('SiteURL') + 'api/OptionList/IndexListStockList/Historical/';
      
        axios.post(url, v_http_post_data)
      
          .then(resp => {
      
            let _return_status = resp.data;
      
            if (_return_status.ReturnCode == 1){
                  OptionList_Historical(P_RefreshDate, P_RefreshHour, P_RefreshMinute);
                }
              });
      
      }  

    function onChange_TimeStatus(P_TimeStatus){
  
        switch(P_TimeStatus){
      
        case 'Historical': // Update the dropdownlist "Date" of historical data. Set state of DateList, RefreshDate and TimeStatus
      
          var _http_post_data = {
            Credential: AuthenticationService.getCurrentUser(),
            ProductType: ProductType,
            DayTotal: 90
          }
      
          const _url = sessionStorage.getItem('SiteURL') + 'api/PriceHistoryLogList/GetDate/';
      
          axios.post(_url, _http_post_data)
      
          .then(resp => {
      
            let _historical_date_list = resp.data;
      
            const _historical_date_list_options = _historical_date_list.map((e, key) => {
              let v = e.replace(/-/g,'');
              v = v.substr(0,8);
              return <option key={key} value={v}>{e}</option>;       
            });
      
            setHistoricalDateListOptions(_historical_date_list_options);        
            setTimeStatus(P_TimeStatus);
    
          });
      
          break;
      
      case 'Current': // Set state of TimeStatus
    
        //   setTimeStatus(P_TimeStatus);
        //   TimeStatus_Callback(P_TimeStatus);
      
        //   switch(ProductType){
        //     case 'IndexOption':
        //       IndexListOptionEnabled_Get();
        //       break;
        //     case 'StockOption':
        //       StockListOptionEnabled_Get();
        //       break;
        //   }
      
          break;
      
        }    
      
      }

    // function CombinationDetails_Get(P_OptionDetailsList) {
    
    //     var v_http_post_data = {
    //         Credential: AuthenticationService.getCurrentUser(),
    //         CombinationID: CombinationID,
    //         GroupNumber: GroupNumber,
    //         ProductType: ProductType,
    //         UnderlyingProductCode: UnderlyingProductCode,
    //         UnderlyingProductValue: UnderlyingProductValue,
    //         Expiry: Expiry,
    //         TimeStatus: TimeStatus,
    //         REACT: REACT,
    //         OptionDetailsList: P_OptionDetailsList,
    //     }

    //     const url = sessionStorage.getItem('SiteURL') + 'api/OptionDetailsList/CombinationDetails/';
    
    //     axios.post(url, v_http_post_data)

    //     .then(resp => {

    //         let _CombinationDetails = resp.data;
    //         setCombinationDetails(_CombinationDetails)     

    //     });

    // }

    useEffect(() => {    
        CombinationListItem_Get();
    }, [CombinationID]);

    function onChange_CombinationID(P_CombinationID){
        setCombinationID(P_CombinationID);        
    }

    function CombinationListItem_Get(){

        var v_http_post_data = {
            Credential: AuthenticationService.getCurrentUser(),
            CombinationID: CombinationID,
        }
      
        const url = sessionStorage.getItem('SiteURL') + '/api/CombinationListItem/Get/';
      
        axios.post(url, v_http_post_data)

        .then(resp => {

            var V_CombinationListItem = resp.data;

            // var V_CombinationList = [];
            // V_CombinationList.push(V_CombinationListItem);

            setCombinationListItem123(V_CombinationListItem);

        }); 

    }

    function CombinationList_Get(){

        var v_http_post_data = {
            Credential: AuthenticationService.getCurrentUser(),
        }
      
        const url = sessionStorage.getItem('SiteURL') + '/api/CombinationList/All/';
      
        axios.post(url, v_http_post_data)

        .then(resp => {

            var V_CombinationList = resp.data;

            const CombinationOptions = V_CombinationList.map((e, key) => {
                return <option key={key} value={e.ID}>{e.ID}</option>;
            });

            setCombinationOptions(CombinationOptions);

        }); 

    }
    
    function CombinationListItem_Show(){


        
    }

    const Select_Combination = useCallback(() => {
        return(
            <Grid item xs={6}>
                <FormControl style={{ width: '100%' }}>
                    <InputLabel id="CombinationIDLabel">組合</InputLabel>
                    <Select native='true' name="CombinationID" id="Combination" labelId="CombinationIDLabel" onChange={e => onChange_CombinationID(e.target.value)}>
                        {CombinationOptions}
                    </Select>
                </FormControl>
            </Grid>
        )
    }, [CombinationOptions]);

    function ProductTypeUnderlyingProductCodeExpiryList_Get(){

        var v_http_post_data = {
          Credential: AuthenticationService.getCurrentUser(),
          TimeStatus: TimeStatus,
        }
    
        const url = sessionStorage.getItem('SiteURL') + '/api/OptionList/ProductTypeUnderlyingProductCodeExpiry/';
    
        axios.post(url, v_http_post_data)
      
        .then(resp => {  
            var V_ProductTypeUnderlyingProductCodeExpiryList = resp.data;
            setProductTypeUnderlyingProductCodeExpiryList(V_ProductTypeUnderlyingProductCodeExpiryList);
        });
    
    }

    function onChange_UnderlyingProductLastTemp(P_UnderlyingProductLast){
        setUnderlyingProductLastTemp(P_UnderlyingProductLast);
    }
    
    function SubmitAction(){

        // setUnderlyingProductLast(UnderlyingProductLastTemp);

        var v_http_post_data = {
            Credential: AuthenticationService.getCurrentUser(),
            UnderlyingProductCode: UnderlyingProductCode,
            TimeStatus: TimeStatus,
          }
      
          let url;
      
          switch(ProductType){
            case 'IndexOption':
              url = sessionStorage.getItem('SiteURL') + 'api/IndexListItem/IndexCode/';
              break;
            case 'StockOption':
              url = sessionStorage.getItem('SiteURL') + 'api/StockListItem/StockCode/';
              break;
            }
          
          axios.post(url, v_http_post_data)
      
              .then(resp => {
                  var V_ListItem = resp.data;
                  var V_UnderlyingProductValue = V_ListItem.Last; // Last value
                  var V_UnderlyingProductValueFloat = parseFloat(V_UnderlyingProductValue);
                  if (UnderlyingProductLastTemp > 0){
                    setUnderlyingProductLast(parseFloat(UnderlyingProductLastTemp));
                  }
                  else{
                    setUnderlyingProductLast(V_UnderlyingProductValueFloat);
                  }                  
                  setMultiplier(V_ListItem.Multiplier);
                  if (V_UnderlyingProductValueFloat > 0) ListOptionDetailsList_Get(ProductType, UnderlyingProductCode, Expiry, BaseContract, TimeStatus, UnderlyingProductLastTemp, setListOptionDetailsList);
              });

    }

    useEffect(() => {  
       
        if (ListOptionDetailsList.length > 0){

            var v_http_post_data = {
                Credential: AuthenticationService.getCurrentUser(),
                CombinationID: CombinationID,
                Position: Position,   
                ListOptionDetailsList: ListOptionDetailsList,
                Last: UnderlyingProductLast,
            }
    
            const url = sessionStorage.getItem('SiteURL') + 'api/OptionDetailsList/OptionStrategyFinder/';
    
            axios.post(url, v_http_post_data)
    
            .then(resp => {
                let _option_details_list = resp.data;

            });

        }  

    }, [ListOptionDetailsList]);

    useEffect(() => {    
        ProductTypeUnderlyingProductCodeExpiryList_Get();
        CombinationList_Get();  
    }, []);

    useEffect(() => {  
        if (ProductTypeUnderlyingProductCodeExpiryList.length > 0){
            setProductType('IndexOption');
        }        
    }, [ProductTypeUnderlyingProductCodeExpiryList]);

    useEffect(() => {    

        if (ProductTypeUnderlyingProductCodeExpiryList.length > 0){

            let L_ProductTypeUnderlyingProductCodeExpiryList = ProductTypeUnderlyingProductCodeExpiryList.filter(function(e){ 
                return e.ProductType == ProductType; 
            });
    
            let L_DistinctUnderlyingProductCodeList = [...new Set(L_ProductTypeUnderlyingProductCodeExpiryList.map(_list_item => _list_item.UnderlyingProductCode))];

            let L_DistinctUnderlyingProductCodeOptions = L_DistinctUnderlyingProductCodeList.map((e, key) => {
                return <option key={key} value={e}>{e}</option>;
            });
    
            setUnderlyingProductCodeList(L_DistinctUnderlyingProductCodeList);
            setUnderlyingProductCodeOptions(L_DistinctUnderlyingProductCodeOptions);
            setUnderlyingProductCode(L_DistinctUnderlyingProductCodeList[0]);

        }

    }, [ProductType]);

    useEffect(() => {    

        if (ProductTypeUnderlyingProductCodeExpiryList.length > 0){
        
            let L_ProductTypeUnderlyingProductCodeExpiryList = ProductTypeUnderlyingProductCodeExpiryList.filter(function(e){ 
                return e.ProductType == ProductType & e.UnderlyingProductCode == UnderlyingProductCode; 
            });
    
            let L_DistinctExpiryList = [...new Set(L_ProductTypeUnderlyingProductCodeExpiryList.map(_list_item => _list_item.Expiry))];

            let L_DistinctExpiryOptions = L_DistinctExpiryList.map((e, key) => {

                let _list_item = ProductTypeUnderlyingProductCodeExpiryList.find(function(e1){ 
                    return e1.ProductType == ProductType & e1.UnderlyingProductCode == UnderlyingProductCode & e1.Expiry == e; 
                });

                return <option key={key} value={e}>{LongExpiry(e)} ({_list_item.WeekDay}) {WeeklyMonthlyName(_list_item.WeeklyMonthly)}</option>;
            });
    
            setExpiryList(L_DistinctExpiryList);
            setExpiryOptions(L_DistinctExpiryOptions);
            setExpiry(L_DistinctExpiryList[0]);

        } 

    }, [UnderlyingProductCode]);

    return (
    
        <div>

            <ButtonAppBar />

            <Box display="flex" justifyContent="center">
                <RadioGroup row defaultValue={TimeStatus} onChange={e => onChange_TimeStatus(e.target.value)}>
                    <FormControlLabel value="Current" control={<Radio />} label="實時數據" />
                    <FormControlLabel value="Historical" control={<Radio />} label="歷史數據" />
                </RadioGroup>
            </Box>

            <Historical 
                RefreshAction={RefreshAction} 
                TimeStatus={TimeStatus} 
                HistoricalDateListOptions={HistoricalDateListOptions}
            />

            <br/>

            <CascadingDropDownList2 
                ProductTypeUnderlyingProductCodeExpiryList={ProductTypeUnderlyingProductCodeExpiryList}
                ProductType={ProductType} 
                ProductType_Callback={setProductType} 
                UnderlyingProductCode={UnderlyingProductCode}
                UnderlyingProductCode_Callback={setUnderlyingProductCode} 
                Expiry={Expiry}
                Expiry_Callback={setExpiry} 
                TimeStatus={TimeStatus} 
                UnderlyingProductCodeOptions={UnderlyingProductCodeOptions} 
                ExpiryOptions={ExpiryOptions} 
                NoExpiry={NoExpiry}
                IndexListOptionEnabled={IndexListOptionEnabled}
                StockListOptionEnabled={StockListOptionEnabled}
            />

            <Select_Combination/>
            <br/>
            <TextField id='UnderlyingProductLast' label='UnderlyingProductLast' defaultValue={UnderlyingProductLast} onChange={e => onChange_UnderlyingProductLastTemp(e.target.value)}/>
            {/* <InputBase value={UnderlyingProductLast} style={{width: '30%'}} sx={{ ml: 1, flex: 1 }} placeholder={'UnderlyingProductLast'} onChange={onChange_ValuePrice}/> */}
            <br/>
            <br/>
            
            <Button variant="contained" color={SubmitButtonColor} fullWidth="true" onClick={SubmitAction}>{SubmitButtonLabel}</Button>

            <br/><br/>

            {/* <MaterialTableOptionPlanner 

                Left={false}
                Center={true}
                Right={false}

                ProductType={ProductType}
                ProductType4UnderlyingProductValue={ProductType}
                UnderlyingProductCode={UnderlyingProductCode}
                UnderlyingProductValue={UnderlyingProductLast}

                Multiplier={Multiplier}
                Expiry={Expiry}
                BaseContract={BaseContract}
                ListOptionDetailsList={ListOptionDetailsList}

                CombinationList={CombinationList}
                CombinationListLength={CombinationList.length}
            />  */}

                <CombinationListItem 
                    CombinationListItem={CombinationListItem123} 
                    ListOptionDetailsList={ListOptionDetailsList} 
                    Left={true} 
                    Center={true} 
                    Right={true} 
                    ProductType={ProductType} 
                    ProductType4UnderlyingProductValue={ProductType} 
                    UnderlyingProductCode={UnderlyingProductCode} 
                    UnderlyingProductValue={UnderlyingProductLast} 
                    Expiry={Expiry} 
                    Multiplier={Multiplier}
                    BaseContract={BaseContract} 
                /> 

                {/* <Button variant="contained" color='primary' fullWidth="true" onClick={SubmitAction}>{'Refresh'}</Button> */}
                <br/><br/><br/>

        </div>    
    
    )



}