import React from "react";
import SignIn from "./SignIn";
import Home from "./components/Home";
import Contact from "./components/Contact";
import About from "./components/About";
import OptionPlanner from './functions/OptionPlanner';
import OptionTable from './OptionTable';
import MyList from './functions/MyList';
import Test from './Test';
import OptionTable2 from './functions/OptionTable2';
import Training from './functions/Training';

const Routes = {
  "/": () => <SignIn />,
  "/home": () => <Home />,
  "/about": () => <About />,
  "/contact": () => <Contact />,
  "/optionplanner": () => <OptionPlanner />,
  "/optiontable2": () => <OptionTable2 />,
  "/mylist": () => <MyList />,
  //
  "/training": () => <Training />,
  "/test": () => <Test />,
};

export default Routes;