// CombinationDetails2

import React from 'react';
import axios from 'axios';
import { useState, useEffect, createContext, useContext } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine, ReferenceDot } from 'recharts';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import { Alert, AlertTitle } from '@material-ui/lab';
import Button from '@mui/material/Button';

import LineChart1 from './LineChart1.js';
import AccordionTooltip from './AccordionTooltip.js';
import AuthenticationService from '../AuthenticationService.js';
// CombinationDetailsReturn.js
import CombinationDetailsReturn from './CombinationDetailsReturn.js';
import { StartEnd, Number2Dollar, LongUnderlyingProductValue, LongExpiry } from './Common.js';

export default function CombinationDetails2(props){

    const { 
        CombinationID,
        Position,
        ListOptionDetailsList, 
        OptionDetailsList,
        GroupNumber,
        ProductType,
        UnderlyingProductCode,
        UnderlyingProductValue,
        // StrikeInterval,
        REACT,
        Expiry,
        Multiplier,
        TimeStatus,
        BaseContract,
    } = props;

    const [CombinationDetails, setCombinationDetails] = useState(null);
    // const [OptionDetailsList, setOptionDetailsList] = useState([]);

    // useEffect(() => {

    //     if (ListOptionDetailsList.length > 0){
    //         if (OptionDetailsList.length > 0) CombinationDetails_Get(OptionDetailsList);
    //     }  
    
    //   }, [ListOptionDetailsList, CombinationID]);   

    // if (ListOptionDetailsList.length > 0){
    //     if (OptionDetailsList.length > 0) CombinationDetails_Get(OptionDetailsList);
    // }

      useEffect(() => {

        if (ListOptionDetailsList.length > 0){
            if (OptionDetailsList.length > 0) CombinationDetails_Get(OptionDetailsList);
        }  
    
      }, [OptionDetailsList]);   

    function CombinationDetails_Get(P_OptionDetailsList) {
    
        var v_http_post_data = {
            Credential: AuthenticationService.getCurrentUser(),
            CombinationID: CombinationID,
            GroupNumber: GroupNumber,
            ProductType: ProductType,
            UnderlyingProductCode: UnderlyingProductCode,
            UnderlyingProductValue: UnderlyingProductValue,
            Expiry: Expiry,
            TimeStatus: 'Historical',
            REACT: REACT,
            OptionDetailsList: P_OptionDetailsList,
        }

        const url = sessionStorage.getItem('SiteURL') + 'api/OptionDetailsList/CombinationDetails/';
    
        axios.post(url, v_http_post_data)

        .then(resp => {

            let _CombinationDetails = resp.data;
            setCombinationDetails(_CombinationDetails)     

        });

    }

    if (CombinationDetails != null){

        return(
            <div>
                <CombinationDetailsReturn 
                    CombinationDetails={CombinationDetails}
                    ProductType={ProductType}
                    Position={Position}
                    // Domain={Domain}
                    CombinationID={CombinationID}
                    UnderlyingProductCode={UnderlyingProductCode}
                    Expiry={Expiry}
                    Multiplier={Multiplier}
                    BaseContract={BaseContract}
                    TimeStatus={TimeStatus}
                />
            </div>
        )

    }
    else{

        return(
            <React.Fragment>
            </React.Fragment>
        )

    }

}
