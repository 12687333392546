// PutOptionDetails.js

import React from 'react';
import { useState, useEffect, createContext, useContext } from "react";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import Box from '@material-ui/core/Box';
import { Alert, AlertTitle } from '@material-ui/lab';

import Tooltip from '@material-ui/core/Tooltip';
import { InputLabel } from '@material-ui/core';
import Chip from '@mui/material/Chip';

import { jsFileName, LabelFigure, LabelPointDollar, LongUnderlyingProductValue, Number2Dollar, FormattedNumber, CheckAuthenticated, ButtonAppBar, StartEnd, StartEnd2, Rounding, date2string, OTM_ATM_ITM } from './Common.js';
import Typography from '@material-ui/core/Typography';
import Button from '@mui/material/Button';

export default function PutOptionDetails(props){

    const { 
      // OptionTableListItem, 
      PutOption,
      PutProductCodeSubmitAction, 
      OptionList 
    } = props;
  
    // let _put_bid = LongUnderlyingProductValue("IndexOption", OptionTableListItem.PutBid);
    // let _put_ask = LongUnderlyingProductValue("IndexOption", OptionTableListItem.PutAsk);
    // let _put_last = LongUnderlyingProductValue("IndexOption", OptionTableListItem.PutLast);
  
    // let _put_close = LongUnderlyingProductValue("IndexOption", OptionTableListItem.PutClose);
    // let _put_open = LongUnderlyingProductValue("IndexOption", OptionTableListItem.PutOpen);
  
    // let _put_volume = LongUnderlyingProductValue("IndexOption", OptionTableListItem.PutVolume);
    // let _put_open_interest = LongUnderlyingProductValue("IndexOption", OptionTableListItem.PutOpenInterest);
    // let _put_previous_open_interest = LongUnderlyingProductValue("IndexOption", OptionTableListItem.PutPreviousOpenInterest);
  
    // let _put_delta = Rounding(OptionTableListItem.PutDelta, 2);
    // let _put_gamma = Rounding(OptionTableListItem.PutGamma, 2);
    // let _put_theta = Rounding(OptionTableListItem.PutTheta, 2);
    // let _put_vega = Rounding(OptionTableListItem.PutVega, 2);
    // let _put_implied_volatility = Rounding(OptionTableListItem.PutImpliedVolatility, 2);
  
    return(
  
      <div>
  
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
              {jsFileName('PutOptionDetails')}
              <br/>
              <Typography>
              認沽期權 ({OTM_ATM_ITM(OptionList, PutOption.ProductCode)})
              </Typography>
              </Grid>
            </Grid>
  
        <br/>
        <Typography variant="caption">
        {LabelPointDollar('賣出', null, PutOption.ProductType, PutOption.Bid, null, false, date2string(PutOption.RevisionDateTimeHKBid, true, false, false), 3, 9, 2, PutOption.Currency)}
        </Typography>
        <Typography variant="caption">
        {LabelPointDollar('買入', null, PutOption.ProductType, PutOption.Ask, null, false, date2string(PutOption.RevisionDateTimeHKAsk, true, false, false), 3, 9, 2, PutOption.Currency)}
        </Typography>
        <Typography variant="caption">
        {LabelPointDollar('最後', null, PutOption.ProductType, PutOption.Last, null, false, date2string(PutOption.RevisionDateTimeHKLast, true, false, false), 3, 9, 2, PutOption.Currency)}
        </Typography>
        <br/>
  
        <Grid container spacing={1}>
          <Grid item xs={6} lg={6}>
          <Typography variant="caption">
            {LabelPointDollar('前收市', null, PutOption.ProductType, PutOption.Close, null, false, null, 6, 6, 2, PutOption.Currency)}
            </Typography>
          </Grid>
          <Grid item xs={6} lg={6}>
          <Typography variant="caption">
            {LabelPointDollar('開市', null, PutOption.ProductType, PutOption.Open, null, false, null, 5, 7, 2, PutOption.Currency)}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={12}>
          <Typography variant="caption">
          {LabelFigure('成交量', PutOption.Volume, 0, date2string(PutOption.RevisionDateTimeHKVolume, true, false, false), 4, 8)}
          </Typography>
          </Grid>
          {/* <Grid item xs={6} lg={6}>
            
          </Grid> */}
          <br/><br/>
          <Grid item xs={12} lg={12}>
          <Typography variant="caption">
            {LabelFigure('未平倉', PutOption.OpenInterest, 0, date2string(PutOption.RevisionDateTimeHKOpenInterest, true, false, false), 4, 8)}
            </Typography>
          </Grid> 
          <Grid item xs={12} lg={12}>
          <Typography variant="caption">
            {LabelFigure('前未平倉', PutOption.PreviousOpenInterest, 0, date2string(PutOption.RevisionDateTimeHKPreviousOpenInterest, true, false, false), 4, 8)}
            </Typography>
          </Grid>                    
        </Grid> 
  
        <br/>
        {date2string(PutOption.RevisionDateTimeHKOptionComputation, false, false, false)}
        <br/>
        <Typography variant="caption">
        {LabelFigure('Delta', PutOption.Delta, 2, null, 3, 9)}
        </Typography>
        <Typography variant="caption">
        {LabelFigure('Theta', PutOption.Theta, 2, null, 3, 9)}
        </Typography>
        <Typography variant="caption">
        {LabelFigure('Vega', PutOption.Vega, 2, null, 3, 9)}
        </Typography>
        <Typography variant="caption">
        {LabelFigure('引伸波幅', PutOption.ImpliedVolatility, 2, null, 8, 4)}
        </Typography>
        <br/>
        {/* <Button variant="contained" color="error" onClick={()=>{PutProductCodeSubmitAction(OptionTableListItem)}}>{OptionTableListItem.PutProductCode}</Button> */}
        <Chip label={PutOption.ProductCode} color="error" onClick={()=>{PutProductCodeSubmitAction(PutOption)}}/>
        <br/><br/>
      </div>
      
    )
  
}