// OptionSummary.js

import React from 'react';
import { useState, useEffect, createContext } from "react";

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import Box from '@material-ui/core/Box';
import { Alert, AlertTitle } from '@material-ui/lab';
import Tooltip from '@material-ui/core/Tooltip';
import { InputLabel } from '@material-ui/core';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import Container from '@material-ui/core/Container';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Switch from '@mui/material/Switch';
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { red, green, grey } from '@mui/material/colors';

import AuthenticationService from '../AuthenticationService.js';
// OptionSummaryListItemDetails
import OptionSummaryListItemDetails from './OptionSummaryListItemDetails.js';
import MaterialTableOptionDetailsList from './MaterialTableOptionDetailsList.js';
import { jsFileName, LabelPointDollar, LongUnderlyingProductValue, Number2Dollar, FormattedNumber, CheckAuthenticated, ButtonAppBar, StartEnd, StartEnd2, Rounding, date2string, LongFormattedValue, RedColor_Set, Profit } from './Common.js';
import Typography from '@material-ui/core/Typography';
import Button from '@mui/material/Button';

// function Profit(P_GroupNumberListItem){

//   if (P_GroupNumberListItem.Active == false){
//     return(
//       <Chip label={RedColor_Set('利潤: ', LongFormattedValue(P_GroupNumberListItem.TotalProfitAvg + P_GroupNumberListItem.TotalIntrinsicValueProfitAvg, 0, true))} size="small" sx={{ paddingLeft: 0, paddingRight: 0 }}/>
//     )
//   }

// }

function GroupNumberListItem_DBSetSelected(P_GroupNumberListItem, P_Selected, P_GroupNumberList_Get){
  
  P_GroupNumberListItem.Selected = P_Selected;
  P_GroupNumberListItem.Updated.Selected = true;

  var v_http_post_data = {
    Credential: AuthenticationService.getCurrentUser(),
    GroupNumberListItemRC: P_GroupNumberListItem,
  }

  const url = sessionStorage.getItem('SiteURL') + '/api/GroupNumberListItem/Put/';

  axios.post(url, v_http_post_data)

  .then(resp => {
    let _return_value = resp.data;
    P_GroupNumberList_Get(true);
  });

}

function GroupNumberListItemRow(props) {
    
    const { 
      OptionList, 
      GroupNumberListItem, 
      GroupNumberList_Get, 
      UnderlyingProductLast, 
      GroupNumberListItem_Callback, 
      GroupNumberListItemDelete_Callback, 
      // GroupNumberListItemDelete_PageUpdate, 
      OptionDetailsListItem_Callback,
      Multiplier,
      Exchange,
      UnderlyingProductCode,
      Currency,
    } = props;
    
    const [open, setOpen] = useState(false);
    const [BackgroundColor, setBackgroundColor] = useState('');

    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    const BackgroundColor_Set = (P_Selected) => {

      switch(P_Selected){
        case true:
          setBackgroundColor('#e3f2fd');
          break;
        case false:
          setBackgroundColor('white');
          break;
      } 

    }

    useEffect(() => {
      BackgroundColor_Set(GroupNumberListItem.Selected);
    });

    const onChange_Selected = (event) => {

      BackgroundColor_Set(event.target.checked);     
      GroupNumberListItem_DBSetSelected(GroupNumberListItem, event.target.checked, GroupNumberList_Get);

    };

    return (

      <React.Fragment>
      <TableRow>
        {/* <TableCell sx={{backgroundColor: "#eeeeee", paddingLeft: 0, paddingRight: 0}}>

        </TableCell> */}
        <TableCell align="left" sx={{ backgroundColor: BackgroundColor, paddingLeft: 1, paddingRight: 1 }} width="25%">

          {/* <Grid item xs={12} lg={12}>
            <br/><Divider color={red[300]}/><br/>
          </Grid>           */}
          <OptionSummaryListItemDetails GroupNumberListItem={GroupNumberListItem} UnderlyingProductLast={UnderlyingProductLast}/>
          {jsFileName('GroupNumberListItemRow')}
          <Grid container spacing={0}>
            <Grid item xs={4} lg={4} align="center">
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
            </Grid>    
            <Grid item xs={4} lg={4} align="center">
              <Switch {...label} checked={GroupNumberListItem.Selected} onChange={onChange_Selected} size="small" />
            </Grid>  
            <Grid item xs={4} lg={4} align="center">
              <DeleteForeverIcon sx={{ color: "#9e9e9e" }}/>
            </Grid>  
          </Grid>

        </TableCell>
        <TableCell align="right" sx={{ paddingLeft: 1, paddingRight: 1, backgroundColor: "#eeeeee" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={12}>
              {LabelPointDollar('開倉值', null, GroupNumberListItem.ProductType, GroupNumberListItem.TotalCostAvg/Multiplier, Multiplier, false, null, 5, 7, 0, Currency)}
            </Grid>              
            <Grid item xs={12} lg={12}>
              {LabelPointDollar('平倉值', null, GroupNumberListItem.ProductType, GroupNumberListItem.TotalValueAvg/Multiplier, Multiplier, false, null, 5, 7, 0, Currency)}
            </Grid>          
            <Grid item xs={12} lg={12}>
              {LabelPointDollar('平倉利潤', null, GroupNumberListItem.ProductType, GroupNumberListItem.TotalProfitAvg/Multiplier, Multiplier, false, null, 6, 6, 0, Currency)}
            </Grid>
            <Grid item xs={12} lg={12}>
              <Divider color={red[300]}/>
            </Grid>
            <Grid item xs={12} lg={12}>
              {LabelPointDollar('內在值', null, GroupNumberListItem.ProductType, GroupNumberListItem.TotalIntrinsicValueAvg/Multiplier, Multiplier, false, null, 5, 7, 0, Currency)}
            </Grid>    
            <Grid item xs={12} lg={12}>
              {LabelPointDollar('結算利潤', null, GroupNumberListItem.ProductType, GroupNumberListItem.TotalIntrinsicValueProfitAvg/Multiplier, Multiplier, false, null, 6, 6, 0, Currency)}
            </Grid>
          </Grid>           
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ paddingLeft: 1, paddingRight: 1, paddingTop: 0, paddingBottom: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <MaterialTableOptionDetailsList 
              OptionList={OptionList}
              UnderlyingProductLast={UnderlyingProductLast}
              GroupNumberListItem={GroupNumberListItem} 
              GroupTitle={GroupNumberListItem.GroupTitle} 
              GroupNumberListItem_Callback={GroupNumberListItem_Callback}
              GroupNumberListItemDelete_Callback={GroupNumberListItemDelete_Callback}
              // GroupNumberListItemDelete_PageUpdate={GroupNumberListItemDelete_PageUpdate}
              OptionDetailsListItem_Callback={OptionDetailsListItem_Callback}
              CallerType='MyList'
              Refresh={GroupNumberList_Get}
              Multiplier={Multiplier}
              Exchange={Exchange}
              UnderlyingProductCode={UnderlyingProductCode}
            />

          </Collapse>
        </TableCell>
      </TableRow>
      </React.Fragment>
      
    )
  }

  export default function OptionSummary(props) {

    const { 
      OptionList, 
      GroupNumberList, 
      GroupNumberList_Get, 
      UnderlyingProductLast, 
      GroupNumberListItem_Callback, 
      GroupNumberListItemDelete_Callback, 
      // GroupNumberListItemDelete_PageUpdate, 
      OptionDetailsListItem_Callback,
      Multiplier,
      Exchange,
      UnderlyingProductCode,
      Currency,
    } = props;

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(2);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    return(

      <div> 

  <Container disableGutters={true} maxWidth="lg">
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableBody>
          {GroupNumberList
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((M_GroupNumberListItem) => (
              <GroupNumberListItemRow 
                OptionList={OptionList} 
                GroupNumberListItem={M_GroupNumberListItem} 
                GroupNumberList_Get={GroupNumberList_Get}
                UnderlyingProductLast={UnderlyingProductLast}
                GroupNumberListItem_Callback={GroupNumberListItem_Callback}
                GroupNumberListItemDelete_Callback={GroupNumberListItemDelete_Callback}
                // GroupNumberListItemDelete_PageUpdate={GroupNumberListItemDelete_PageUpdate}
                OptionDetailsListItem_Callback={OptionDetailsListItem_Callback}
                Multiplier={Multiplier}
                Exchange={Exchange}
                UnderlyingProductCode={UnderlyingProductCode}
                Currency={Currency}
              />
            ))}
        </TableBody>
        <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[1, 2, 5, 10, 25, 100]}
                  // component="div"
                  count={GroupNumberList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
        </TableFooter>  
      </Table>
    </TableContainer>
  </Container>
    </div>         
    )

  }

// export default OptionSummary;