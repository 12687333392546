// Options4Display.js

import { BrowserRouter, Route } from "react-router-dom";
import React from 'react';
import { useState, useEffect, createContext, useContext } from "react";
import ReactDOM from 'react-dom';
import { Button, InputLabel } from '@material-ui/core';
import MaterialTable from "material-table";
//import { DataGrid } from '@material-ui/data-grid';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { sizing } from '@material-ui/system';
import axios from 'axios';
import Container from '@material-ui/core/Container';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FolderIcon from '@material-ui/icons/Folder';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { makeStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Box from '@material-ui/core/Box';

import Tooltip from '@material-ui/core/Tooltip';

import { DataGrid } from '@material-ui/data-grid';
import AuthenticationService from '../AuthenticationService.js';
import { useRoutes, A, navigate } from "hookrouter";

function Options4Display(props){

  const { 
    Left_Callback,
    Center_Callback,
    Right_Callback
  } = props;

  const [Left, setLeft] = useState(false);
  const [Center, setCenter] = useState(true);
  const [Right, setRight] = useState(false);

  function onChange_Left(P_Left){
    setLeft(P_Left);
    Left_Callback(P_Left);
  }

  function onChange_Center(P_Center){
    setCenter(P_Center);
    Center_Callback(P_Center);
  }

  function onChange_Right(P_Right){
    setRight(P_Right);
    Right_Callback(P_Right);
  }

  return (

    <div>

     <Container>

     {/* <Box display="flex" justifyContent="center">
         <FormControlLabel control={<Checkbox name="ShowOutdated" id="ShowOutdated"/>} label="Show Outdated?" />
     </Box> */}

     {/* <br/><br/> */}

     <Box display="flex" justifyContent="center">
         <FormControl component="fieldset">
         <FormLabel component="legend" align="center">相關產品現貨價</FormLabel>
         <FormGroup row>
         <FormControlLabel
           control={<Checkbox defaultChecked={Left} onChange={e => onChange_Left(e.target.checked)} name="Left"/>}
           label="偏右"
         />
         <FormControlLabel
           control={<Checkbox defaultChecked={Center} onChange={e => onChange_Center(e.target.checked)} name="Center" />}
           label="置中"
         />
         <FormControlLabel
           control={<Checkbox defaultChecked={Right} onChange={e => onChange_Right(e.target.checked)} name="Right" />}
           label="偏左"
         />
       </FormGroup>
       </FormControl>
     </Box>

     {/* <br/><br/> */}

   {/* <Box display="flex" justifyContent="center">
     <FormControl component="fieldset">
     <FormLabel component="legend" align="center">Hide when Ratio is less than</FormLabel>
     <RadioGroup aria-label="gender" name="gender1" row>
       <FormControlLabel value="3" control={<Radio />} label="3" />
       <FormControlLabel value="2" control={<Radio />} label="2" />
       <FormControlLabel value="1" control={<Radio />} label="1" />
       <FormControlLabel value="0" control={<Radio />} label="0" />
     </RadioGroup>
     </FormControl>
   </Box> */}

     </Container>

     </div>

       )  

}

export default Options4Display;