// OptionDetailsRow.js

import React from 'react';
import axios from 'axios';
import { useState, useEffect, createContext, useContext } from "react";

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DoorFrontIcon from '@mui/icons-material/DoorFront';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import TextField from '@mui/material/TextField';
import ButtonGroup from '@mui/material/ButtonGroup';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { green, pink, grey } from '@mui/material/colors';
import SwipeUpTwoToneIcon from '@mui/icons-material/SwipeUpTwoTone';
import SwipeDownTwoToneIcon from '@mui/icons-material/SwipeDownTwoTone';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Chip from '@mui/material/Chip';
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Container from '@material-ui/core/Container';

import AuthenticationService from '../AuthenticationService.js';
import NonClosedTableRow from './NonClosedTableRow.js';
import BidAskDisplay from './BidAskDisplay.js';
import { LabelPointDollarString, LabelPointDollarRHS, DollarFormatter, jsFileName, LabelPointDollar, LongUnderlyingProductValue, Number2Dollar, FormattedNumber, CheckAuthenticated, ButtonAppBar, StartEnd, StartEnd2, Rounding, LongFormattedValue, SetBidAskValue, Bid, BidDetails, Ask, AskDetails, GroupSummaryCostValueSum, LabelBidAsk, RedColor_Set, date2string, CalcIntrinsicValue, jdate2string4display } from './Common.js';
import { VapingRoomsRounded } from '@mui/icons-material';
import { lightBlue } from '@material-ui/core/colors';

export default function OptionDetailsRow(props){

  const { 
    OptionDetailsListItem, 
    Key, 
    CallerType, 
    OptionList, 
    UnderlyingProductLast, 
    OptionDetailsListItem_Callback, 
    BaseContract_Callback, 
    GroupNumberListItem, 
    GroupNumberListItem_Callback ,
    Multiplier,
    Exchange,
  } = props;

  const [costPrice, setCostPrice] = React.useState('');
  const [valuePrice, setValuePrice] = React.useState('');
  const [BaseContract, setBaseContract] = React.useState(1);
  const [Triggered, setTriggered] = React.useState(false);
  const [AutoClose, setAutoClose] = React.useState(false);
  const [AutoCloseDisabled, setAutoCloseDisabled] = React.useState(false);
  const [AutoCloseDelta, setAutoCloseDelta] = React.useState(1);
  const [AutoCloseDeltaDisabled, setAutoCloseDeltaDisabled] = React.useState(false);

  function RowSummary_IntrinsicValue_IntrinsicValueProfit_Existed(){

    switch(OptionDetailsListItem.Status){
  
      case 'Pending':
      case 'Opened':
  
        return(
          <React.Fragment>
            <br/><br/>
            {/* <Grid item xs={12} lg={12}> */}
              {LabelPointDollar('內在值', null, OptionDetailsListItem.ProductType, OptionDetailsListItem.TotalIntrinsicValueAvg/Multiplier, Multiplier, true, null, 6, 6, 0, OptionDetailsListItem.Currency)}
              {/* {StartEnd2('內在值:', LongFormattedValue(OptionDetailsListItem.TotalIntrinsicValueAvg, 0, true), 7, 5)} */}
            {/* </Grid>                        */}
            {/* <Grid item xs={12} lg={12}> */}
              {LabelPointDollar('結算利潤', null, OptionDetailsListItem.ProductType, OptionDetailsListItem.TotalIntrinsicValueProfitAvg/Multiplier, Multiplier, true, null, 6, 6, 0, OptionDetailsListItem.Currency)}
              {/* {StartEnd2('結算利潤:', LongFormattedValue(OptionDetailsListItem.TotalIntrinsicValueProfitAvg, 0, true), 7, 5)} */}
            {/* </Grid>               */}
          </React.Fragment>
        )
  
      case 'Closed':        
      case 'Closed2Expired':
  
        return(
          <React.Fragment>
          </React.Fragment>
        )
  
      case 'Opened2Expired':
  
        return(
          <React.Fragment>
            <br/><br/>
            <Grid item xs={12} lg={12}>
              {LabelPointDollar('內在值', null, OptionDetailsListItem.ProductType, OptionDetailsListItem.TotalIntrinsicValueAvg/Multiplier, Multiplier, true, null, 7, 5)}
              {/* {StartEnd2('內在值:', LongFormattedValue(OptionDetailsListItem.TotalIntrinsicValueAvg, 0, true), 7, 5)} */}
            </Grid>                       
            <Grid item xs={12} lg={12}>
              {LabelPointDollar('結算利潤', null, OptionDetailsListItem.ProductType, OptionDetailsListItem.TotalIntrinsicValueProfitAvg/Multiplier, Multiplier, true, null, 7, 5)}
              {/* {StartEnd2('結算利潤:', LongFormattedValue(OptionDetailsListItem.TotalIntrinsicValueProfitAvg, 0, true), 7, 5)} */}
            </Grid>              
          </React.Fragment>
        )
  
    }
  
  }

  function RowSummary_TotalValueAvg_TotalProfitAvg_Existed(){

    switch(OptionDetailsListItem.Status){
  
      case 'Pending':
      case 'Opened':
  
        var V_ValueContract = OptionDetailsListItem.RefValueContract;
        var V_ValuePrice = OptionDetailsListItem.RefValuePrice;
        var V_Currency = OptionDetailsListItem.Currency;
        
        var V_Label_Value = '(' + LabelBidAsk(V_ValueContract) + ': ' + LabelPointDollarString(OptionDetailsListItem.ProductType, V_ValuePrice, V_Currency) + '; Contract: ' + V_ValueContract + ')';
        var V_SummaryTotalValueAvg = OptionDetailsListItem.TotalValueAvg;
  
        var V_Label_Profit = '((' + LongFormattedValue(OptionDetailsListItem.TotalValueAvg, 0, true) + ') + (' + LongFormattedValue(OptionDetailsListItem.TotalCostAvg, 0, true) + '))';
        var V_SummaryTotalProfitAvg = OptionDetailsListItem.TotalProfitAvg;
  
        return(
          <React.Fragment>
            <br/>
            <Grid item xs={12} lg={12}>
              {LabelPointDollar('平倉值', V_Label_Value, OptionDetailsListItem.ProductType, V_SummaryTotalValueAvg/Multiplier, Multiplier, true, null, 6, 6, 0, V_Currency)}
              {/* {StartEnd2('平倉值:', LongFormattedValue(V_SummaryTotalValueAvg, 0, true), 8, 4)} */}
              {/* {V_Label_Value} */}
            </Grid>                     
            <Grid item xs={12} lg={12}>
              {LabelPointDollar('平倉利潤', V_Label_Profit, OptionDetailsListItem.ProductType, V_SummaryTotalProfitAvg/Multiplier, Multiplier, true, null, 8, 4, 0, V_Currency)}
              {/* {StartEnd2('平倉利潤:', LongFormattedValue(V_SummaryTotalProfitAvg, 0, true), 8, 4)} */}
              {/* {V_Label_Profit} */}
            </Grid>              
          </React.Fragment>
        )
  
      case 'Closed':        
      case 'Closed2Expired':
  
        var V_ValueContract = OptionDetailsListItem.ValueContract;
        var V_ValuePrice = OptionDetailsListItem.ValuePrice;
        var V_Currency = OptionDetailsListItem.Currency;
  
        var V_Label_Value = '平倉值 (' + LabelBidAsk(V_ValueContract) + ': ' + V_ValuePrice + '; Contract: ' + V_ValueContract + '):';
        var V_SummaryTotalValueAvg = OptionDetailsListItem.TotalValueAvg;
  
        var V_Label_Profit = '平倉利潤 ((' + LongFormattedValue(OptionDetailsListItem.TotalValueAvg, 0, true) + ') + (' + LongFormattedValue(OptionDetailsListItem.TotalCostAvg, 0, true) + ')):';
        var V_SummaryTotalProfitAvg = OptionDetailsListItem.TotalProfitAvg;
  
        return(
          <React.Fragment>
            <br/><br/>
            <Grid item xs={12} lg={12}>
              {StartEnd2(V_Label_Value, LongFormattedValue(V_SummaryTotalValueAvg, 0, true), 8, 4)}
            </Grid>                     
            <Grid item xs={12} lg={12}>
              {StartEnd2(V_Label_Profit, LongFormattedValue(V_SummaryTotalProfitAvg, 0, true), 8, 4)}
            </Grid>              
          </React.Fragment>
        )
  
      case 'Opened2Expired':
  
        return(
          <React.Fragment>
          </React.Fragment>
        )
  
    }  
  
  }

  function RowSummary_TotalCostAvg_Existed(){

    switch(OptionDetailsListItem.Status){  
      case 'Pending':
  
        var V_CostContract = OptionDetailsListItem.RefCostContract;
        var V_CostPrice = OptionDetailsListItem.RefCostPrice; 
        var V_Currency = OptionDetailsListItem.Currency;
  
        break;
  
      case 'Opened':
      case 'Closed':
      case 'Opened2Expired':
      case 'Closed2Expired':
  
        var V_CostContract = OptionDetailsListItem.CostContract;
        var V_CostPrice = OptionDetailsListItem.CostPrice; 
        var V_Currency = OptionDetailsListItem.Currency;

        break;  
  
    }   
    
    var V_Label = '(' + LabelBidAsk(V_CostContract) + ': ' + LabelPointDollarString(OptionDetailsListItem.ProductType, V_CostPrice, V_Currency) + '; Contract: ' + V_CostContract + ')';
    var V_SummaryTotalCostAvg = OptionDetailsListItem.TotalCostAvg;
  
    return(
      <React.Fragment>
        {LabelPointDollar('開倉值', V_Label, OptionDetailsListItem.ProductType, V_SummaryTotalCostAvg/Multiplier, Multiplier, true, null, 6, 6, 0, V_Currency)}
        {/* {StartEnd2('開倉值:', LongFormattedValue(V_SummaryTotalCostAvg, 0, true), 8, 4)} */}
        {/* {V_Label} */}
      </React.Fragment>
    )  
  
  }

  function ProductCodeDisplay(){

    let _call_put = OptionDetailsListItem.CallPut;
    let _color;
    
    switch(_call_put)
    {
      
      case 'C':
        _color = 'success';
        break;
    
      case 'P':
        _color = 'error';
        break;
    
    }
    
    return(
      <React.Fragment>
        <Chip label={OptionDetailsListItem.ProductCode} color={_color}/>
      </React.Fragment>
    )
    
  }

  function NonClosedGreeks(){

    switch(OptionDetailsListItem.Status){
    
      case 'Pending':
      case 'Opened':
  
        return(
          <React.Fragment>
            <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
              <TableCell sx={{ paddingLeft: 1, paddingRight: 1 }} colSpan={3}>
                <Stack direction="row" spacing={1}>       
                  <Chip label={'Delta: ' + Rounding(OptionDetailsListItem.Delta, 2)} color="primary" variant="outlined" size="small" />
                  <Chip label={'Theta: ' + Rounding(OptionDetailsListItem.Theta, 2)} color="secondary" variant="outlined" size="small" />
                  <Chip label={'Vega: ' + Rounding(OptionDetailsListItem.Vega, 2)} color="warning" variant="outlined" size="small" />
                </Stack>
              </TableCell>
            </TableRow>
          </React.Fragment>
        )
  
      // case 'Closed':
      // case 'Closed2Expired':
  
      //   return(
      //     <React.Fragment>
      //     </React.Fragment>
      //   )

      default:

        return(
          <React.Fragment>
          </React.Fragment>
        )
  
    }
  
  }

  function onChange_CostPrice(e){
    setCostPrice(e.target.value);
  }

  function onChange_ValuePrice(e){
    setValuePrice(e.target.value);
  }

  function CostPriceReset(){
    setCostPrice('');
  }

  function ValuePriceReset(){
    setValuePrice('');
  }

  var V_Color;
  var V_DateTime;
  switch(OptionDetailsListItem.Status){
    case 'Pending':
      V_Color = 'primary';
      V_DateTime = date2string(OptionDetailsListItem.DateTimeHKPending, false, false, true);
      break;
    case 'Opened':
      V_Color = 'secondary';
      V_DateTime = date2string(OptionDetailsListItem.DateTimeHKOpened, false, false, true);
      break;
    case 'Closed':
      V_Color = 'warning';
      V_DateTime = date2string(OptionDetailsListItem.DateTimeHKClosed, false, false, true);
      break;
    case 'Opened2Expired':
      V_Color = 'info';
      V_DateTime = date2string(OptionDetailsListItem.DateTimeHKOpened2Expired, false, false, true);
      break;          
    case 'Closed2Expired':
      V_Color = 'success';
      V_DateTime = date2string(OptionDetailsListItem.DateTimeHKClosed2Expired, false, false, true);
      break;
  }

  function onChange_AutoCloseDelta(P_Value){
    setAutoCloseDelta(parseInt(P_Value));
  }

  function onClick_AutoClose(){
    if (AutoCloseDisabled == false){
      if (AutoClose == false){
        setAutoClose(true);
      }
      else{
        setAutoClose(false);
      } 
    } 
  }

  function onClick_Trigger(){

    if (Triggered == false){

      setTriggered(true);

      let L_OptionListItem = OptionList.find(x => x.ProductCode === OptionDetailsListItem.ProductCode);

      var V_TriggerListItemRC = {

        OptionDetailsListItemID: OptionDetailsListItem.ID,
        ProductType: OptionDetailsListItem.ProductType,
        UnderlyingProductCode: OptionDetailsListItem.UnderlyingProductCode,
        ProductCode: OptionDetailsListItem.ProductCode,        
        // Currency: OptionDetailsListItem.Currency,

        RefCostContract: OptionDetailsListItem.RefCostContract,
        RefCostPrice: OptionDetailsListItem.RefCostPrice,

        // CostContract: 0,

        RefVolume: L_OptionListItem.Volume,
        ConID: L_OptionListItem.ConID,
        Expiry: L_OptionListItem.Expiry,
        ExpiryDate: L_OptionListItem.ExpiryDate,        

        Exchange: Exchange,
        AutoClose: AutoClose,
        AutoCloseDelta: AutoCloseDelta,

        OrderID: 0,
        OrderOpened: false,        
        OrderClosed: false,
        Active: true,

        Valid: true,

      };
  
      var v_http_post_data = {
        Credential: AuthenticationService.getCurrentUser(),
        TriggerListItemRC: V_TriggerListItemRC,          
      }
    
      const url = sessionStorage.getItem('SiteURL') + '/api/TriggerListItem/Post/';
    
      axios.post(url, v_http_post_data)
    
      .then(resp => {
        var V_ReturnValue = resp.data;
      });

    }

    setAutoCloseDisabled(true);
    setAutoCloseDeltaDisabled(true);

  }

  function MyListOnly(){

    switch(CallerType){  

      case 'Draft':
  
        return(
          <React.Fragment>
          </React.Fragment>
        )  
  
        break;
  
      case 'MyList':
  
        return(

          <React.Fragment>

            <div>

              {jsFileName('OptionDetailsRow')}

                <Grid item xs={12} lg={12}>
                  <Chip label={OptionDetailsListItem.ID} variant="outlined" size='small'/>
                </Grid> 

                <br/>

              {/* <Box sx={{ color: 'success' }}> */}

                <Grid container rowSpacing={0} columnSpacing={0}>

                <Chip avatar={<Avatar>T</Avatar>} label={'Trigger'} color={'secondary'}/>

                  <Grid item xs={12} lg={12}>
                    <FormControlLabel control={<Checkbox />} label="AutoClose" checked={AutoClose} disabled={AutoCloseDisabled} onClick={() => onClick_AutoClose()}/>
                  </Grid>

                  <Grid item xs={12} lg={12}>
                    <RadioGroup row defaultValue={AutoCloseDelta} onChange={e => onChange_AutoCloseDelta(e.target.value)}>
                    <FormControlLabel value={0} disabled={AutoCloseDeltaDisabled} control={<Radio />} label='0' />
                      <FormControlLabel value={1} disabled={AutoCloseDeltaDisabled} control={<Radio />} label='1' />
                      <FormControlLabel value={3} disabled={AutoCloseDeltaDisabled} control={<Radio />} label='3' />
                      <FormControlLabel value={5} disabled={AutoCloseDeltaDisabled} control={<Radio />} label='5' />
                      <FormControlLabel value={10} disabled={AutoCloseDeltaDisabled} control={<Radio />} label='10' />
                      {/* <FormControlLabel value={20} disabled={AutoCloseDeltaDisabled} control={<Radio />} label='20' /> */}
                    </RadioGroup>
                  </Grid>

                  <Grid item xs={12} lg={12}>
                    <FormControlLabel control={<Checkbox />} label="Trigger" checked={Triggered} disabled={Triggered} onClick={() => onClick_Trigger()}/>
                  </Grid>

                </Grid> 

              {/* </Box> */}

                <br/>

            </div>
            
          </React.Fragment>
        )  
  
    }   
  
  }

  useEffect(() => {    
    
    var v_http_post_data = {
      Credential: AuthenticationService.getCurrentUser(),
      OptionDetailsListItemID: OptionDetailsListItem.ID,          
    }
  
    const url = sessionStorage.getItem('SiteURL') + '/api/TriggerListItem/Get/';
  
    axios.post(url, v_http_post_data)
  
    .then(resp => {
      var V_TriggerListItem = resp.data;
      if (V_TriggerListItem.ReturnCode == 1){
        setTriggered(true);              
        setAutoCloseDisabled(true);
        setAutoClose(V_TriggerListItem.AutoClose);
        setAutoCloseDeltaDisabled(true);
        setAutoCloseDelta(V_TriggerListItem.AutoCloseDelta);        
      }
      else{
        setTriggered(false);
        setAutoCloseDisabled(false);
        setAutoCloseDeltaDisabled(false);
      }
      
    });

  }, []); // Runs only on the first render

  return(

    <React.Fragment>

      <br/>

            {jsFileName('OptionDetailsRow')}

            <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
              <TableCell align="left" sx={{ paddingLeft: 1, paddingRight: 1 }} colSpan={3}>
                <Stack direction="row" spacing={1}>
                  <ProductCodeDisplay OptionDetailsListItem={OptionDetailsListItem}/>
                  <Chip label={OptionDetailsListItem.Status} color={V_Color} variant="outlined"/>
                  <Chip label={V_DateTime}/>
                </Stack>
              </TableCell>
            </TableRow>

            <NonClosedGreeks />

            <NonClosedTableRow
              OptionDetailsListItem={OptionDetailsListItem}
              Key={Key}
              CallerType={CallerType}
              OptionList={OptionList}
              OptionDetailsListItem_Callback={OptionDetailsListItem_Callback}
              CostPrice={costPrice}
              onChange_CostPrice={onChange_CostPrice}
              CostPriceReset={CostPriceReset}
              ValuePrice={valuePrice}
              onChange_ValuePrice={onChange_ValuePrice}
              ValuePriceReset={ValuePriceReset}
              BaseContract_Callback={BaseContract_Callback}
              GroupNumberListItem={GroupNumberListItem}
              GroupNumberListItem_Callback={GroupNumberListItem_Callback}
              Multiplier={Multiplier}
            />

            <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
              <TableCell align="left" sx={{ paddingLeft: 3, paddingRight: 3 }} colSpan={3}>
                <Grid container spacing={0}>
                  <MyListOnly />
                  {/* <Grid item xs={12} lg={12}>
                    <Chip label={OptionDetailsListItem.ID} variant="outlined" size='small'/>
                  </Grid>  
                  <Grid item xs={12} lg={12} align='center'>
                    <FormControlLabel control={<Checkbox />} label="Trigger" checked={Triggered} disabled={Triggered} onClick={() => onClick_Trigger()}/>
                  </Grid> */}
                  <br/><br/>                    
                  <Grid item xs={12} lg={12}>                        
                    <RowSummary_TotalCostAvg_Existed />
                    <RowSummary_TotalValueAvg_TotalProfitAvg_Existed />
                    <RowSummary_IntrinsicValue_IntrinsicValueProfit_Existed />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>

    </React.Fragment>

  )

}

// export default OptionDetailsRow;