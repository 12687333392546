//

// import React from 'react';
import { useState, useEffect, createContext } from "react";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';

export default function ToggleButtons(props) {

	const { 
		DefaultToggleButton, 
		ToggleButtons_Callback 
	} = props;

	const [currentToggleButton, setCurrentToggleButton] = useState(DefaultToggleButton);

	return (

		<div>
	
		<ToggleButtonGroup
			value={currentToggleButton}
			onChange={(event, newToggleButton) => {
				setCurrentToggleButton(newToggleButton);
				ToggleButtons_Callback(newToggleButton);
			}}
			exclusive
		>

			<ToggleButton value="OI">
            	OI
			</ToggleButton>
			<ToggleButton value="Delta">
            	Delta
			</ToggleButton>
			<ToggleButton value="Theta">
            	Theta
			</ToggleButton>
        	<ToggleButton value="Vega">
            	Vega
			</ToggleButton>
			<ToggleButton value="VOL">
            	VOL
			</ToggleButton>

		</ToggleButtonGroup>

		</div>

	);

}
