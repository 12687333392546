// OptionDetailsListItem_DBSetBaseContract.js

import React from 'react';
import axios from 'axios';

import AuthenticationService from '../AuthenticationService.js';

export default function OptionDetailsListItem_DBSetBaseContract(P_OptionDetailsListItem){

  switch(P_OptionDetailsListItem.Status){

    case 'Pending':

      P_OptionDetailsListItem.Updated.OptionType = true;

      P_OptionDetailsListItem.Updated.RefCostContract = true;
      P_OptionDetailsListItem.Updated.RefCostShare = true;
      // P_OptionDetailsListItem.Updated.RefCostPrice = true;
      P_OptionDetailsListItem.Updated.RefValueContract = true;
      P_OptionDetailsListItem.Updated.RefValueShare = true;

      break;

    case 'Opened':

      P_OptionDetailsListItem.Updated.RefValueContract = true;
      P_OptionDetailsListItem.Updated.RefValueShare = true;
      // P_OptionDetailsListItem.Updated.RefValuePrice = true;

      break;

  }
    
  var v_http_post_data = {
    Credential: AuthenticationService.getCurrentUser(),
    OptionDetailsListItemRC: P_OptionDetailsListItem,
  }
  
  const url = sessionStorage.getItem('SiteURL') + '/api/OptionDetailsListItem/Put/';
  
  axios.post(url, v_http_post_data)
  
  .then(resp => {
    let _return_value = resp.data;
  });
  
}