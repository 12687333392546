// GroupNumberListItem_DBSetTitle.js

import React from 'react';
import axios from 'axios';

import AuthenticationService from '../AuthenticationService.js';

export default function GroupNumberListItem_DBSetTitle(P_GroupNumberListItem){
  
    // P_GroupNumberListItem.GroupTitle = P_GroupTitle;
    // P_GroupNumberListItem.Updated.GroupTitle = true;
  
    var v_http_post_data = {
      Credential: AuthenticationService.getCurrentUser(),
      GroupNumberListItemRC: P_GroupNumberListItem,
    }
  
    const url = sessionStorage.getItem('SiteURL') + '/api/GroupNumberListItem/Put/';
  
    axios.post(url, v_http_post_data)
  
    .then(resp => {
      var _return_value = resp.data;
    });
  
  }