// Filtering.js

import React from 'react';
import { useState, useEffect, createContext, useContext } from "react";
import axios from 'axios';

import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FormLabel from '@material-ui/core/FormLabel';

import Button from '@mui/material/Button';

import CheckBox_RequireUnderlying from './CheckBox_RequireUnderlying.js';
import DropDownList_OutlookUnderlying from './DropDownList_OutlookUnderlying.js';
import DropDownList_ProfitPotential from './DropDownList_ProfitPotential.js';
import DropDownList_LossPotential from './DropDownList_LossPotential.js';
import DropDownList_CreditDebit from './DropDownList_CreditDebit.js';
import DropDownList_Leg from './DropDownList_Leg.js';
import { ThreeSixty } from '@material-ui/icons';

function Filtering(props){

  const { 
    RequireUnderlying_Callback,
    OutlookUnderlying_Callback,
    ProfitPotential_Callback,
    LossPotential_Callback,
    CreditDebit_Callback,
    Leg_Callback
  } = props;

  const [RequireUnderlying, setRequireUnderlying] = useState(false);
  const [OutlookUnderlying, setOutlookUnderlying] = useState('Bearish');
  const [ProfitPotential, setProfitPotential] = useState('Limited');
  const [LossPotential, setLossPotential] = useState('Limited');
  const [CreditDebit, setCreditDebit] = useState('Credit');
  const [Leg, setLeg] = useState('1');

  function onChange_RequireUnderlying(P_RequireUnderlying){
    setRequireUnderlying(P_RequireUnderlying);
    RequireUnderlying_Callback(P_RequireUnderlying);
  }

  function onChange_OutlookUnderlying(P_OutlookUnderlying){
    setOutlookUnderlying(P_OutlookUnderlying);
    OutlookUnderlying_Callback(P_OutlookUnderlying);
  }

  function onChange_ProfitPotential(P_ProfitPotential){
    setProfitPotential(P_ProfitPotential);
    ProfitPotential_Callback(P_ProfitPotential);
  }

  function onChange_LossPotential(P_LossPotential){
    setLossPotential(P_LossPotential);
    LossPotential_Callback(P_LossPotential);
  }

  function onChange_CreditDebit(P_CreditDebit){
    setCreditDebit(P_CreditDebit);
    CreditDebit_Callback(P_CreditDebit);
  }

  function onChange_Leg(P_Leg){
    setLeg(P_Leg);
    Leg_Callback(P_Leg);
  }

  return(

    <div>

    <Box pb={3} pt={3} m={0} width="100%">

      {/* <Grid container spacing={1}>
        <Grid item xs={8} style={{ width: '100%' }}><CheckBox_RequireUnderlying RequireUnderlyingDefaultValue={RequireUnderlying} RequireUnderlying_Callback={onChange_RequireUnderlying}/></Grid>
        <Grid item xs={4} style={{ width: '100%' }}>
        </Grid>
      </Grid> */}

      <Grid container spacing={1}>
        <Grid item xs={12} style={{ width: '100%' }}><DropDownList_OutlookUnderlying OutlookUnderlyingDefaultValue={OutlookUnderlying} OutlookUnderlying_Callback={onChange_OutlookUnderlying}/></Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={6} style={{ width: '100%' }}><DropDownList_ProfitPotential ProfitPotentialDefaultValue={ProfitPotential} ProfitPotential_Callback={onChange_ProfitPotential}/></Grid>
        <Grid item xs={6} style={{ width: '100%' }}><DropDownList_LossPotential LossPotentialDefaultValue={LossPotential} LossPotential_Callback={onChange_LossPotential}/></Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={6} style={{ width: '100%' }}><DropDownList_CreditDebit CreditDebitDefaultValue={CreditDebit} CreditDebit_Callback={onChange_CreditDebit}/></Grid>
        <Grid item xs={6} style={{ width: '100%' }}><DropDownList_Leg LegDefaultValue={Leg} Leg_Callback={onChange_Leg}/></Grid>
      </Grid>

    </Box>

    </div>

  )  

}

export default Filtering;