// CheckBox_RequireUnderlying.js

import React from 'react';
import { useState, useEffect, createContext, useContext } from "react";
import { InputLabel } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

function CheckBox_RequireUnderlying(props){

  const { 
    RequireUnderlyingDefaultValue,
    RequireUnderlying_Callback
  } = props;

  const [RequireUnderlying, setRequireUnderlying] = useState(RequireUnderlyingDefaultValue);

  function onChange_RequireUnderlying(P_RequireUnderlying){
    setRequireUnderlying(P_RequireUnderlying);
    RequireUnderlying_Callback(P_RequireUnderlying);
  }

  return(
    <div>
        <FormControlLabel 
            control={<Checkbox name="RequireUnderlying" id="RequireUnderlying" checked={RequireUnderlying} onChange={e => onChange_RequireUnderlying(e.target.checked)}/>} 
            label="Require Underlying?" 
        />
  </div>            
)

}

export default CheckBox_RequireUnderlying;