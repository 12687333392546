// GroupNumberListItemDelete.js

import React from 'react';
import axios from 'axios';

import AuthenticationService from '../AuthenticationService.js';

export default function GroupNumberListItemDelete(P_GroupNumberListItem, P_GroupNumberListItemDelete_Callback, P_GroupNumberListItemDelete_PageUpdate){

    P_GroupNumberListItem.Valid = false;
    P_GroupNumberListItem.Updated.Valid = true;

    var v_http_post_data = {
      Credential: AuthenticationService.getCurrentUser(),
      GroupNumberListItemRC: P_GroupNumberListItem,
    }

    const url = sessionStorage.getItem('SiteURL') + '/api/GroupNumberListItem/Put/';

    axios.post(url, v_http_post_data)

    .then(resp => {
      let _return_value = resp.data;
      P_GroupNumberListItemDelete_Callback(P_GroupNumberListItem);
      // P_GroupNumberListItemDelete_PageUpdate();
    });

}