// CallOptionDetails.js

import React from 'react';
import { useState, useEffect, createContext, useContext } from "react";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import axios from 'axios';
import Box from '@material-ui/core/Box';
import { Alert, AlertTitle } from '@material-ui/lab';

import Tooltip from '@material-ui/core/Tooltip';
import { InputLabel } from '@material-ui/core';
import Chip from '@mui/material/Chip';

import { jsFileName, LabelFigure, LabelPointDollar, LongUnderlyingProductValue, Number2Dollar, FormattedNumber, CheckAuthenticated, ButtonAppBar, StartEnd, StartEnd2, Rounding, date2string, OTM_ATM_ITM } from './Common.js';
import Typography from '@material-ui/core/Typography';
import Button from '@mui/material/Button';

export default function CallOptionDetails(props){

    const { 
      // OptionTableListItem, 
      CallOption,
      CallProductCodeSubmitAction, 
      OptionList 
    } = props;
  
    return(
  
      <div>
  
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
              {jsFileName('CallOptionDetails')}
              <br/>
              <Typography>
              認購期權 ({OTM_ATM_ITM(OptionList, CallOption.ProductCode)})
              </Typography>
              </Grid>
            </Grid>
  
        <br/>
        <Typography variant="caption">
        {LabelPointDollar('賣出', null, CallOption.ProductType, CallOption.Bid, null, false, date2string(CallOption.RevisionDateTimeHKBid, true, false, false), 3, 9, 2, CallOption.Currency)}
        </Typography>
        {/* {StartEnd2('賣出', CallOption.Bid + ' ' + date2string(CallOption.RevisionDateTimeHKBid, true, false, false), 3, 9)} */}
        <Typography variant="caption">
        {LabelPointDollar('買入', null, CallOption.ProductType, CallOption.Ask, null, false, date2string(CallOption.RevisionDateTimeHKAsk, true, false, false), 3, 9, 2, CallOption.Currency)}
        </Typography>
        <Typography variant="caption">
        {LabelPointDollar('最後', null, CallOption.ProductType, CallOption.Last, null, false, date2string(CallOption.RevisionDateTimeHKLast, true, false, false), 3, 9, 2, CallOption.Currency)}
        </Typography>
        <br/>
  
        <Grid container spacing={1}>
          <Grid item xs={6} lg={6}>
          <Typography variant="caption">
            {LabelPointDollar('前收市', null, CallOption.ProductType, CallOption.Close, null, false, null, 6, 6, 2, CallOption.Currency)}
            </Typography>
            {/* {StartEnd2('前收市', CallOption.Close, 7, 5)} */}
          </Grid>
          <Grid item xs={6} lg={6}>
          <Typography variant="caption">
            {LabelPointDollar('開市', null, CallOption.ProductType, CallOption.Open, null, false, null, 5, 7, 2, CallOption.Currency)}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={12}>
          <Typography variant="caption">
            {LabelFigure('成交量', CallOption.Volume, 0, date2string(CallOption.RevisionDateTimeHKVolume, true, false, false), 4, 8)}
            </Typography>
            {/* {StartEnd2('成交量', CallOption.Volume + ' ' + date2string(CallOption.RevisionDateTimeHKVolume, true, false, false), 4, 8)} */}
          </Grid>
          <br/><br/>
          <Grid item xs={12} lg={12}>
          <Typography variant="caption">
            {LabelFigure('未平倉', CallOption.OpenInterest, 0, date2string(CallOption.RevisionDateTimeHKOpenInterest, true, false, false), 4, 8)}
            </Typography>
          </Grid>  
          <Grid item xs={12} lg={12}>
          <Typography variant="caption">
            {LabelFigure('前未平倉', CallOption.PreviousOpenInterest, 0, date2string(CallOption.RevisionDateTimeHKPreviousOpenInterest, true, false, false), 4, 8)}
            </Typography>
          </Grid>        
        </Grid> 
  
        <br/>
        {date2string(CallOption.RevisionDateTimeHKOptionComputation, false, false, false)}
        <br/>
        <Typography variant="caption">
        {LabelFigure('Delta', CallOption.Delta, 2, null, 3, 9)}
        </Typography>
        <Typography variant="caption">
        {LabelFigure('Theta', CallOption.Theta, 2, null, 3, 9)}
        </Typography>
        <Typography variant="caption">
        {LabelFigure('Vega', CallOption.Vega, 2, null, 3, 9)}
        </Typography>
        <Typography variant="caption">
        {LabelFigure('引伸波幅', CallOption.ImpliedVolatility, 2, null, 8, 4)}
        </Typography>
        <br/>
        <Chip label={CallOption.ProductCode} color="success" onClick={()=>{CallProductCodeSubmitAction(CallOption)}}/>
        <br/><br/>
      </div>
  
    )
  
}