// Accordion_BidAskShow.js

import React from 'react';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DoorFrontIcon from '@mui/icons-material/DoorFront';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import TextField from '@mui/material/TextField';
import ButtonGroup from '@mui/material/ButtonGroup';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { green, pink } from '@mui/material/colors';
import SwipeUpTwoToneIcon from '@mui/icons-material/SwipeUpTwoTone';
import SwipeDownTwoToneIcon from '@mui/icons-material/SwipeDownTwoTone';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Chip from '@mui/material/Chip';
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';

import AuthenticationService from '../AuthenticationService.js';
import BidAskDisplay from './BidAskDisplay.js';
import { LongUnderlyingProductValue, Number2Dollar, FormattedNumber, CheckAuthenticated, ButtonAppBar, StartEnd, StartEnd2, Rounding, LongFormattedValue, SetBidAskValue, Bid, BidDetails, Ask, AskDetails, GroupSummaryCostValueSum, LabelBidAsk, RedColor_Set, date2string, CalcIntrinsicValue, jdate2string4display } from './Common.js';
import { VapingRoomsRounded } from '@mui/icons-material';
import { lightBlue } from '@material-ui/core/colors';

export default function Accordion_BidAskShow(props){

  const { 
    CallerType, 
    OptionList, 
    OptionDetailsListItem, 
    OptionDetailsListItem_Callback, 
    CostPrice, 
    onChange_CostPrice, 
    CostPriceReset, 
    ValuePrice, 
    onChange_ValuePrice, 
    ValuePriceReset 
  } = props;

  function OptionDetailsListItem_DBSetClosed(){

    let V_OptionDetailsListItem = OptionDetailsListItem;
    
    V_OptionDetailsListItem.ValueContract = V_OptionDetailsListItem.RefValueContract;
    V_OptionDetailsListItem.ValueShare = V_OptionDetailsListItem.RefValueShare;
    V_OptionDetailsListItem.ValuePrice = ValuePrice;
  
    V_OptionDetailsListItem.TotalValueAvg = V_OptionDetailsListItem.ValueShare * V_OptionDetailsListItem.ValuePrice * (-1);
    V_OptionDetailsListItem.TotalProfitAvg = V_OptionDetailsListItem.TotalValueAvg + V_OptionDetailsListItem.TotalCostAvg;
    
    V_OptionDetailsListItem.TotalIntrinsicValueAvg = 0;
    V_OptionDetailsListItem.TotalIntrinsicValueProfitAvg = 0;
  
    V_OptionDetailsListItem.Status = 'Closed';
  
    //
  
    V_OptionDetailsListItem.Updated.ValueContract = true;
    V_OptionDetailsListItem.Updated.ValueShare = true;
    V_OptionDetailsListItem.Updated.ValuePrice = true;
    
    V_OptionDetailsListItem.Updated.TotalValueAvg = true;
    V_OptionDetailsListItem.Updated.TotalProfitAvg = true;
  
    V_OptionDetailsListItem.Updated.TotalIntrinsicValueAvg = true;
    V_OptionDetailsListItem.Updated.TotalIntrinsicValueProfitAvg = true;
  
    V_OptionDetailsListItem.Updated.Status = true;
  
    //
  
    var V_DateTime = new Date();
    V_OptionDetailsListItem.DateTimeHKClosed = jdate2string4display(V_DateTime);
    
    //
  
    var v_http_post_data = {
      Credential: AuthenticationService.getCurrentUser(),
      OptionDetailsListItemRC: V_OptionDetailsListItem,
    }
  
    const url = sessionStorage.getItem('SiteURL') + '/api/OptionDetailsListItem/Put/';
  
    axios.post(url, v_http_post_data)
  
    .then(resp => {
      let _return_value = resp.data;
      ValuePriceReset('');
      OptionDetailsListItem_Callback(V_OptionDetailsListItem);
      // this.setState({ValuePrice: ''}, () => {
      //   P_OptionDetailsListItem_Callback(V_OptionDetailsListItem);
      // })        
    });
  
  }

  function OptionDetailsListItem_DBSetOpened(){ // When changing Status from 'Pending' to 'Opened', only RefCostPrice, CostContract, CostShare, CostPrice, TotalCostAvg, RefValueContract and RefValueShare are updated because RefValuePrice may be changed many times within 1 second

    let V_OptionDetailsListItem = OptionDetailsListItem;
    
    V_OptionDetailsListItem.CostContract = V_OptionDetailsListItem.RefCostContract;
    V_OptionDetailsListItem.CostShare = V_OptionDetailsListItem.RefCostShare;
    V_OptionDetailsListItem.CostPrice = CostPrice;
  
    V_OptionDetailsListItem.TotalCostAvg = V_OptionDetailsListItem.CostShare * V_OptionDetailsListItem.CostPrice * (-1);
  
    V_OptionDetailsListItem.RefValueContract = V_OptionDetailsListItem.CostContract * (-1);
    V_OptionDetailsListItem.RefValueShare = V_OptionDetailsListItem.CostShare * (-1);
    if (V_OptionDetailsListItem.RefValueContract > 0){
      V_OptionDetailsListItem.RefValuePrice = Ask(OptionList, V_OptionDetailsListItem);
    }
    else{
      V_OptionDetailsListItem.RefValuePrice = Bid(OptionList, V_OptionDetailsListItem);
    }
  
    V_OptionDetailsListItem.Status = 'Opened';
  
    V_OptionDetailsListItem.Updated.CostContract = true;
    V_OptionDetailsListItem.Updated.CostShare = true;
    V_OptionDetailsListItem.Updated.CostPrice = true;
    
    V_OptionDetailsListItem.Updated.TotalCostAvg = true;
  
    V_OptionDetailsListItem.Updated.RefValueContract = true;
    V_OptionDetailsListItem.Updated.RefValueShare = true;
  
    V_OptionDetailsListItem.Updated.Status = true;
    
    //
  
    var V_DateTime = new Date();
    V_OptionDetailsListItem.DateTimeHKOpened = jdate2string4display(V_DateTime);
  
    //
  
    var v_http_post_data = {
      Credential: AuthenticationService.getCurrentUser(),
      OptionDetailsListItemRC: V_OptionDetailsListItem,
    }
  
    const url = sessionStorage.getItem('SiteURL') + '/api/OptionDetailsListItem/Put/';
  
    axios.post(url, v_http_post_data)
  
    .then(resp => {
      let _return_value = resp.data;
      CostPriceReset();
      OptionDetailsListItem_Callback(V_OptionDetailsListItem);
      // this.setState({CostPrice: ''}, () => {
      //   P_OptionDetailsListItem_Callback(V_OptionDetailsListItem);
      // })        
    });
  
  }

  switch(CallerType)
  {
    
    case 'Draft':

      return(
        <div>
                    <Grid container spacing={0}>
                      <Grid item xs={12} lg={12} align='left'>
                        <BidAskDisplay OptionList={OptionList} OptionDetailsListItem={OptionDetailsListItem}/>
                      </Grid>
                    </Grid>                
        </div>
      )
      break;

    case 'MyList':

      var V_PlaceHolder;

      switch(OptionDetailsListItem.Status){

        case 'Pending':                

          if (OptionDetailsListItem.RefCostContract < 0){
            V_PlaceHolder='Bid';
          }
          else{
            V_PlaceHolder='Ask';
          }

          return(
                <Accordion>
                  <AccordionSummary sx={{ justifyContent: 'flex-end' }}>             
                    <Grid container spacing={0}>
                      <Grid item xs={8} lg={8} align='left'>
                        <BidAskDisplay OptionList={OptionList} OptionDetailsListItem={OptionDetailsListItem}/>
                      </Grid>
                      <Grid item xs={2} lg={2} align='right'>
                      </Grid>                              
                      <Grid item xs={2} lg={2} align='right'>
                        <SwipeUpTwoToneIcon fontSize='small' />
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={1}>
                      <Grid item xs={12} lg={12} align='center'>
                        <InputBase value={CostPrice} style ={{width: '30%'}} sx={{ ml: 1, flex: 1 }} placeholder={V_PlaceHolder} onChange={onChange_CostPrice}/>
                        <IconButton color="primary" sx={{ p: "10px" }} aria-label="directions">
                          <CheckBoxIcon onClick={() => OptionDetailsListItem_DBSetOpened()}/>
                        </IconButton>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>        
          )

        break;

        case 'Opened':

          if (OptionDetailsListItem.RefValueContract < 0){
            V_PlaceHolder='Bid';
          }
          else{
            V_PlaceHolder='Ask';
          }

          // var V = this.props.UnderlyingProductLast;

          return(
                <Accordion>
                  <AccordionSummary sx={{ justifyContent: 'flex-end' }}>             
                    <Grid container spacing={0}>
                      <Grid item xs={8} lg={8} align='left'>
                        <BidAskDisplay OptionList={OptionList} OptionDetailsListItem={OptionDetailsListItem}/>
                      </Grid>
                      <Grid item xs={2} lg={2} align='right'>
                      </Grid>                            
                      <Grid item xs={2} lg={2} align='right'>
                        <SwipeDownTwoToneIcon fontSize='small'/>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={1}>
                      <Grid item xs={12} lg={12} align='center'>
                        <InputBase value={ValuePrice} style={{width: '30%'}} sx={{ ml: 1, flex: 1 }} placeholder={V_PlaceHolder} onChange={onChange_ValuePrice}/>
                        <IconButton color="primary" sx={{ p: "10px" }} aria-label="directions">
                          <CheckBoxIcon onClick={() => OptionDetailsListItem_DBSetClosed()}/>
                        </IconButton>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>        
          )

        break;

      }

      break;

    default:

      return(
        <React.Fragment>
        </React.Fragment>
      )

  }

}