// UnderlyingProduct1.js

import React from 'react';
import axios from 'axios';
import { useState, useEffect, createContext } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import { w3cwebsocket as W3CWebSocket } from 'websocket';

import Box from '@mui/material/Box';

import AuthenticationService from '../AuthenticationService.js';
import UnderlyingProductLineChart from './UnderlyingProductLineChart.js';
import ButtonAppBar from "./ButtonAppBar.js";
import StrikeReferenceLines_Get from './StrikeReferenceLines_Get.js';
import UnderlyingProduct2 from './UnderlyingProduct2.js';
import { ChartDataDateTimeHKContext } from '../Context.js';
import { LongUnderlyingProductValue, Number2Dollar, FormattedNumber, CheckAuthenticated, LongExpiry, ShortExpiry, Expiry_Get, date2string, UnderlyingProductValue_Get, getResponse, GroupSummaryCostValueSum, SetBidAskValue, OnMessageIndex } from './Common.js';

export default function UnderlyingProduct1(props){

  const { 
    // Auto,
    ProductType, 
    UnderlyingProductCode,
    // Country, 
    TimeStatus, 
    OptionList,
    ChartData,
    ChartDataDateTimeHK,
    Currency,
  } = props;

  // const [ChartData, setChartData] = useState([]);
  // const [ChartDataDateTimeHK, setChartDataDateTimeHK] = useState('');
  const [StrikeReferenceLines, setStrikeReferenceLines] = useState([]);
  
  // const [IndexStockListItem, setIndexStockListItem] = useState({});
  // const [WebSocketConsoleMessage, setWebSocketConsoleMessage] = useState('');
  // const [WebSocketConsoleDateTime, setWebSocketConsoleDateTime] = useState('');

  // const PriceHistoryListToday_Get = () => {

  //   var v_http_post_data = {
  //     Credential: AuthenticationService.getCurrentUser(),
  //     ProductType: ProductType,
  //     UnderlyingProductCode: UnderlyingProductCode,
  //     PriceType: 'Last',
  //     // Country: 'HK',
  //   }

  //   const url = sessionStorage.getItem('SiteURL') + '/api/PriceHistoryList/Today/';

  //   axios.post(url, v_http_post_data)

  //   .then(resp => {

  //     var V_ConsoleDateTime = new Date().toLocaleTimeString('en-US', { hour12: false, hour: "numeric", minute: "numeric", second: "numeric"});
  //     var V_ConsoleMessage = 'PriceHistoryList/Today/' + ' (' + V_ConsoleDateTime +')';
  //     console.log(V_ConsoleMessage);

  //     var V_Data = resp.data;
  //     var V_ChartData = [];

  //     for (var _x_index = 0; _x_index < V_Data.length; _x_index++){

  //         var V_Price;
  //         switch(ProductType){
  //             case 'Index':
  //                 V_Price = V_Data[_x_index].Price.toFixed(0);
  //                 break;  
  //             case 'Stock':
  //                 V_Price = V_Data[_x_index].Price.toFixed(2);
  //                 break;    
  //         }            

  //         var y = parseFloat(V_Price);

  //         let _xy = {};
  //         _xy.x = _x_index;
  //         _xy.y = y;
  //         V_ChartData.push(_xy);

  //     }

  //     setChartData(V_ChartData);

  //     if (V_Data.length > 0){
  //       var V_ChartDataDateTimeHK = V_Data[V_Data.length-1].CreationDateTimeHK;
  //       setChartDataDateTimeHK(V_ChartDataDateTimeHK);
  //     }

  //   });     

  // }

  useEffect(() => {    
    // PriceHistoryListToday_Get();
  }, []);

  // useEffect(() => {
    
  //   PriceHistoryListToday_Get();
  //   const interval = setInterval(() => {
  //     PriceHistoryListToday_Get();        
  //   }, 60000);

  //   return () => clearInterval(interval);

  // }, []);

  return (
    <ChartDataDateTimeHKContext.Provider value={ChartDataDateTimeHK}>
      <div>
        {/* <ButtonAppBar /> */}
        {/* <br/> */}
        <UnderlyingProduct2 
          ChartData={ChartData}           
          ProductType={ProductType}
          OptionList={OptionList}
          Currency={Currency}
          // IndexStockListItem={IndexStockListItem} 
        />
        <br/>
        <Box display="flex" justifyContent="flex-end">
          {/* {WebSocketConsoleMessage} {WebSocketConsoleDateTime} */}
        </Box>
      </div>
    </ChartDataDateTimeHKContext.Provider>
  );

}