// Common.js

import AuthenticationService from '../AuthenticationService.js';
import { useRoutes, A, navigate } from "hookrouter";
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import axios from 'axios';
import { useState } from "react";
import { w3cwebsocket as W3CWebSocket } from 'websocket';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Chip from '@mui/material/Chip';
import { red } from '@mui/material/colors';
import { green } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import { Alert, AlertTitle } from '@material-ui/lab';

import greenjpg from '../images/green.jpg';
import redjpg from '../images/red.jpg';

import OptionDetailsListItemAdd from './OptionDetailsListItemAdd.js';
import ProductBarInfo from './ProductBarInfo.js';
import UnderlyingProduct1 from './UnderlyingProduct1.js';

function jsFileName(P_Value)
{
  return(
    <React.Fragment>
      {/* <Grid container spacing={0}>
        <Grid item xs={12} lg={12} align='right'> */}
        <Box color='lightgray'>
          <Typography variant="caption">{P_Value}</Typography>     
        </Box>
          
        {/* </Grid>
      </Grid> */}
    </React.Fragment>
  )  
}

function ListOptionDetailsList_Get(P_ProductType, P_UnderlyingProductCode, P_Expiry, P_BaseContract, P_TimeStatus, P_Last, P_setListOptionDetailsList){

  var v_http_post_data = {
    Credential: AuthenticationService.getCurrentUser(),
    ProductType: P_ProductType,//
    UnderlyingProductCode: P_UnderlyingProductCode,//
    Expiry: P_Expiry,//
    BaseContract: P_BaseContract,//
    TimeStatus: P_TimeStatus,//
    Last: P_Last,
  }

  const url = sessionStorage.getItem('SiteURL') + 'api/OptionDetailsList/List/';

    axios.post(url, v_http_post_data)

      .then(resp => {
        let list = resp.data;
        P_setListOptionDetailsList(list);
        console.log('Expiry of ListOptionDetailsList[0][0].Expiry in index.js: ' + list[0][0].Expiry);
      });
      
}

function WeeklyMonthlyName(P_WeeklyMonthly){

  let _weekly_monthly_name;

  switch(P_WeeklyMonthly){
    case 'W':
      _weekly_monthly_name = '(週期權)'
        break;
    case 'M':
      _weekly_monthly_name = '(月期權)'
        break;
  }

  return _weekly_monthly_name;

}

function NowGet(){

  let _now = new Date();

  let _year = _now.getFullYear().toString();
  let _month = (_now.getMonth() + 1).toString();
  let _day = _now.getDate().toString();
  let _hour = _now.getHours().toString();
  let _minute = _now.getMinutes().toString();

  return _month.padStart(2, "0") + '-' + _day.padStart(2, "0") + ' ' + _hour.padStart(2, "0") + ':' + _minute.padStart(2, "0");

} 

function CurrentDateGet(P_Country){

  switch(P_Country){
    case 'HK':

      break;
    case 'US':
      
      break;
  }

  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  return String(year) + '-' + String(month).padStart(2, '0') + '-' + String(day).padStart(2, '0');

}

function WebSocket_Open(P_Message, P_WebSocketOpenBgColor, P_setWebSocketOpen, P_setWebSocketOpenBgColor, P_ProductType, P_UnderlyingProductCode, P_setIndexStockListItem, P_setWebSocketConsoleMessage, P_OptionListItem_Callback){

  var V_WebSocketURL = sessionStorage.getItem('WebSocketURL');
  const _ws_client = new W3CWebSocket(V_WebSocketURL);      

  _ws_client.onopen = () => {

    var V_ConsoleMessage = P_Message;
    var V_ConsoleDateTime = new Date().toLocaleTimeString('en-US', { hour12: false, hour: "numeric", minute: "numeric", second: "numeric"});
    var V_WebSocketOpen = V_ConsoleMessage + ' (' + V_ConsoleDateTime + ')';

    _ws_client.onmessage = (message) => {
    
      var V_Data = JSON.parse(message.data);

      switch(V_Data.ProductType){

        case 'Index':  

          var V_IndexListItemRC = V_Data.TinyIndexListItem;

          if (P_ProductType == 'IndexOption' & V_IndexListItemRC.IndexCode == P_UnderlyingProductCode)
          {
            P_setWebSocketOpen(V_WebSocketOpen);
            P_setWebSocketOpenBgColor(P_WebSocketOpenBgColor);
            OnMessageIndex(V_Data, P_setIndexStockListItem, P_setWebSocketConsoleMessage, 'OptionTable');
          }                
          break;           

        case 'Stock':  

          var V_StockListItemRC = V_Data.TinyStockListItem;

          if (P_ProductType == 'StockOption' & V_StockListItemRC.StockCode == P_UnderlyingProductCode)
          {
            P_setWebSocketOpen(V_WebSocketOpen);
            P_setWebSocketOpenBgColor(P_WebSocketOpenBgColor);
            OnMessageStock(V_Data, P_setIndexStockListItem, P_setWebSocketConsoleMessage, 'OptionTable');
          }            
          break;

        case 'IndexOption':

          if (P_ProductType == 'IndexOption')
          {
            P_setWebSocketOpen(V_WebSocketOpen);
            P_setWebSocketOpenBgColor(P_WebSocketOpenBgColor);
            OnMessageIndexOption(V_Data, P_OptionListItem_Callback, P_setWebSocketConsoleMessage, 'OptionTable');
          } 
          break;

        case 'StockOption':

          if (P_ProductType == 'StockOption')
          {
            P_setWebSocketOpen(V_WebSocketOpen);
            P_setWebSocketOpenBgColor(P_WebSocketOpenBgColor);
            OnMessageStockOption(V_Data, P_OptionListItem_Callback, P_setWebSocketConsoleMessage, 'OptionTable');
          } 
          break;

      }

    }

    _ws_client.onclose = () => {
      WebSocket_Open('Opened', 'error');
    };

    _ws_client.onerror = (err) => {
      console.error('WebSocket encountered error: ', err.message, 'Closing socket');
      _ws_client.close();
    };

  }

}

function UnderlyingProduct_Show(P_Demo, P_ProductType, P_UnderlyingProductCode, P_OptionList, P_ChartData, P_ChartDataDateTimeHK, P_Currency){

  if (P_Demo == false){

    var V_UnderlyingProductType;

    switch(P_ProductType){
      case 'IndexOption':
        V_UnderlyingProductType = 'Index';
        break;
      case 'StockOption':
        V_UnderlyingProductType = 'Stock';
        break;
    }

    return(

      <UnderlyingProduct1 
        ProductType={V_UnderlyingProductType} 
        UnderlyingProductCode={P_UnderlyingProductCode} 
        TimeStatus={'Current'}
        OptionList={P_OptionList}
        ChartData={P_ChartData}
        ChartDataDateTimeHK={P_ChartDataDateTimeHK}
        Currency={P_Currency}
      />

    )

  }
  else{

    return(
      <React.Fragment>
      </React.Fragment>
    )

  }

}

function LabelFigure(P_Label, P_Value, P_DecimalPlace, P_Date2String, P_LabelWidth, P_ValueWidth){

  let v_color = 'black';
  let v_value_formatted;

  if (P_Value != null)
  {
    if (P_Value < 0) v_color = 'red';

    let v_value_absolute = Math.abs(P_Value);
    let v_value_fixed = v_value_absolute.toFixed(P_DecimalPlace);
    v_value_formatted = Intl.NumberFormat('en-US', {minimumFractionDigits: P_DecimalPlace}).format(v_value_fixed);
  
    if (P_Value < 0) v_value_formatted = '-' + v_value_formatted;
  }

  if (P_Date2String != null) v_value_formatted = v_value_formatted + ' ' + P_Date2String;

  return(
    <Grid container spacing={1}>
      <Grid item xs={P_LabelWidth} lg={P_LabelWidth}>
          <Box display="flex" justifyContent="flex-left">
            {P_Label}
          </Box>      
      </Grid>
      <Grid item xs={P_ValueWidth} lg={P_ValueWidth}>
          <Box color={v_color} display="flex" justifyContent="flex-end">
            {v_value_formatted}
          </Box>
      </Grid>
    </Grid>
  );

}

function LabelPointDollarString(P_ProductType, P_Value, P_Currency){

  if (P_Value != null)
  {

    switch(P_ProductType)
    {
      case 'Index':
      case 'IndexOption':
        return PointFormatter(P_Value, null, null, P_Currency);
        break;
      case 'Stock':
      case 'StockOption':
        return DollarFormatter(P_Value, null, 2, P_Currency);
        break;
    }

  }

}

function LabelPointDollarDateTime(P_ProductType, P_Value, P_2Lines, P_Date2String, P_Align, P_Currency){

  let v_color = 'black';
  let v_value_formatted;

  if (P_Value != null)
  {

    if (P_Value < 0) v_color = 'red';

    switch(P_ProductType)
    {
      case 'Index':
      case 'IndexOption':
        v_value_formatted = PointFormatter(P_Value, null, false);
        break;
      case 'Stock':
      case 'StockOption':
        v_value_formatted = DollarFormatter(P_Value, 1, 2, P_Currency);
        break;
    }

  }

  if (P_2Lines == true){

    return(
      <React.Fragment>
        <Box color={v_color}>
          <Grid item xs={12} lg={12} align={P_Align}>
            {v_value_formatted}<br/>
            <Typography variant="caption">{P_Date2String}</Typography>
          </Grid>
        </Box>     
      </React.Fragment>
    );

  }
  else{

    return(
      <React.Fragment>
        <Box color={v_color}>
          <Grid item xs={12} lg={12} align={P_Align}>
            {v_value_formatted} {P_Date2String}
          </Grid>
        </Box>     
      </React.Fragment>
    );

  }

}

function LabelPointDollarRHS(P_ProductType, P_Value, P_Multiplier, P_2Lines, P_Date2String, P_DecimalPlaces, P_Currency){

  let v_color = 'black';
  let v_value_formatted;

  if (P_Value != null)
  {

    if (P_Value < 0) v_color = 'red';

    switch(P_ProductType)
    {
      case 'Index':
      case 'IndexOption':
        v_value_formatted = PointFormatter(P_Value, P_Multiplier, P_2Lines, P_Currency);
        break;
      case 'Stock':
      case 'StockOption':
        v_value_formatted = DollarFormatter(P_Value, P_Multiplier, P_DecimalPlaces, P_Currency);
        break;
    }

  }

  if (P_Date2String == null){
    return(
      <React.Fragment>
            <Box color={v_color}>
              <Grid item xs={12} lg={12} align='right'>
                {v_value_formatted}
              </Grid>
            </Box>     
      </React.Fragment>
    );
  }
  else{
    return(
      <React.Fragment>
            <Box color={v_color}>
              <Grid item xs={12} lg={12} align='right'>
                {v_value_formatted} {P_Date2String}
              </Grid>
            </Box>     
      </React.Fragment>
    );
  }

}

function LabelPointDollarLHS(P_Label1, P_Label2, P_ProductType, P_Value, P_Multiplier, P_Currency){

  let v_color = 'black';
  let v_value_formatted;

  if (P_Value != null)
  {
    // if (P_Value < 0) v_color = 'red';

    switch(P_ProductType)
    {
      case 'Index':
      case 'IndexOption':
        v_value_formatted = PointFormatter(P_Value, P_Multiplier, true);
        break;
      case 'Stock':
      case 'StockOption':
        v_value_formatted = DollarFormatter(P_Value, P_Multiplier, 2, P_Currency);
        break;
    }
  }

  // if (P_Date2String != null) v_value_formatted = v_value_formatted + ' ' + P_Date2String;

  return(
    <React.Fragment>
    {/* <Grid container spacing={1}> */}
      {/* <Grid item xs={12} lg={12}> */}
          <Box color={v_color}>
            <Grid item xs={12} lg={12} align='left'>
              {P_Label1} {v_value_formatted}<br/>
              <Typography variant="caption">{P_Label2}</Typography>
            </Grid> 
          </Box>      
      {/* </Grid> */}
    {/* </Grid> */}
    </React.Fragment>
  );

}

function LabelPointDollar(P_Label1, P_Label2, P_ProductType, P_Value, P_Multiplier, P_2Lines, P_Date2String, P_LeftWidth, P_RightWidth, P_DecimalPlaces, P_Currency){

  return(
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={P_LeftWidth} lg={P_LeftWidth} align='left'>
          {LabelPointDollarLHS(P_Label1, P_Label2, null, null, null, null)}
        </Grid>
        <Grid item xs={P_RightWidth} lg={P_RightWidth} align='right'>
          {LabelPointDollarRHS(P_ProductType, P_Value, P_Multiplier, P_2Lines, P_Date2String, P_DecimalPlaces, P_Currency)}
        </Grid>
      </Grid>      
    </React.Fragment>
  )

}

function PointFormatter(P_Value, P_Multiplier, P_2Lines, P_Currency){

  if (P_Multiplier == null)
  {
    let v_value_absolute = Math.abs(P_Value);
    let v_value_fixed = v_value_absolute.toFixed(0);
    let v_value_formatted = Intl.NumberFormat('en-US', {minimumFractionDigits: 0}).format(v_value_fixed);

    if (P_Value >= 0) return v_value_formatted;
    else return '-' + v_value_formatted;
  }
  else
  {
    let v_value_formatted = DollarFormatter(P_Value, P_Multiplier, 0, P_Currency);
    
    // return v_value_formatted + ' (' + P_Value + ')';

    switch(P_2Lines)
    {
      case true:
        return(
          <Grid item xs={12} lg={12} align='right'>
            {v_value_formatted}<br/>
            <Typography variant="caption">({P_Value})</Typography>
          </Grid> 
        )

      case false:
        return(
          <Grid item xs={12} lg={12} align='right'>
            {/* {v_value_formatted} <Typography variant="caption">({P_Value})</Typography> */}
            {v_value_formatted} ({P_Value})
          </Grid>
        )
    }

  }

}

function DollarFormatter(P_Value, P_Multiplier, P_DecimalPlace, P_Currency){
  
  let v_value_absolute;
  
  if (P_Multiplier != null){
    v_value_absolute = Math.abs(P_Value * P_Multiplier);
  }  
  else{
    v_value_absolute = Math.abs(P_Value);
  }

  let v_value_fixed = v_value_absolute.toFixed(P_DecimalPlace);
  let v_value_formatted = Intl.NumberFormat('en-US', {minimumFractionDigits: P_DecimalPlace}).format(v_value_fixed);

  switch(P_Currency){
    case 'HKD':
      if (P_Value >= 0) return 'HK$' + v_value_formatted;
      else return '-HK$' + v_value_formatted;
    case 'USD':
      if (P_Value >= 0) return 'US$' + v_value_formatted;
      else return '-US$' + v_value_formatted;
  }

  // if (P_Value >= 0) return '123$' + v_value_formatted;
  // else return '-123$' + v_value_formatted;

}

function AlertsSet2(p_LegList, P_Multiplier, P_ProductType, P_Currency){

  const _alerts =  p_LegList.map((e, key) => {

    return(
      <Grid item xs={12} lg={12}>
        <ProductBarInfo
          Contract={e.Contract}
          ProductType={P_ProductType}
          ProductCode={e.ProductCode}
          CallPut={e.CallPut}
          BidAsk={e.BidAsk}
          RevisionDateTimeHKBidAsk={e.RevisionDateTimeHKBidAsk}
          Multiplier={P_Multiplier}
          Currency={P_Currency}
        />  
        <br/>
      </Grid>
    )

  });

  return _alerts;

}

function AlertsSet(p_LegList){

  const _alerts =  p_LegList.map((e, key) => {

      let LongShortCallPut = e.LongShortCallPut;
      let severity, icon;

      switch(LongShortCallPut){

          case 'LongCall':
          case 'ShortCall':
              severity = 'success';
              break;

          case 'LongPut':
          case 'ShortPut':
              severity = 'error';
              break;

      }

      switch(LongShortCallPut){

          case 'LongCall':
              icon = 'LC';
              break;

          case 'LongPut':
              icon = 'LP';
              break;

          case 'ShortCall':
              icon = 'SC';
              break;

          case 'ShortPut':
              icon = 'SP';
              break;

      }

      let OpenOptionLabel = e.OpenOptionLabel;
      let CloseOptionLabel = e.CloseOptionLabel;

      return (
          <span>
              <Alert severity={severity} variant="filled" icon={icon}><AlertTitle>{OpenOptionLabel}</AlertTitle>({CloseOptionLabel})<br/><br/>代碼：{e.ProductCode}</Alert><br/>
          </span>
      );

  });

  return _alerts;

}

function Dollar2Point(P_Dollar){

  let _color = 'black';

  if (P_Dollar < 0){
    _color = 'red';
  }

  return(
    <Box color={_color} display="flex" justifyContent="flex-end">
      ({P_Dollar / 50})
    </Box>
  );

}

function OptionDetailsListSubmit(P_OptionDetailsList){    

  P_OptionDetailsList.forEach((_option_details_list_item) => {
    OptionDetailsListItemAdd(_option_details_list_item)
  });

  var v_http_post_data = {
    Credential: AuthenticationService.getCurrentUser(),
    ProductType: P_OptionDetailsList[0].ProductType,
    UnderlyingProductCode: P_OptionDetailsList[0].UnderlyingProductCode,
    OptionDetailsList: P_OptionDetailsList,
    TimeStatus: 'Current',
  }

  const url = sessionStorage.getItem('SiteURL') + '/api/GroupNumberListItem/Post/';

  axios.post(url, v_http_post_data)

  .then(resp => {
    let _return_value = resp.data;
  });

} 

function PutOption2OptionDetailsListItem(P_PutOption){

  var _option_details_list_item = {

    CallPut: 'P',

    ProductType: P_PutOption.ProductType,
    UnderlyingProductCode: P_PutOption.UnderlyingProductCode,
    ProductCode: P_PutOption.ProductCode,
    Expiry: P_PutOption.Expiry,
    Strike: P_PutOption.Strike,

    Delta: P_PutOption.Delta,
    Theta: P_PutOption.Theta,
    Vega: P_PutOption.Vega,

    RefCostContract: 0,
    RefCostShare: 0,
    RefCostPrice: 0,

    RefValueContract: 0,
    RefValueShare: 0,
    RefValuePrice: 0,

    TotalCostAvg: 0,
    TotalValueAvg: 0,
    TotalProfitAvg: 0,          

    Status: 'Pending',
    Triggered: false,

  }  
  
  return _option_details_list_item;

}

function CallOption2OptionDetailsListItem(P_CallOption){

  var _option_details_list_item = {

    CallPut: 'C',

    ProductType: P_CallOption.ProductType,
    UnderlyingProductCode: P_CallOption.UnderlyingProductCode,
    ProductCode: P_CallOption.ProductCode,
    Expiry: P_CallOption.Expiry,
    Strike: P_CallOption.Strike,

    Delta: P_CallOption.Delta,
    Theta: P_CallOption.Theta,
    Vega: P_CallOption.Vega,

    RefCostContract: 0,
    RefCostShare: 0,
    RefCostPrice: 0,

    RefValueContract: 0,
    RefValueShare: 0,
    RefValuePrice: 0,

    TotalCostAvg: 0,
    TotalValueAvg: 0,
    TotalProfitAvg: 0,          

    Status: 'Pending',
    Triggered: false,

  }  
  
  return _option_details_list_item;

}

function OptionTableListItem4Put(P_OptionTableListItem, P_Currency){

  var V_PutOption ={};

  V_PutOption.ProductType = P_OptionTableListItem.ProductType;
  V_PutOption.UnderlyingProductCode = P_OptionTableListItem.UnderlyingProductCode;
  V_PutOption.Expiry = P_OptionTableListItem.Expiry;
  V_PutOption.Strike = P_OptionTableListItem.Strike;

  V_PutOption.ProductCode = P_OptionTableListItem.PutProductCode;
  V_PutOption.CallPut = 'Put';
  V_PutOption.Currency = P_Currency;

  V_PutOption.Bid = P_OptionTableListItem.PutBid;
  V_PutOption.Ask = P_OptionTableListItem.PutAsk;
  V_PutOption.Last = P_OptionTableListItem.PutLast;
  
  V_PutOption.Close = P_OptionTableListItem.PutClose;
  V_PutOption.Open = P_OptionTableListItem.PutOpen;
  
  V_PutOption.Volume = P_OptionTableListItem.PutVolume;
  V_PutOption.OpenInterest = P_OptionTableListItem.PutOpenInterest;
  V_PutOption.PreviousOpenInterest = P_OptionTableListItem.PutPreviousOpenInterest;
  
  V_PutOption.Delta = Rounding(P_OptionTableListItem.PutDelta, 2);
  V_PutOption.Gamma = Rounding(P_OptionTableListItem.PutGamma, 2);
  V_PutOption.Theta = Rounding(P_OptionTableListItem.PutTheta, 2);
  V_PutOption.Vega = Rounding(P_OptionTableListItem.PutVega, 2);
  V_PutOption.ImpliedVolatility = Rounding(P_OptionTableListItem.PutImpliedVolatility, 2);
  
  V_PutOption.RevisionDateTimeHKBid = P_OptionTableListItem.RevisionDateTimeHKPutBid;
  V_PutOption.RevisionDateTimeHKAsk = P_OptionTableListItem.RevisionDateTimeHKPutAsk;
  V_PutOption.RevisionDateTimeHKLast = P_OptionTableListItem.RevisionDateTimeHKPutLast;

  V_PutOption.RevisionDateTimeHKVolume = P_OptionTableListItem.RevisionDateTimeHKPutVolume;
  V_PutOption.RevisionDateTimeHKOpenInterest = P_OptionTableListItem.RevisionDateTimeHKPutOpenInterest;
  V_PutOption.RevisionDateTimeHKPreviousOpenInterest = P_OptionTableListItem.RevisionDateTimeHKPutPreviousOpenInterest;
  V_PutOption.RevisionDateTimeHKOptionComputation = P_OptionTableListItem.RevisionDateTimeHKPutOptionComputation;

  return V_PutOption;

}

function OptionTableListItem4Call(P_OptionTableListItem, P_Currency){

  var V_CallOption ={};

  V_CallOption.ProductType = P_OptionTableListItem.ProductType;
  V_CallOption.UnderlyingProductCode = P_OptionTableListItem.UnderlyingProductCode;
  V_CallOption.Expiry = P_OptionTableListItem.Expiry;
  V_CallOption.Strike = P_OptionTableListItem.Strike;

  V_CallOption.ProductCode = P_OptionTableListItem.CallProductCode;
  V_CallOption.CallPut = 'Call';
  V_CallOption.Currency = P_Currency;

  // V_CallOption.Bid = LongUnderlyingProductValue(P_OptionTableListItem.ProductType, P_OptionTableListItem.CallBid);
  // V_CallOption.Ask = LongUnderlyingProductValue(P_OptionTableListItem.ProductType, P_OptionTableListItem.CallAsk);
  // V_CallOption.Last = LongUnderlyingProductValue(P_OptionTableListItem.ProductType, P_OptionTableListItem.CallLast);
  
  // V_CallOption.Close = LongUnderlyingProductValue(P_OptionTableListItem.ProductType, P_OptionTableListItem.CallClose);
  // V_CallOption.Open = LongUnderlyingProductValue(P_OptionTableListItem.ProductType, P_OptionTableListItem.CallOpen);
  
  // V_CallOption.Volume = LongUnderlyingProductValue("IndexOption", P_OptionTableListItem.CallVolume);
  // V_CallOption.OpenInterest = LongUnderlyingProductValue("IndexOption", P_OptionTableListItem.CallOpenInterest);
  // V_CallOption.PreviousOpenInterest = LongUnderlyingProductValue("IndexOption", P_OptionTableListItem.CallPreviousOpenInterest);
  
  V_CallOption.Bid = P_OptionTableListItem.CallBid;
  V_CallOption.Ask = P_OptionTableListItem.CallAsk;
  V_CallOption.Last = P_OptionTableListItem.CallLast;
  
  V_CallOption.Close = P_OptionTableListItem.CallClose;
  V_CallOption.Open = P_OptionTableListItem.CallOpen;
  
  V_CallOption.Volume = P_OptionTableListItem.CallVolume;
  V_CallOption.OpenInterest = P_OptionTableListItem.CallOpenInterest;
  V_CallOption.PreviousOpenInterest = P_OptionTableListItem.CallPreviousOpenInterest;

  V_CallOption.Delta = Rounding(P_OptionTableListItem.CallDelta, 2);
  V_CallOption.Gamma = Rounding(P_OptionTableListItem.CallGamma, 2);
  V_CallOption.Theta = Rounding(P_OptionTableListItem.CallTheta, 2);
  V_CallOption.Vega = Rounding(P_OptionTableListItem.CallVega, 2);
  V_CallOption.ImpliedVolatility = Rounding(P_OptionTableListItem.CallImpliedVolatility, 2);
  
  V_CallOption.RevisionDateTimeHKBid = P_OptionTableListItem.RevisionDateTimeHKCallBid;
  V_CallOption.RevisionDateTimeHKAsk = P_OptionTableListItem.RevisionDateTimeHKCallAsk;
  V_CallOption.RevisionDateTimeHKLast = P_OptionTableListItem.RevisionDateTimeHKCallLast;

  V_CallOption.RevisionDateTimeHKVolume = P_OptionTableListItem.RevisionDateTimeHKCallVolume;
  V_CallOption.RevisionDateTimeHKOpenInterest = P_OptionTableListItem.RevisionDateTimeHKCallOpenInterest;
  V_CallOption.RevisionDateTimeHKPreviousOpenInterest = P_OptionTableListItem.RevisionDateTimeHKCallPreviousOpenInterest;
  V_CallOption.RevisionDateTimeHKOptionComputation = P_OptionTableListItem.RevisionDateTimeHKCallOptionComputation;

  return V_CallOption;

}

function UnderlyingProductDiffString_Get(P_ProductType, P_UnderlyingProductDiff){

  var V_UnderlyingProductDiffString = LongUnderlyingProductValue(P_ProductType, P_UnderlyingProductDiff);
  if (P_UnderlyingProductDiff > 0){
    V_UnderlyingProductDiffString = '(+' + V_UnderlyingProductDiffString + ')';
  }
  else{
    V_UnderlyingProductDiffString = '(' + V_UnderlyingProductDiffString + ')';
  }

  return V_UnderlyingProductDiffString;
  
}

function OnMessageStockOption(P_Data, OptionListItem_Callback, WebSocketConsoleMessage_Callback, P_Where){

  var V_OptionListItemRC = P_Data.TinyOptionListItem; 
          
  var V_ConsoleMessage = V_OptionListItemRC.ProductCode;
  var V_ConsoleDateTime = new Date().toLocaleTimeString('en-US', { hour12: false, hour: "numeric", minute: "numeric", second: "numeric"});
  var V_WebSocketConsoleMessage = V_ConsoleMessage + ' (' + V_ConsoleDateTime + ')';

  OptionListItem_Callback(V_OptionListItemRC);

  WebSocketConsoleMessage_Callback(V_WebSocketConsoleMessage);
  console.log(V_WebSocketConsoleMessage + ' ' + P_Where);

}

function OnMessageIndexOption(P_Data, OptionListItem_Callback, WebSocketConsoleMessage_Callback, P_Where){

  var V_OptionListItemRC = P_Data.TinyOptionListItem;             
          
  var V_ConsoleMessage = V_OptionListItemRC.ProductCode;
  var V_ConsoleDateTime = new Date().toLocaleTimeString('en-US', { hour12: false, hour: "numeric", minute: "numeric", second: "numeric"});
  var V_WebSocketConsoleMessage = V_ConsoleMessage + ' (' + V_ConsoleDateTime + ')';

  OptionListItem_Callback(V_OptionListItemRC);

  WebSocketConsoleMessage_Callback(V_WebSocketConsoleMessage);
  console.log(V_WebSocketConsoleMessage + ' ' + P_Where);

}

function OnMessageIndex(P_Data, IndexStockListItem_Callback, WebSocketConsoleMessage_Callback, P_Where){

  var V_IndexListItemRC = P_Data.TinyIndexListItem;
  V_IndexListItemRC.ProductType = P_Data.ProductType;

  var V_ConsoleMessage = V_IndexListItemRC.IndexCode;
  var V_ConsoleDateTime = new Date().toLocaleTimeString('en-US', { hour12: false, hour: "numeric", minute: "numeric", second: "numeric"});
  var V_WebSocketConsoleMessage = V_ConsoleMessage + ' (' + V_ConsoleDateTime + ')';
  
  IndexStockListItem_Callback(V_IndexListItemRC);  

  WebSocketConsoleMessage_Callback(V_WebSocketConsoleMessage);
  console.log(V_WebSocketConsoleMessage + ' ' + P_Where + ' ' + P_Data.AlertMessage);

}

function OnMessageStock(P_Data, IndexStockListItem_Callback, WebSocketConsoleMessage_Callback, P_Where){

  var V_StockListItemRC = P_Data.TinyStockListItem;
  V_StockListItemRC.ProductType = P_Data.ProductType;

  var V_ConsoleMessage = V_StockListItemRC.StockCode;
  var V_ConsoleDateTime = new Date().toLocaleTimeString('en-US', { hour12: false, hour: "numeric", minute: "numeric", second: "numeric"});
  var V_WebSocketConsoleMessage = V_ConsoleMessage + ' (' + V_ConsoleDateTime + ')';
  
  IndexStockListItem_Callback(V_StockListItemRC);  

  WebSocketConsoleMessage_Callback(V_WebSocketConsoleMessage);
  console.log(V_WebSocketConsoleMessage + ' ' + P_Where + ' ' + P_Data.AlertMessage);

}

function Profit(P_GroupNumberListItem){

  if (P_GroupNumberListItem.Active == false){

    var V_Profit = P_GroupNumberListItem.TotalProfitAvg + P_GroupNumberListItem.TotalIntrinsicValueProfitAvg;

    var V_ColorJpg;
    if (V_Profit >= 0){
      V_ColorJpg = greenjpg;
    }
    else{
      V_ColorJpg = redjpg;
    }

    return(
      // <Chip label={'利潤: ' + LongFormattedValue(V_Profit, 0, true)} size="small" sx={{ paddingLeft: 0, paddingRight: 0 }}/>
      <Chip avatar={<Avatar src={V_ColorJpg} />} label={RedColor_Set(null, LongFormattedValue(V_Profit, 0, true))} variant="outlined" sx={{ paddingLeft: 0, paddingRight: 0 }} size='small'/>
    )
  }

}

function ActiveOptionTableList_Get(P_FullOptionTableList, P_Last){

  var V_OptionTableRowTotal = 28;

  var V_ActiveOptionTableList = [];

  var V_IndexJustAboveLast = P_FullOptionTableList.findIndex(x => x.Strike > P_Last);
  var V_IndexLowest = V_IndexJustAboveLast - V_OptionTableRowTotal/2;
  var V_IndexHighest = V_IndexJustAboveLast + (V_OptionTableRowTotal/2 - 1);

  for (var V_Index = V_IndexLowest; V_Index < V_IndexHighest+1; V_Index++) {
    V_ActiveOptionTableList.push(P_FullOptionTableList[V_Index])
  }

  //

  var _call_open_interest_diff_max = -999;
  var _put_open_interest_diff_max = -999;

  for (var _index = 0; _index < V_ActiveOptionTableList.length; _index++)
  {
      if (V_ActiveOptionTableList[_index].CallOpenInterestDiff > _call_open_interest_diff_max) _call_open_interest_diff_max = V_ActiveOptionTableList[_index].CallOpenInterestDiff;
      if (V_ActiveOptionTableList[_index].PutOpenInterestDiff > _put_open_interest_diff_max) _put_open_interest_diff_max = V_ActiveOptionTableList[_index].PutOpenInterestDiff;
  }

  for (var _index = 0; _index < V_ActiveOptionTableList.length; _index++)
  {
      if (V_ActiveOptionTableList[_index].CallOpenInterestDiff == _call_open_interest_diff_max)
      {
        V_ActiveOptionTableList[_index].CallMarked = true;
          if (V_ActiveOptionTableList[_index].CallOpenInterestDiff > _put_open_interest_diff_max) V_ActiveOptionTableList[_index].OpenInterestDiffMax = true;
      }
      else
      {
        V_ActiveOptionTableList[_index].CallMarked = false;
      }

      if (V_ActiveOptionTableList[_index].PutOpenInterestDiff == _put_open_interest_diff_max)
      {
        V_ActiveOptionTableList[_index].PutMarked = true;
          if (V_ActiveOptionTableList[_index].PutOpenInterestDiff > _call_open_interest_diff_max) V_ActiveOptionTableList[_index].OpenInterestDiffMax = true;
      }
      else
      {
        V_ActiveOptionTableList[_index].PutMarked = false;
      }
  }
  
  return V_ActiveOptionTableList;

}

function CalcIntrinsicValue(P_OptionDetailsListItem, P_UnderlyingProductValue, P_Share){

  var V_IntrinsicValue = 0;

  switch(P_OptionDetailsListItem.OptionType){

    case 'LongCall':
    case 'ShortCall':

      if (P_UnderlyingProductValue > P_OptionDetailsListItem.Strike){
        V_IntrinsicValue = (P_UnderlyingProductValue - P_OptionDetailsListItem.Strike) * P_Share;
      }

      if (P_OptionDetailsListItem.OptionType == 'ShortCall') V_IntrinsicValue = V_IntrinsicValue * (-1)

      break;

    case 'LongPut':
    case 'ShortPut':

      if (P_UnderlyingProductValue < P_OptionDetailsListItem.Strike){
        V_IntrinsicValue = (P_OptionDetailsListItem.Strike - P_UnderlyingProductValue) * P_Share;
      }

      if (P_OptionDetailsListItem.OptionType == 'ShortPut') V_IntrinsicValue = V_IntrinsicValue * (-1)

      break;

  }

  return V_IntrinsicValue;

}

function GroupNumberList_Update(P_OptionList, P_GroupNumberList, P_UnderlyingProductLast){

  var V_UnderlyingProductValueRounded = Rounding(P_UnderlyingProductLast, 0);

  P_GroupNumberList.map((M_GroupNumberListItem)=>{

    M_GroupNumberListItem.OptionDetailsList.map((M_OptionDetailsListItem) => {

      SetBidAskValue(P_OptionList, M_OptionDetailsListItem);

      switch(M_OptionDetailsListItem.Status){

        case 'Pending':

          M_OptionDetailsListItem.TotalCostAvg = M_OptionDetailsListItem.RefCostShare * M_OptionDetailsListItem.RefCostPrice * (-1); // e.g. 100 * 2.55 becomes -254.99999997
          M_OptionDetailsListItem.TotalCostAvg = parseFloat(M_OptionDetailsListItem.TotalCostAvg.toFixed(2));

          M_OptionDetailsListItem.TotalValueAvg = M_OptionDetailsListItem.RefValueShare * M_OptionDetailsListItem.RefValuePrice * (-1);  
          M_OptionDetailsListItem.TotalValueAvg = parseFloat(M_OptionDetailsListItem.TotalValueAvg.toFixed(2));

          M_OptionDetailsListItem.TotalProfitAvg = M_OptionDetailsListItem.TotalValueAvg + M_OptionDetailsListItem.TotalCostAvg;

          M_OptionDetailsListItem.TotalIntrinsicValueAvg = CalcIntrinsicValue(M_OptionDetailsListItem, V_UnderlyingProductValueRounded, M_OptionDetailsListItem.RefCostShare);    
          M_OptionDetailsListItem.TotalIntrinsicValueProfitAvg = M_OptionDetailsListItem.TotalIntrinsicValueAvg + M_OptionDetailsListItem.TotalCostAvg;    

          break;

        case 'Opened':

          M_OptionDetailsListItem.TotalCostAvg = M_OptionDetailsListItem.CostShare * M_OptionDetailsListItem.CostPrice * (-1);
          M_OptionDetailsListItem.TotalCostAvg = parseFloat(M_OptionDetailsListItem.TotalCostAvg.toFixed(2));

          M_OptionDetailsListItem.TotalValueAvg = M_OptionDetailsListItem.RefValueShare * M_OptionDetailsListItem.RefValuePrice * (-1); 
          M_OptionDetailsListItem.TotalValueAvg = parseFloat(M_OptionDetailsListItem.TotalValueAvg.toFixed(2));

          M_OptionDetailsListItem.TotalProfitAvg = M_OptionDetailsListItem.TotalValueAvg + M_OptionDetailsListItem.TotalCostAvg;

          M_OptionDetailsListItem.TotalIntrinsicValueAvg = CalcIntrinsicValue(M_OptionDetailsListItem, V_UnderlyingProductValueRounded, M_OptionDetailsListItem.CostShare);      
          M_OptionDetailsListItem.TotalIntrinsicValueProfitAvg = M_OptionDetailsListItem.TotalIntrinsicValueAvg + M_OptionDetailsListItem.TotalCostAvg; 

          break;

        case 'Closed':
        case 'Closed2Expired':

          M_OptionDetailsListItem.TotalValueAvg = M_OptionDetailsListItem.ValueShare * M_OptionDetailsListItem.ValuePrice * (-1);
          M_OptionDetailsListItem.TotalValueAvg = parseFloat(M_OptionDetailsListItem.TotalValueAvg.toFixed(2));
                     
          M_OptionDetailsListItem.TotalProfitAvg = M_OptionDetailsListItem.TotalValueAvg + M_OptionDetailsListItem.TotalCostAvg;

          M_OptionDetailsListItem.TotalIntrinsicValueAvg = 0;       
          M_OptionDetailsListItem.TotalIntrinsicValueProfitAvg = 0; 
  
          break;

        case 'Opened2Expired':

          M_OptionDetailsListItem.TotalValueAvg = 0;           
          M_OptionDetailsListItem.TotalProfitAvg = 0;
 
          var V_Settlement = Rounding(M_OptionDetailsListItem.Settlement, 0);

          M_OptionDetailsListItem.TotalIntrinsicValueAvg = CalcIntrinsicValue(M_OptionDetailsListItem, V_Settlement, M_OptionDetailsListItem.CostShare);      
          M_OptionDetailsListItem.TotalIntrinsicValueProfitAvg = M_OptionDetailsListItem.TotalIntrinsicValueAvg + M_OptionDetailsListItem.TotalCostAvg; 

          break;

      } 

    })

    GroupSummaryCostValueSum(P_OptionList, M_GroupNumberListItem);

  })

}

// function UnderlyingProductValue_Get(props){
   
//   const { ProductType, UnderlyingProductCode, TimeStatus, UnderlyingProductValue_Callback, Auto, WSClient, OptionList, OptionListItem_Callback } = props;

//   const [UnderlyingProductClose, setUnderlyingProductClose] = useState(0);
//   const [UnderlyingProductValue, setUnderlyingProductValue] = useState(0);
//   const [UnderlyingProductDiff, setUnderlyingProductDiff] = useState(0);
//   const [RevisionDateTimeHKLast, setRevisionDateTimeHKLast] = useState('');

//   if (UnderlyingProductCode !== ''){

//   switch(Auto){

//     case true:

//       WSClient.onmessage = (message) => {

//         var V_Data = JSON.parse(message.data);

//         var V_ProductType = V_Data.ProductType;

//         switch(V_ProductType){

//           case 'Index':

//             var V_IndexListItemRC = V_Data.IndexListItemRC;

//             console.log(V_IndexListItemRC.IndexCode);

//             var V_UnderlyingProductCloseString = V_IndexListItemRC.Close;
//             var V_UnderlyingProductClose = parseFloat(V_UnderlyingProductCloseString);

//             var V_UnderlyingProductValueString = V_IndexListItemRC.Last; // Last value
//             var V_UnderlyingProductValue = parseFloat(V_UnderlyingProductValueString);

//             var V_UnderlyingProductDiff = V_UnderlyingProductValue - V_UnderlyingProductClose;

//             var V_RevisionDateTimeHKLast = V_IndexListItemRC.RevisionDateTimeHKLast;
      
//             if (UnderlyingProductValue !== V_UnderlyingProductValue){
//               UnderlyingProductValue_Callback(V_UnderlyingProductValue);
//             }
    
//             setUnderlyingProductClose(V_UnderlyingProductClose);
//             setUnderlyingProductValue(V_UnderlyingProductValue);
//             setUnderlyingProductDiff(V_UnderlyingProductDiff);
//             setRevisionDateTimeHKLast(V_RevisionDateTimeHKLast);   

//             break;
            
//           case 'IndexOption':

//             var V_OptionListItemRC = V_Data.OptionListItemRC;

//             console.log(V_OptionListItemRC.ProductCode);

//             OptionListItem_Callback(V_OptionListItemRC);

//             break;

//         }

//       }

//       break;

//     case false:

//       var v_http_post_data = {
//         Credential: AuthenticationService.getCurrentUser(),
//         UnderlyingProductCode: UnderlyingProductCode,
//         TimeStatus: TimeStatus,
//       }
    
//       let url;
    
//       switch(ProductType){
//         case 'IndexOption':
//           url = sessionStorage.getItem('SiteURL') + 'api/IndexListItem/IndexCode/';
//           break;
//         case 'StockOption':
//           url = sessionStorage.getItem('SiteURL') + 'api/StockListItem/StockCode/';
//           break;
//       }
    
//       axios.post(url, v_http_post_data)
    
//         .then(resp => {
    
//             var V_ListItem = resp.data;

//             var V_UnderlyingProductCloseString = V_ListItem.Close;
//             var V_UnderlyingProductClose = parseFloat(V_UnderlyingProductCloseString);

//             var V_UnderlyingProductValueString = V_ListItem.Last; // Last value
//             var V_UnderlyingProductValue = parseFloat(V_UnderlyingProductValueString);

//             var V_UnderlyingProductDiff = V_UnderlyingProductValue - V_UnderlyingProductClose;

//             var V_RevisionDateTimeHKLast = V_ListItem.RevisionDateTimeHKLast;
    
//             if (UnderlyingProductValue !== V_UnderlyingProductValue){
//               UnderlyingProductValue_Callback(V_UnderlyingProductValue);
//             }
  
//             setUnderlyingProductClose(V_UnderlyingProductClose);
//             setUnderlyingProductValue(V_UnderlyingProductValue);
//             setUnderlyingProductDiff(V_UnderlyingProductDiff);
//             setRevisionDateTimeHKLast(V_RevisionDateTimeHKLast);          
    
//         });
  
//         break;

//     }       

//   } 
  
//   var V_UnderlyingProductDiffString = LongUnderlyingProductValue("Index", UnderlyingProductDiff);
//   if (UnderlyingProductDiff > 0){
//     V_UnderlyingProductDiffString = '(+' + V_UnderlyingProductDiffString + ')';
//   }
//   else{
//     V_UnderlyingProductDiffString = '(' + V_UnderlyingProductDiffString + ')';
//   } 

//   return(
//     <div>
//       <Grid container spacing={2}>
//         <Grid item align="left" xs={6} lg={6}>
//           現貨價: {LongUnderlyingProductValue("Index", UnderlyingProductValue)} {V_UnderlyingProductDiffString}
//         </Grid>
//         <Grid item align="right" xs={6} lg={6}>
//           更新: {date2string(RevisionDateTimeHKLast, false, true)}
//         </Grid>
//       </Grid> 
//     </div>
//   )
    
// }

function LabelBidAsk(P_Contract){
  
  if (P_Contract > 0){
    return 'Ask';
  }
  else{
    return 'Bid';
  }

}

function OTM_ATM_ITM(P_OptionList, P_ProductCode){

  var V_OptionListItem = P_OptionList.find(x => x.ProductCode === P_ProductCode);

  if (V_OptionListItem !== undefined){

    switch(V_OptionListItem.OtmAtmItm){
      case 'OTM':
        return '價外';
        break;
      case 'ATM':
        return '等價';
        break;
      case 'ITM':
        return '價內';
        break;
    }

  }
  else{

    return 'N/A';

  }

}

function GroupSummaryCostValueSum(P_OptionList, P_GroupNumberListItem){
  
  var V_GroupSummaryTotalCostAvg = 0;
  var V_GroupSummaryTotalValueAvg = 0;
  var V_GroupSummaryTotalProfitAvg = 0;
  var V_GroupSummaryTotalIntrinsicValueAvg = 0;
  var V_GroupSummaryTotalIntrinsicValueProfitAvg = 0;

  for (let _index = 0; _index < P_GroupNumberListItem.OptionDetailsList.length; _index++) {

    Greeks(P_OptionList, P_GroupNumberListItem.OptionDetailsList[_index]);

    V_GroupSummaryTotalCostAvg += P_GroupNumberListItem.OptionDetailsList[_index].TotalCostAvg; 
    V_GroupSummaryTotalValueAvg += P_GroupNumberListItem.OptionDetailsList[_index].TotalValueAvg;  
    V_GroupSummaryTotalProfitAvg += P_GroupNumberListItem.OptionDetailsList[_index].TotalProfitAvg;   
    V_GroupSummaryTotalIntrinsicValueAvg += P_GroupNumberListItem.OptionDetailsList[_index].TotalIntrinsicValueAvg;
    V_GroupSummaryTotalIntrinsicValueProfitAvg += P_GroupNumberListItem.OptionDetailsList[_index].TotalIntrinsicValueProfitAvg;

  }

  P_GroupNumberListItem.TotalCostAvg = V_GroupSummaryTotalCostAvg;
  P_GroupNumberListItem.TotalValueAvg = V_GroupSummaryTotalValueAvg;
  P_GroupNumberListItem.TotalProfitAvg = V_GroupSummaryTotalProfitAvg;
  P_GroupNumberListItem.TotalIntrinsicValueAvg = V_GroupSummaryTotalIntrinsicValueAvg;
  P_GroupNumberListItem.TotalIntrinsicValueProfitAvg = V_GroupSummaryTotalIntrinsicValueProfitAvg;

}

function BidAskDisplay(p_OptionDetailsListItem){

  if (p_OptionDetailsListItem.RefCostContract > 0)
  {

    return(

      <div>
  
        <Grid container spacing={2} align="center">
          <Grid item xs={6} lg={6}>
          Ask: {p_OptionDetailsListItem.Ask}
          </Grid>
          <Grid item xs={6} lg={6}>
            
          </Grid>
        </Grid>
  
      </div>
  
    )

  }
  else if (p_OptionDetailsListItem.RefCostContract == 0)
  {

    return(

      <div>
  
        <Grid container spacing={2} align="center">
          <Grid item xs={6} lg={6}>
            Bid: {p_OptionDetailsListItem.Bid}
          </Grid>
          <Grid item xs={6} lg={6}>
            Ask: {p_OptionDetailsListItem.Ask}
          </Grid>
        </Grid>
  
      </div>
  
    )

  }
  else
  {

    return(

      <div>
  
        <Grid container spacing={2} align="center">
          <Grid item xs={6} lg={6}>
            Bid: {p_OptionDetailsListItem.Bid}
          </Grid>
          <Grid item xs={6} lg={6}>
            
          </Grid>
        </Grid>
  
      </div>
  
    )

  }

}

function Greeks(p_OptionList, p_OptionDetailsListItem){

  let _option_list_item = p_OptionList.find(x => x.ProductCode === p_OptionDetailsListItem.ProductCode);

  if (_option_list_item != undefined){ // _option_list_item = undefined if it has already expired and it cannot be found in P_OptionList
    p_OptionDetailsListItem.Delta = _option_list_item.Delta;
    p_OptionDetailsListItem.Theta = _option_list_item.Theta;
    p_OptionDetailsListItem.Vega = _option_list_item.Vega;
  }

}

function BidDetails(p_OptionList, p_OptionDetailsListItem){

  var V_Bid = 0;
  var V_RevisionDateTimeHKBid;

  let _option_list_item = p_OptionList.find(x => x.ProductCode === p_OptionDetailsListItem.ProductCode);

  if (_option_list_item == undefined){
    V_Bid = 0;
    V_RevisionDateTimeHKBid = '1900-01-01';
  }
  else{
    V_Bid = _option_list_item.Bid;
    V_RevisionDateTimeHKBid = _option_list_item.RevisionDateTimeHKBid;
  }  

  var V_Data = {
    Bid: V_Bid,
    RevisionDateTimeHKBid: V_RevisionDateTimeHKBid,
  }

  return V_Data;

}

function AskDetails(p_OptionList, p_OptionDetailsListItem){

  var V_Ask = 0;
  var V_RevisionDateTimeHKAsk;

  let _option_list_item = p_OptionList.find(x => x.ProductCode === p_OptionDetailsListItem.ProductCode);

  if (_option_list_item == undefined){
    V_Ask = 0;
    V_RevisionDateTimeHKAsk = '1900-01-01';
  }
  else{
    V_Ask = _option_list_item.Ask;
    V_RevisionDateTimeHKAsk = _option_list_item.RevisionDateTimeHKAsk;
  }  
  
  var V_Data = {
    Ask: V_Ask,
    RevisionDateTimeHKAsk: V_RevisionDateTimeHKAsk,
  }

  return V_Data;

}

function Bid(p_OptionList, p_OptionDetailsListItem){

  let _bid = 0;

  let _option_list_item = p_OptionList.find(x => x.ProductCode === p_OptionDetailsListItem.ProductCode);

  if (_option_list_item == undefined){
    _bid = 0;
  }
  else{
    _bid = _option_list_item.Bid;
  }  

  return _bid;

}

function Ask(p_OptionList, p_OptionDetailsListItem){

  let _ask = 0;

  let _option_list_item = p_OptionList.find(x => x.ProductCode === p_OptionDetailsListItem.ProductCode);

  if (_option_list_item == undefined){
    _ask = 0;
  }
  else{
    _ask = _option_list_item.Ask;
  }  
  
  return _ask;

}

function SetBidAskValue(p_option_list, p_option_details_list_item){

  let _bid = Bid(p_option_list, p_option_details_list_item);
  let _ask = Ask(p_option_list, p_option_details_list_item);

  switch(p_option_details_list_item.Status){

    case 'Pending':
      if (p_option_details_list_item.RefCostContract < 0){
        p_option_details_list_item.RefCostPrice = _bid;
        p_option_details_list_item.RefValuePrice = _ask;
      }
      else if (p_option_details_list_item.RefCostContract > 0)
      {
        p_option_details_list_item.RefCostPrice = _ask;
        p_option_details_list_item.RefValuePrice = _bid;
      }
    break;

    case 'Opened':
      if (p_option_details_list_item.RefValueContract < 0){
        p_option_details_list_item.RefValuePrice = _bid;
      }
      else if (p_option_details_list_item.RefValueContract > 0)
      {
        p_option_details_list_item.RefValuePrice = _ask;
      }
    break;
  
  }

}

function jdate2string4display(p_date){

  if (typeof p_date === 'undefined' | p_date == '')
  {
    return '';
  }
  else
  {
    let year = p_date.getFullYear();
    let month = p_date.getMonth() + 1;
    let day = p_date.getDate();
    let hour = p_date.getHours();
    let minute = p_date.getMinutes();
    let second = p_date.getSeconds();

    let _year = String(year).padStart(4, '0');   //          0123456789012345678
    let _month = String(month).padStart(2, '0');  //          0123 for year; 56 for month; 89 for day, etc. length = 2nd parameter - 1st parameter
    let _day = String(day).padStart(2, '0');
    let _hour = String(hour).padStart(2, '0');
    let _minute = String(minute).padStart(2, '0');
    let _second = String(second).padStart(2, '0');
  
    if (_year == '1900')
    {

      return '';
      
    }
    else
    {
      return _year + '-' + _month + '-' + _day + 'T' + _hour +':' + _minute + ':' + _second;;
    }
    
  }
}

function date2string(p_date, p_bracket, p_second_required, p_date_only){

  if (typeof p_date === 'undefined' | p_date == '' | p_date == null)
  {
    return '';
  }
  else
  {
    let d = p_date.toString();      // e.g. d = 2022-04-19T10:26:07
    let _year = d.substring(0,4);   //          0123456789012345678
    let _month = d.substring(5,7);  //          0123 for year; 56 for month; 89 for day, etc. length = 2nd parameter - 1st parameter
    let _day = d.substring(8,10);
    let _hour = d.substring(11,13);
    let _minute = d.substring(14,16);
    let _second = d.substring(17,19);
  
    if (_year == '1900')
    {

      return '';
      
    }
    else
    {

      let _date_time_string = '';

      switch(p_second_required)
      {
        case true:
          _date_time_string = _month + '-' + _day + ' ' + _hour +':' + _minute + ':' + _second;
          break;
        case false:
          _date_time_string = _month + '-' + _day + ' ' + _hour +':' + _minute;
          break;
      }

      if (p_date_only) _date_time_string = _month + '-' + _day;

      switch(p_bracket)
      {
        case true:
          return '(' + _date_time_string + ')';
          break;

        case false:
          return _date_time_string;
          break;
      }

    }
    
  }
}

function Rounding(value, decimals) {
  // let _debug0 = Number(value.toFixed(decimals)); // return a string
  // let _debug01 = value.toPrecision(decimals); // return a number
  // let _debug1 = value + 'e' + decimals;
  // let _debug2 = Math.round(_debug1);
  // let _debug3 = Number(_debug2 + 'e-' + decimals);
  return value.toFixed(decimals); // return a string
}

function RedColor_Set(P_Label, P_Value){

  let _color = 'black';

  if (P_Value != null){
      let _first_char = P_Value.substring(0, 1);
      if (_first_char == '-'){
          _color = 'red';
      }
  }

  return(
    <Box color={_color} display="flex" justifyContent="flex-end">
      {P_Label}{P_Value}
    </Box>
  );

}

function StartEnd2(P_Label, value, p_label_width, p_value_width){

  let v_color = 'black';

  if (value != null){
      let _value_string = value.toString();
      let _first_char = _value_string.substring(0, 1);
      if (_first_char == '-'){
          v_color = 'red';
      }
  }

    return(
    <Grid container spacing={1}>
      <Grid item xs={p_label_width} lg={p_label_width}>
          <Box display="flex" justifyContent="flex-left">
            {P_Label}
          </Box>      
      </Grid>
      <Grid item xs={p_value_width} lg={p_value_width}>
          <Box color={v_color} display="flex" justifyContent="flex-end">
                  {value}
          </Box>
      </Grid>
      </Grid>
      );

  }

// function StartEnd(label, value){

//   let _color = 'black';

//   if (value != null){
//       let _first_char = value.substring(0, 1);
//       if (_first_char == '-'){
//           _color = 'red';
//       }
//   }

//     return(
//     <Grid container spacing={1}>
//       <Grid item xs={6} lg={6}>
//       {label}
//       </Grid>
//       <Grid item xs={6} lg={6}>
//           <Box color={_color} display="flex" justifyContent="flex-end">
//                   {value}
//           </Box>
//       </Grid>
//       </Grid>
//       );

//   }

// function ButtonAppBar() {

//   const classes = useStyles();

//   const [anchorElNav, setAnchorElNav] = React.useState(null);

//   const handleOpenNavMenu = (event) => {
//     setAnchorElNav(event.currentTarget);
//   };

//   const handleCloseNavMenu = () => {
//     setAnchorElNav(null);
//   };

//   return (
//     <div className={classes.root} >
//       <AppBar position="fixed">
//         <Toolbar>
//           <Typography variant="h7" className={classes.title}>
//           期權策劃師
//           </Typography>
//           <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu}><MenuIcon /></IconButton>

//           <Menu
//               id="menu-appbar"
//               anchorEl={anchorElNav}
//               anchorOrigin={{
//                 vertical: 'bottom',
//                 horizontal: 'left',
//               }}
//               keepMounted
//               transformOrigin={{
//                 vertical: 'top',
//                 horizontal: 'left',
//               }}
//               open={Boolean(anchorElNav)}
//               onClose={handleCloseNavMenu}
//               sx={{
//                 display: { xs: 'block', md: 'none' },
//               }}
//             >

//                 <MenuItem key='Home' onClick={handleCloseNavMenu}>
//                   <Typography><A href='/home' style={{textDecoration: "none"}}>Home</A></Typography>
//                 </MenuItem>

//                 <MenuItem key='About' onClick={handleCloseNavMenu}>
//                   <Typography><A href='/about' style={{textDecoration: "none"}}>About</A></Typography>
//                 </MenuItem>

//                 <MenuItem key='Contact' onClick={handleCloseNavMenu}>
//                   <Typography><A href='/contact' style={{textDecoration: "none"}}>Contact</A></Typography>
//                 </MenuItem>

//                 <MenuItem key='OptionTable' onClick={handleCloseNavMenu}>
//                   <Typography><A href='/optiontable' style={{textDecoration: "none"}}>Option Table</A></Typography>
//                 </MenuItem>

//                 <MenuItem key='OptionPlanner' onClick={handleCloseNavMenu}>
//                   <Typography><A href='/optionplanner' style={{textDecoration: "none"}}>Option Planner</A></Typography>
//                 </MenuItem>

//                 <MenuItem key='MyList' onClick={handleCloseNavMenu}>
//                   <Typography><A href='/mylist' style={{textDecoration: "none"}}>My List</A></Typography>
//                 </MenuItem>

//             </Menu>

//         </Toolbar>
//       </AppBar>
//       <br/><br/><br/><br/>
//     </div>
//   );

// }

function CheckAuthenticated(){

  const Credential = AuthenticationService.getCurrentUser();

  if (Credential == null){
    navigate('/');
  }
  else{
    if (Credential.UserName == null){
      navigate('/');
    }
  }

};

function FormattedNumber(p_Number, p_minimumFractionDigits){
    return Intl.NumberFormat('en-US', {minimumFractionDigits: p_minimumFractionDigits}).format(p_Number);
}

function LongFormattedValueNoPoint(p_value, p_decimal_place, p_dollar_sign){

  let _value_absolute = Math.abs(p_value);
  let _value_fixed = _value_absolute.toFixed(p_decimal_place);
  let _value_formatted = FormattedNumber(_value_fixed, 0);

  switch(p_dollar_sign)
  {

    case true:

      if (p_value >= 0)
      {
        return '$' + _value_formatted;
      }
      else
      {
        return '-$' + _value_formatted;
      }
      
      break;

    case false:

      if (p_value >= 0)
      {
        return _value_formatted;
      }
      else
      {
        return '-' + _value_formatted;
      }

      break;

  }

}

// function LongFormattedValue2(p_value, p_decimal_place, p_dollar_sign){

//   let _value_absolute = Math.abs(p_value);
//   let _value_fixed = _value_absolute.toFixed(p_decimal_place);
//   let _value_formatted = FormattedNumber(_value_fixed, 0);

//   switch(p_dollar_sign)
//   {

//     case true:

//       if (p_value >= 0)
//       {
//         return '$' + _value_formatted;
//       }
//       else
//       {
//         return '-$' + _value_formatted;
//       }
      
//       break;

//     case false:

//       if (p_value >= 0)
//       {
//         return _value_formatted;
//       }
//       else
//       {
//         return '-' + _value_formatted;
//       }

//       break;

//   }

// }

function LongFormattedValue(p_value, p_decimal_place, p_dollar_sign){

  let _value_absolute = Math.abs(p_value);
  let _value_fixed = _value_absolute.toFixed(p_decimal_place);
  let _value_formatted = FormattedNumber(_value_fixed, 0);
  let _point = p_value / 50;

  switch(p_dollar_sign)
  {

    case true:

      if (p_value >= 0)
      {
        return '$' + _value_formatted + ' (' + _point + ')';
      }
      else
      {
        return '-$' + _value_formatted + ' (' + _point + ')';
      }
      
      break;

    case false:

      if (p_value >= 0)
      {
        return _value_formatted;
      }
      else
      {
        return '-' + _value_formatted;
      }

      break;

  }

}

function LongUnderlyingProductValue(p_ProductType, p_underlying_product_value){

  if (p_underlying_product_value === '' | p_underlying_product_value === null){
    return '';
  } 
  else
  {
    switch(p_ProductType){        
      case 'Index':
      case 'IndexOption':
        let _x_index_option = p_underlying_product_value.toFixed(0);
        let _x_index_option_string = FormattedNumber(_x_index_option, 0);
        return _x_index_option_string;     
      case 'Stock':       
      case 'StockOption':
          // let _x_stock_option = p_underlying_product_value.toFixed(2);
          let _x_stock_option_string = Number2Dollar(p_underlying_product_value, 2);
        return _x_stock_option_string;
    }
  }

}

function Number2Dollar(p_Number, p_minimumFractionDigits){
    return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: p_minimumFractionDigits }).format(p_Number);
}

function LongExpiry(p_expiry){      
  let _expiry = String(p_expiry);
  let _year = _expiry.substring(0,4);
  let _month = _expiry.substring(4,6);
  let _day = _expiry.substring(6,8);
  let _long_expiry = _year + '-' + _month + '-' + _day;
  return _long_expiry;
}

function ShortExpiry(p_expiry){
  let _expiry = String(p_expiry);
  let _year = _expiry.substring(0,4);
  let _month = _expiry.substring(5,7);
  let _day = _expiry.substring(8,10);
  let _short_expiry = _year + _month + _day;
  return _short_expiry;
}

export {
  LongUnderlyingProductValue,
  Number2Dollar,
  FormattedNumber,
  LongExpiry,
  ShortExpiry,
  CheckAuthenticated,
  // ButtonAppBar,
  // StartEnd,
  StartEnd2,
  Rounding,
  date2string,
  LongFormattedValue,
  SetBidAskValue,
  Bid,
  Ask,
  // UnderlyingProductValue_Get,
  // getResponse,
  OTM_ATM_ITM,
  GroupSummaryCostValueSum,
  LabelBidAsk,
  RedColor_Set,
  BidDetails,
  AskDetails,
  GroupNumberList_Update,
  CalcIntrinsicValue,
  ActiveOptionTableList_Get,
  Profit,
  jdate2string4display,
  OnMessageIndex,
  OnMessageIndexOption,
  UnderlyingProductDiffString_Get,
  Dollar2Point,
  LongFormattedValueNoPoint,
  OptionTableListItem4Call,
  OptionTableListItem4Put,
  CallOption2OptionDetailsListItem,
  PutOption2OptionDetailsListItem,
  OptionDetailsListSubmit,
  AlertsSet,
  AlertsSet2,
  // LongFormattedValue2,
  OnMessageStockOption,
  OnMessageStock,
  PointFormatter,
  DollarFormatter,
  LabelPointDollar,
  LabelPointDollarLHS,
  LabelFigure,
  jsFileName,
  LabelPointDollarRHS,
  LabelPointDollarString,
  UnderlyingProduct_Show,
  WebSocket_Open,
  LabelPointDollarDateTime,
  CurrentDateGet,
  NowGet,
  WeeklyMonthlyName,
  ListOptionDetailsList_Get,
};