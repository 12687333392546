import React, { Component } from 'react';
import { w3cwebsocket as W3CWebSocket } from 'websocket';



class WS extends React.Component {

    constructor(props){

        super(props);
    
        this.state ={
            messages : [],
        }
    
      }

componentDidMount(){
    
    const client = new W3CWebSocket(sessionStorage.getItem('WebSocketURL'));

    client.onopen = () => {
        console.log('Connected');
    }

    client.onmessage = (message) => {

        console.log(message.data);

        var V_Object = JSON.parse(message.data);

        var V_ProductType = V_Object.ProductType;

        switch(V_ProductType){
            case 'Index':
                var s = '';
                break;
        }

    }
    
}

render(){
    return(
        <div>
            Practical Intro to WebSockets
        </div>
    )
}

}

export default WS;