// SignIn.js

import React from 'react';
import { unmountComponentAtNode, render } from "react-dom";
import Avatar from '@material-ui/core/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import AuthenticationService from './AuthenticationService.js';
//import { BrowserRouter, Route, Switch, withRouter, useHistory } from 'react-router-dom';
import App from './App';
import { useRoutes, A, navigate } from "hookrouter";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://OptionPlanner.com/">
        Option Planner (Tag: 2022-06-27)
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}<br/>
      {sessionStorage.getItem('SiteURL')}<br/>
    </Typography>
  );
}

//const useStyles = makeStyles((theme) => ({
  const styles = theme => ({

  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
  },

});

class SignIn extends React.Component {

  constructor(props){
    super(props);
    this.state ={
      username: '',
      password: '',
      error: '',
    };
    this.SubmitAction = this.SubmitAction.bind(this);        
    //this.hist = this.hist.bind(this);        
  };  

  onChangeHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({[name]: value});
  }

  SubmitAction = async (e) => {

    e.preventDefault();

    AuthenticationService
        .signin(this.state.username, this.state.password)
        .then((response) => {

          if (response.length > 0){
                
            var v_credential = {
                UserName: this.state.username,
                Token: response
            };
            sessionStorage.setItem('Credential', JSON.stringify(v_credential));

            navigate('/optiontable2');
          }
          else {
            this.setState({error: "Cannot signin successfully! Please check username / password again"});
          }
        },
        error => {
          //console.log("Login fail: error = { " + error.toString() + " }");
          this.setState({error: "Cannot signin successfully! Please check username / password again"});
        }
    )
      
  }
  
  componentDidMount() {
    console.log(this.props);       
  }

  render(){
  
      const { classes } = this.props;

      return (        

<div>

{/*         <App /> */}

        <Container component="main" maxWidth="xs">

          <CssBaseline />
          
          <div className={classes.paper}>
            
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            
            <form className={classes.form} onSubmit={this.SubmitAction}>
              
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Email Address"
                name="username"
                autoComplete="email"
                autoFocus
                onChange={this.onChangeHandler}
              />
              
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={this.onChangeHandler}
              />
              
              {this.state.error}<br/>

              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
/*                 onClick={this.SubmitAction}
 */              >
                Sign In
              </Button>
              
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>

            </form>

          </div>

          <Box mt={8}>
            <Copyright />
          </Box>

        

        </Container>

</div>

      )
  }
}

export default withStyles(styles)(SignIn);
//export default withRouter(withStyles(styles)(SignIn));