// CurrentHistorical.js

import React from 'react';
import { useState, useEffect, createContext, useContext } from "react";
import axios from 'axios';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import Historical from './Historical.js';
import AuthenticationService from '../AuthenticationService.js';
import CascadingDropDownList from './CascadingDropDownList.js';
import { WeeklyMonthlyName, LongUnderlyingProductValue, Number2Dollar, FormattedNumber, CheckAuthenticated, ButtonAppBar, LongExpiry, ShortExpiry } from './Common.js';

export default function CurrentHistorical(props){

  const { 
    ProductTypeUnderlyingProductCodeExpiryList,
    ProductType_Callback, 
    UnderlyingProductCode_Callback, 
    Currency_Callback,
    Expiry_Callback, 
    TimeStatus_Callback, 
    NoExpiry,
  } = props;

  const [ProductType, setProductType] = useState('IndexOption');
  const [UnderlyingProductCode, setUnderlyingProductCode] = useState('');
  const [Currency, setCurrency] = useState('');
  const [Expiry, setExpiry] = useState('');  

  const [UnderlyingProductCodeOptions, setUnderlyingProductCodeOptions] = useState('');
  const [IndexStockListItem, setIndexStockListItem] = useState({});
  const [HistoricalDateListOptions, setHistoricalDateListOptions] = useState([]);

  const [TimeStatus, setTimeStatus] = useState('Current');
  const [ExpiryOptions, setExpiryOptions] = useState('');

  const [IndexListOptionEnabled, setIndexListOptionEnabled] = useState('');
  const [StockListOptionEnabled, setStockListOptionEnabled] = useState('');

  function RefreshAction(P_RefreshDate, P_RefreshHour, P_RefreshMinute){
  
    var v_http_post_data = {
      Credential: AuthenticationService.getCurrentUser(),
      HistoricalDateTimeString: P_RefreshDate + P_RefreshHour + P_RefreshMinute,         
    }
  
    let _debug = ProductType;
    const url = sessionStorage.getItem('SiteURL') + 'api/OptionList/IndexListStockList/Historical/';
  
    axios.post(url, v_http_post_data)
  
      .then(resp => {
  
        let _return_status = resp.data;
  
        if (_return_status.ReturnCode == 1){
              OptionList_Historical(P_RefreshDate, P_RefreshHour, P_RefreshMinute);
            }
          });
  
  }  
  
  function IndexListOptionEnabled_Get(){
  
    var v_http_post_data = {
        Credential: AuthenticationService.getCurrentUser(),
        TimeStatus: TimeStatus,            
    }
  
    const url = sessionStorage.getItem('SiteURL') + 'api/IndexList/OptionEnabled/';
  
    axios.post(url, v_http_post_data)
  
        .then(resp => {
  
            const _index_list_option_enabled = resp.data;
  
            const _underlying_product_code_options = _index_list_option_enabled.map((e, key) => {
                return <option key={key} value={e.IndexCode}>{e.IndexCode}</option>;
            });
  
            var V_UnderlyingProductCodeDefaultValue = _index_list_option_enabled[0].IndexCode;
            
            setIndexListOptionEnabled(_index_list_option_enabled);
            setUnderlyingProductCodeOptions(_underlying_product_code_options); 
            setUnderlyingProductCode(V_UnderlyingProductCodeDefaultValue);
            setCurrency(_index_list_option_enabled[0].Currency);
            Expiry_Get(V_UnderlyingProductCodeDefaultValue); // also update Expiry even if the UnderlyingProductCode is not changed after TimeStatus is from Current to Historical
  
    })
  
  }

  function StockListOptionEnabled_Get(){
  
    var v_http_post_data = {
        Credential: AuthenticationService.getCurrentUser(),
        TimeStatus: TimeStatus,            
    }
  
    const url = sessionStorage.getItem('SiteURL') + 'api/StockList/OptionEnabled/';
  
    axios.post(url, v_http_post_data)
  
        .then(resp => {
  
            const _stock_list_option_enabled = resp.data;
  
            const _underlying_product_code_options = _stock_list_option_enabled.map((e, key) => {
                return <option key={key} value={e.StockCode}>{e.StockCode}</option>;
            });
  
            var V_UnderlyingProductCodeDefaultValue = _stock_list_option_enabled[0].StockCode;
  
            setStockListOptionEnabled(_stock_list_option_enabled);
            setUnderlyingProductCodeOptions(_underlying_product_code_options);
            setUnderlyingProductCode(V_UnderlyingProductCodeDefaultValue);
            setCurrency(_stock_list_option_enabled[0].Currency);
            Expiry_Get(V_UnderlyingProductCodeDefaultValue); // also update Expiry even if the UnderlyingProductCode is not changed after TimeStatus is from Current to Historical
  
    })
  
  }

  function OptionList_Historical(P_RefreshDate, P_RefreshHour, P_RefreshMinute){
  
    var v_http_post_data = {
      Credential: AuthenticationService.getCurrentUser(),
      HistoricalDateTimeString: P_RefreshDate + P_RefreshHour + P_RefreshMinute,  
      ProductType: ProductType,
    }
  
    const url = sessionStorage.getItem('SiteURL') + 'api/OptionList/Historical/';
  
    axios.post(url, v_http_post_data)
  
      .then(resp => {
  
        let _return_status = resp.data;
  
        if (_return_status.ReturnCode == 1){
  
              switch(ProductType){
                case 'IndexOption':
                  IndexListOptionEnabled_Get();
                  break;
                case 'StockOption':
                  StockListOptionEnabled_Get();
                  break;
              }   
  
        }
  
      });
  
  }

  function onChange_TimeStatus(P_TimeStatus){
  
    switch(P_TimeStatus){
  
    case 'Historical': // Update the dropdownlist "Date" of historical data. Set state of DateList, RefreshDate and TimeStatus
  
      var _http_post_data = {
        Credential: AuthenticationService.getCurrentUser(),
        ProductType: ProductType,
        DayTotal: 60
      }
  
      const _url = sessionStorage.getItem('SiteURL') + 'api/PriceHistoryLogList/GetDate/';
  
      axios.post(_url, _http_post_data)
  
      .then(resp => {
  
        let _historical_date_list = resp.data;
  
        const _historical_date_list_options = _historical_date_list.map((e, key) => {
          let v = e.replace(/-/g,'');
          v = v.substr(0,8);
          return <option key={key} value={v}>{e}</option>;       
        });
  
        setHistoricalDateListOptions(_historical_date_list_options);        
        setTimeStatus(P_TimeStatus);

      });
  
      break;
  
  case 'Current': // Set state of TimeStatus

      setTimeStatus(P_TimeStatus);
      TimeStatus_Callback(P_TimeStatus);
  
      switch(ProductType){
        case 'IndexOption':
          IndexListOptionEnabled_Get();
          break;
        case 'StockOption':
          StockListOptionEnabled_Get();
          break;
      }
  
      break;
  
    }    
  
  }

  useEffect(() => {
    ProductType_Callback(ProductType);
  }, [ProductType]);

  useEffect(() => {
    UnderlyingProductCode_Callback(UnderlyingProductCode);
  }, [UnderlyingProductCode]);

  useEffect(() => {
    Currency_Callback(Currency);
  }, [Currency]);

  useEffect(() => {
    Expiry_Callback(Expiry);
  }, [Expiry]);

  useEffect(() => {
    TimeStatus_Callback(TimeStatus);
  }, [TimeStatus]);

  useEffect(() => {
    switch(ProductType){
      case 'IndexOption':
        IndexListOptionEnabled_Get();
        break;
      case 'StockOption':
        StockListOptionEnabled_Get();
        break;
    }
  }, [ProductType]);

  function Expiry_Get(P_UnderlyingProductCode){

    var v_http_post_data = {
        Credential: AuthenticationService.getCurrentUser(),
        ProductType: ProductType,
        UnderlyingProductCode: P_UnderlyingProductCode,
        TimeStatus: TimeStatus,            
    }

    const url = sessionStorage.getItem('SiteURL') + 'api/OptionList/ByUnderlyingProductCode/';

    axios.post(url, v_http_post_data)

    .then(resp => {

        const _option_list = resp.data;

        let _expiry_weekly_monthly_list = [];

        _option_list.forEach((e) => {
            let _wm = WeeklyMonthlyName(e.WeeklyMonthly);
            let _long_expiry = LongExpiry(e.Expiry);
            var _ewm = _long_expiry + ' (' + e.WeekDay + ') ' + _wm;
            _expiry_weekly_monthly_list.push(_ewm);
        });
        const _unique_expiry_list = [...new Set(_expiry_weekly_monthly_list.map(_list_item => _list_item))];

        const ExpiryOptions = _unique_expiry_list.map((e, key) => {
            let _long_expiry = e.substr(0,10); // e.g. 2022-06-17
            var _expiry_value = ShortExpiry(_long_expiry); // e.g. 20220617
            return <option key={key} value={_expiry_value}>{e}</option>;
        });

        setExpiryOptions(ExpiryOptions);        

    })
}  

  useEffect(() => {
    CheckAuthenticated();   
    IndexListOptionEnabled_Get();
  }, []);

  return (

    <div>

       <Container>

       <Box display="flex" justifyContent="center">
         <RadioGroup row defaultValue={TimeStatus} onChange={e => onChange_TimeStatus(e.target.value)}>
           <FormControlLabel value="Current" control={<Radio />} label="實時數據" />
           <FormControlLabel value="Historical" control={<Radio />} label="歷史數據" />
         </RadioGroup>
       </Box>

       <br/>

       <Historical 
        RefreshAction={RefreshAction} 
        TimeStatus={TimeStatus} 
        HistoricalDateListOptions={HistoricalDateListOptions}
       />

       <br/>

       <Box pb={3} pt={3} m={0} width="100%" borderTop={1} borderColor="grey.300">   

         <CascadingDropDownList 
          ProductTypeUnderlyingProductCodeExpiryList={ProductTypeUnderlyingProductCodeExpiryList}
           ProductType={ProductType} 
           ProductType_Callback={setProductType} 
           UnderlyingProductCode_Callback={setUnderlyingProductCode} 
           Expiry_Callback={setExpiry} 
           TimeStatus={TimeStatus} 
           UnderlyingProductCodeOptions={UnderlyingProductCodeOptions} 
           ExpiryOptions={ExpiryOptions} 
           NoExpiry={NoExpiry}
           IndexListOptionEnabled={IndexListOptionEnabled}
           StockListOptionEnabled={StockListOptionEnabled}
         />

       </Box>

       </Container>

    </div>

 );

}